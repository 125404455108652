import React, { Component } from "react";
import { GetDataFromEvent, getResizedImage, toPesoAmount } from "../helpers";
import { Table, Spinner } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { InfoAlertHelper, InfoTooltip } from "../components/Base";
import ItemTag from "../components/widgets/ItemTag";
import { TAG_NAMES } from "./forms/ItemTags";
import { KTUtil } from "../../_metronic/_assets/js/components/util";
import moment from "moment";
import { DB_DATE_FORMAT, PRIMARY_COLOR } from "../constants";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8,
  DRAGGABLE_WIDTH = 1231.5;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  background: "white",
  width: DRAGGABLE_WIDTH,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: "100%",
});

const computeWidth = (percentage) => {
  return (percentage / 100) * DRAGGABLE_WIDTH;
};

const TableTDWidth = {
  item_name: 100,
  drag: 5,
};

export default class ItemListDnD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data_status: "loading",
      loadingItems: [],
    };

    this._mounted = false;
    this.getList = this.getList.bind(this);
    this.getGroupName = this.getGroupName.bind(this);
    this.updateList = this.updateList.bind(this);
    this.updateItems = this.updateItems.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    const { data = [] } = this.props;

    let lists = {
      groups: [],
      data_status: "fetched",
    };

    for (var i = 0; i < data.length; i++) {
      let limit = 0;
      if (data[i].category_limit_per_time_slot.length > 0) {
        limit = data[i].category_limit_per_time_slot[0]["limit"];
      }

      const listname = `${data[i].isExclusive ? "mealPlan_" : ""}list${i + 1}`;
      lists[listname] = [];
      lists["groups"].push({
        id: listname,
        name: data[i]._id === "" ? "Uncategorized" : data[i]._id,
        category_limit_per_day: data[i].category_limit_per_day,
        category_limit_per_time_slot_batch: limit,
        visible: data[i].visible,
        isExclusive: data[i].isExclusive,
      });

      for (var j = 0; j < data[i].items.length; j++) {
        lists[listname].push(data[i].items[j]);
      }
    }

    this._mounted && this.setState(lists);
  }

  /**
   *  getList()
   *  - Returns the list of items by id.
   */
  getList = (id) => {
    return this.state[id];
  };

  /**
   *  getGroupName()
   *  - Returns group name by list id.
   *  - Used in moving items to a new group.
   */
  getGroupName = (id) => {
    const { groups = [] } = this.state;
    let group = "";

    for (var i = 0; i < groups.length; i++) {
      if (id === groups[i].id) {
        group = groups[i].name;
        break;
      }
    }

    return group;
  };

  /***
   *  updateItems()
   *  - Returns the updated list of items with the ff changes:
   *     1. Updates the item's `order_position`.
   *     2. If `newGroup` is provided, it will update the `category` field as well.
   */
  updateItems = (items = [], newGroup = null) => {
    if (items.length > 0) {
      return items
        .map((item, index) => {
          if (newGroup) {
            item.category = newGroup;
          }

          item.order_position = index + 1;

          return item;
        })
        .filter((item) => {
          return item.item_name !== "ITEM_GROUP_PLACEHOLDER";
        });
    } else {
      return [
        {
          _id: `${newGroup}_1`,
          item_name: "ITEM_GROUP_PLACEHOLDER",
        },
      ];
    }
  };

  /**
   *  updateList()
   *  - Does the updating of the items, with a new `order_id` or `group`
   *    before updating them on the state and the database.
   */
  updateList = (type = "rearrange", params = {}) => {
    let state = {},
      newItems = [];

    if (type === "rearrange") {
      const { sourceId = "", items = [] } = params;
      newItems = this.updateItems(items);
      state[sourceId] = newItems;
    } else if (type === "move") {
      const { rearrangedList = {} } = params;

      for (var listname in rearrangedList) {
        const newGroup = this.getGroupName(listname);
        const nItems = this.updateItems(
          rearrangedList[listname],
          newGroup === "Uncategorized" ? "" : newGroup,
        );
        state[listname] = nItems;
        newItems = newItems.concat(nItems);
      }
    }

    if (newItems.length > 0)
      if (type === "rearrange" && params.sourceId === "groups") {
        this.props.actions.updateCategoriesOrder(newItems);
      } else {
        this.props.actions.updateList(newItems);
      }
    this.setState(state);
  };

  setAvailability = async (values) => {
    const noop = () => {};
    this.setState(({ loadingItems }) => ({
      loadingItems: loadingItems.concat(loadingItems, values?._id),
    }));
    await this.props.onSubmitEditItem(values, noop, noop, true);
    this.setState(({ loadingItems }) => ({
      loadingItems: loadingItems.filter((id) => id !== values?._id),
    }));
  };

  onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    const sourceId = source.droppableId,
      destinationId = destination.droppableId;
    // dropped outside the list
    if (!destination) {
      return;
    }
    if (source.index === destination.index && sourceId === destinationId) {
      return;
    }
    if (sourceId === destinationId) {
      const items = reorder(
        this.getList(sourceId),
        source.index,
        destination.index,
      );

      this.updateList("rearrange", { sourceId, items });
    } else {
      if (destinationId.includes("mealPlan_")) {
        const { data } = this.props;
        const items = data.flatMap(({ items }) => items);
        const selected_item = items.filter(
          (item) => item._id === draggableId,
        )[0];
        if (!selected_item.item_id.isMealPlan) {
          MySwal.fire({
            icon: "error",
            title: "Ooops!",
            text: `Item should be of meal plan type.`,
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "OK",
            showCancelButton: false,
          });
          return;
        }
      }

      const rearrangedList = move(
        this.getList(sourceId),
        this.getList(destinationId),
        source,
        destination,
      );

      this.updateList("move", { rearrangedList });
    }
  };

  /**
   * Availability switch
   */
  handleOnChangeAvailable = (e) => {
    const id = GetDataFromEvent(e, "data-id"),
      droppableId = GetDataFromEvent(e, "data-droppableId"),
      available = e.target.checked;

    let state = {};

    const grouplist = this.getList(droppableId);
    state[droppableId] = grouplist.map((item) => {
      if (item._id === id) item.available = available;
      return item;
    });

    this.setState(state);

    this.props.handleOnChangeAvailable(id, available);
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { groups = [], data_status = "" } = this.state,
      isLoading = data_status !== "fetched";

    return isLoading ? (
      <></>
    ) : (
      <>
        <InfoAlertHelper
          text={
            "This is the order of items and categories that will show up on your site."
          }
        />

        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId={"groups"} type="CATEGORY">
            {(provided, snapshot) => (
              <div ref={provided.innerRef}>
                {groups.map((group, index) => {
                  return (
                    <Draggable
                      key={group.id}
                      draggableId={"group-" + index}
                      index={index}
                      type="CATEGORY"
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <MenuGroup
                            items={this.getList(group.id)}
                            droppableId={group.id}
                            groupName={group.name}
                            actions={this.props.actions}
                            category_limit_per_day={
                              group.category_limit_per_day
                            }
                            category_limit_per_time_slot_batch={
                              group.category_limit_per_time_slot_batch
                            }
                            setAvailability={this.setAvailability}
                            visible={group.visible}
                            isExclusive={group.isExclusive}
                            tagSettings={this.props.tagSettings}
                            loadingItems={this.state.loadingItems}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </>
    );
  }
}

class MenuGroup extends Component {
  render() {
    const {
      items = [],
      droppableId = "",
      groupName = "",
      actions = {},
      category_limit_per_day = 0,
      category_limit_per_time_slot_batch = 0,
      visible,
      tagSettings,
      setAvailability,
      isExclusive = false,
    } = this.props;
    return (
      <Droppable droppableId={droppableId} type="ITEM">
        {(provided, snapshot) => (
          <Table
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            className="per-category-group-table"
            responsive
          >
            <tbody>
              <tr width={DRAGGABLE_WIDTH}>
                <td
                  colSpan={6}
                  className={`group-name group_${groupName
                    .split(" ")
                    .join("_")}`}
                >
                  <span
                    onClick={() => {
                      this.props.actions.onClickCategory(groupName);
                    }}
                    className={`va-middle${!visible ? " text-danger" : ""}`}
                  >
                    {" "}
                    {groupName}{" "}
                  </span>
                  {category_limit_per_day > 0 ||
                  category_limit_per_time_slot_batch > 0 ? (
                    <span className="label label-primary label-inline font-weight-bold mr-2">
                      LIMITED
                    </span>
                  ) : (
                    <></>
                  )}
                  {isExclusive && (
                    <span className="label label-meal-plan label-inline font-weight-bold mr-2 va-middle">
                      EXCLUSIVE
                    </span>
                  )}
                </td>
              </tr>

              {items.map((item, index) => {
                return (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                    type="ITEM"
                  >
                    {(provided, snapshot) => (
                      <>
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                          data-id={item._id}
                          className="item-tr"
                        >
                          {item.item_name === "ITEM_GROUP_PLACEHOLDER" ? (
                            <>
                              <td
                                width={computeWidth(100)}
                                colSpan={6}
                                className="no-items"
                              >
                                <span style={{ textTransform: "uppercase" }}>
                                  No items under this category.
                                </span>
                              </td>
                            </>
                          ) : (
                            <>
                              <td
                                width={computeWidth(TableTDWidth["item_name"])}
                                {...provided.dragHandleProps}
                              >
                                <>
                                  <ItemDisplay
                                    item={item.item_id}
                                    category={groupName}
                                    available={item.available}
                                    hotItem={item.hotItem}
                                    item_limit_per_day={item.item_limit_per_day}
                                    item_limit_per_time_slot_batch={
                                      item.item_limit_per_time_slot.length > 0
                                        ? item.item_limit_per_time_slot[0][
                                            "limit"
                                          ]
                                        : 0
                                    }
                                    setAvailability={setAvailability}
                                    actions={actions}
                                    tags={item.tags}
                                    tagSettings={tagSettings}
                                    loadingItems={this.props.loadingItems}
                                  />
                                </>
                              </td>
                              {/* <td width={computeWidth(TableTDWidth["drag"])} className="icon"><span className="drag-handle" > <SVGIcon icon="Text/Menu" title={"Move"}/></span></td> */}
                            </>
                          )}
                        </tr>
                      </>
                    )}
                  </Draggable>
                );
              })}
            </tbody>
            {provided.placeholder}
          </Table>
        )}
      </Droppable>
    );
  }
}

class ItemDisplay extends React.Component {
  constructor(props) {
    super(props);
    const today_date = moment(new Date()).format(DB_DATE_FORMAT);

    const {
      tagSettings,
      item_limit_per_time_slot_batch = 0,
      item_limit_per_day = 0,
      category = "",
      available = false,
      item: {
        _id = "",
        extragroup = [],
        isMealPlan = false,
        tags = [],
        visible = false,
        off_dates = [],
        isVisibleOnOffDate = false,
      },
    } = this.props;
    this.state = {
      today_date,
      isNotAvailable: off_dates.includes(today_date) || !available,
      values: {
        item_limit_per_time_slot_batch,
        item_limit_per_day,
        visible,
        tag_settings: tagSettings,
        extragroup,
        isVisibleOnOffDate,
        isMealPlan,
        category,
        tags,
        _id,
        off_dates,
      },
    };
  }

  handleOnChange = async (event) => {
    const target = event.target;

    const value = target.checked ? "ON" : "OFF";
    const { values, today_date } = this.state;
    if (value === "OFF") {
      values["off_dates"].push(today_date);
    } else {
      const x = values["off_dates"].indexOf(today_date);

      if (x !== -1) {
        values["off_dates"].splice(x, 1);
      }
    }

    this.setState({ values, isNotAvailable: value === "OFF" });
    await this.props.setAvailability(values);
  };

  render() {
    const {
      item = {},
      available = true,
      item_limit_per_day = 0,
      item_limit_per_time_slot_batch = 0,
      tagSettings,
    } = this.props;
    let activeTags = null;

    try {
      const settings =
        tagSettings && typeof tagSettings === "string"
          ? JSON.parse(tagSettings)
          : undefined;
      activeTags = settings
        ? item.tags.map((tagId, index) => {
            return typeof settings[tagId] !== "undefined" ? (
              <div className="mr-3">
                <ItemTag
                  key={index}
                  backgroundColor={settings[tagId].bgColor}
                  textColor={settings[tagId].textColor}
                  borderColor={settings[tagId].borderColor}
                  icon={settings[tagId].icon}
                  name={TAG_NAMES[tagId]}
                />
              </div>
            ) : null;
          })
        : null;
    } catch (error) {}

    const {
      name = "",
      description = "",
      price = "",
      image_url = "",
      _id = "",
      date_updated,
      date_created,
      regular_price = "",
      isBulk = false,
      isMealPlan = false,
      active_discount = "",
      discount_description = "",
      discount_type = "",
      discount_value = "",
      item_code = "",
      off_dates = [],
      isDineInExclusive = false,
    } = item;

    const inputActions = {
      onChange: this.handleOnChange,
    };

    const small_image = getResizedImage(
      image_url,
      "small",
      date_updated || date_created,
    );

    return (
      <div className="d-flex">
        <div
          data-id={_id}
          className={`item w-100 item_${item_code}`}
          onClick={this.props.actions.onClickItem}
        >
          <div className="d-flex align-items-center">
            <div
              className="menu-image-container mr-4"
              style={{ position: "relative" }}
            >
              <img
                className="menu-image"
                src={small_image}
                alt={name}
                onError={(ev) => {
                  ev.target.onError = null;
                  ev.target.src = image_url;
                }}
              />
              {active_discount > 0 ? (
                discount_type === "percentDiscount" ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                      backgroundColor: "rgba(250,250,250,0.5)",
                      fontSize: "0.8em",
                      fontWeight: "bolder",
                    }}
                  >
                    {Number(discount_value)}% OFF
                  </div>
                ) : discount_type === "rawDiscount" ? (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      color: "red",
                      backgroundColor: "rgba(250,250,250,0.5)",
                      fontSize: "0.8em",
                      fontWeight: "bolder",
                    }}
                  >
                    P {Number(active_discount).toFixed(2)} OFF
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
            <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2">
              <div className="item-name d-flex align-items-center">
                {name}
                {isBulk ? (
                  <div>
                    {/* <span data-toggle="tooltip" data-placement="top" title="This item is bulky and can only be delivered by cars"> */}
                    <span>
                      &nbsp;
                      <i
                        style={{ color: "lightsalmon", cursor: "help" }}
                        className="fas fa-truck-loading"
                      ></i>
                    </span>
                    {InfoTooltip(
                      "This item is bulky and can only be delivered by cars",
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {this.state.isNotAvailable ? (
                  <span className="label label-danger label-inline font-weight-bold ml-2">
                    UNAVAILABLE
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="item-desc">{description}</div>
              {active_discount > 0 ? (
                <div className="item-price">
                  <del>{toPesoAmount(regular_price)}</del>
                  <span className="ml-3 text-danger font-weight-boldest">
                    {toPesoAmount(price)}
                  </span>
                </div>
              ) : (
                <div className="item-price">{toPesoAmount(price)}</div>
              )}
              {discount_description && active_discount > 0 ? (
                <div className="item-desc text-danger">
                  {discount_description}
                </div>
              ) : (
                <></>
              )}
              {/* {(item_limit_per_day > 0 || item_limit_per_time_slot_batch > 0) ? <div><span className="label label-primary label-inline font-weight-bold mr-2">LIMITED</span></div> : <></>} */}
              <span>
                {item_limit_per_day > 0 ||
                item_limit_per_time_slot_batch > 0 ? (
                  <span className="label label-primary label-inline font-weight-bold mr-2">
                    LIMITED
                  </span>
                ) : (
                  <></>
                )}
                <span>
                  {isMealPlan ? (
                    <span
                      style={{ backgroundColor: "#2196F3" }}
                      className="label label-info label-inline font-weight-bold mr-2"
                    >
                      MEAL PLAN ITEM
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
                <span>
                  {isDineInExclusive ? (
                    <span
                      style={{ backgroundColor: "#2196F3" }}
                      className="label label-info label-inline font-weight-bold mr-2"
                    >
                      DINE-IN EXCLUSIVE
                    </span>
                  ) : (
                    <></>
                  )}
                </span>
              </span>
            </div>
          </div>
          {Array.isArray(activeTags) && activeTags.length > 0 && (
            <div className="row mt-4">
              <div className="col-xs-12 col-md-12 col-lg-1"></div>
              <div className="col-xs-12 col-md-12 col-lg-11">
                <div
                  className={`row ${
                    KTUtil.isInResponsiveRange("mobile") ? "pl-4" : "pl-0"
                  }`}
                  style={{
                    marginLeft: KTUtil.isInResponsiveRange("mobile")
                      ? "-4%"
                      : "0",
                  }}
                >
                  {activeTags}
                </div>
              </div>
            </div>
          )}
        </div>
        {!isMealPlan && (
          <div className="d-flex flex-end flex-grow-1 justify-content-end">
            <span className="switch switch-outline switch-icon switch-primary">
              <label>
                <input
                  type="checkbox"
                  name="isUnavailableToday"
                  checked={!this.state.isNotAvailable ? "checked" : null}
                  disabled={this.props.loadingItems.includes(
                    this.props.item._id,
                  )}
                  {...inputActions}
                />
                <span></span>
              </label>
            </span>
          </div>
        )}
      </div>
    );
  }
}
