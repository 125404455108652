import React from "react";

import core from "../../../vendors/core-api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TitleHelmet from "../../../partials/TitleHelmet";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { fetchData, GetSubmitClassList } from "../../../helpers";
import {
  FormItem,
  HeaderTextWithActions,
  IconTooltip,
  PreviewPhoto,
  RequiredAsterisk,
} from "../../../components/Base";
import Breadcrumbs from "../../../components/Breadcrumbs";
import APIComponent from "../../../components/API";
import { isEmpty, isNull, debounce } from "lodash";
import { PRIMARY_COLOR } from "../../../constants";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import PacmanLoading from "../../../components/widgets/PacmanLoading/PacmanLoading";
import { MapComponent } from "../../../components/widgets/GMapsWidget";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ProSourceForm from "../../../components/ProSourceForm";
import ImageUpload from "../../../components/ImageUpload";
import moment from "moment/moment";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "react-accessible-accordion/dist/fancy-example.css";
import {
  eachDayOfInterval,
  getHours,
  getMinutes,
  nextMonday,
  setHours,
  setMinutes,
} from "date-fns";

const MySwal = withReactContent(Swal);

export default function Page(props) {
  const { store_id = "", event_id = "" } = useParams();
  const { user } = useSelector((state) => state.auth);
  return (
    <MainComponent id={store_id} user={user} event_id={event_id} {...props} />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      mountForm: true,
      groupName: "",
      store: {},
      isSubmitting: false,
      isEdit: !isEmpty(props.event_id),
      isMapLoading: true,
      event: {},
      title: `${!isEmpty(props.event_id) ? "Update" : "Create New"} Event`,
    };
    this.api = core("pu").get();
    this.fileapi = core("puf").get();
    this.data_url = `/store/${props.id}/event${
      !isEmpty(props.event_id) ? `/${props.event_id}` : ""
    }`;
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getData();
  }

  async getData() {
    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .get(this.data_url)
        .then(({ data }) => {
          this._isMounted &&
            this.setState({
              event: isEmpty(data.event) ? null : data.event,
              store: data.store,
              data_status: "fetched",
              groupName: data.store.brand.client.name,
            });
        })
        .catch((err) => {
          this._isMounted && this.setState({ error: err, showSwalError: true });
          if (err.hasOwnProperty("response")) {
            const { data = {} } = err.response,
              { error_message = {}, error = "" } = data;
            if (!isEmpty(error_message)) {
              MySwal.fire({
                icon: "error",
                title: "Ooops!",
                text: error_message,
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "OK",
                showCancelButton: false,
              });
              if (!isEmpty(error)) {
                if (error === "EventNotFund") {
                  this.props.history.push(`/stores/${this.props.id}/events`);
                }
              }
            } else {
              this.handleOnDataFetchError();
            }
          }
          this.handleOnDataFetchError();
        });
  }

  render() {
    const {
      groupName,
      data_status = "fetched",
      store,
      isEdit,
      title,
    } = this.state;

    return (
      <>
        {data_status === "fetching" && (
          <div className="d-flex justify-content-center align-items-center h-100">
            <PacmanLoading />
          </div>
        )}
        {data_status === "fetched" && (
          <>
            {" "}
            <TitleHelmet title={title} />
            <HeaderTextWithActions
              breadcrumbs={
                <Breadcrumbs>
                  <Breadcrumbs.Item text={groupName} link={`/stores`} />
                  <Breadcrumbs.Item
                    text="Events"
                    link={`/stores/${store._id}/events`}
                  />
                  <Breadcrumbs.Item
                    text={`${isEdit ? "Edit" : "Add"} Event`}
                    active={"true"}
                  />
                </Breadcrumbs>
              }
            />
            <CreateEventForm
              event={this.state.event}
              isEdit={isEdit}
              api={this.api}
              state={this.state}
              {...this.props}
            />
          </>
        )}
      </>
    );
  }
}

class CreateEventForm extends ProSourceForm {
  constructor(props) {
    super(props);
    const { event = {} } = props;
    const next_monday = nextMonday(new Date());
    this.state = {
      ...props.state,
      values: {
        mapPosition: {
          lat: "",
          lng: "",
        },
        currentPosition: {
          lat: "",
          lng: "",
        },
        event_location: { lat: "", lng: "" },
        event_name: "",
        zoom_value: 18,
        start_date: isEmpty(event) ? next_monday : event["start_date"],
        end_date: isEmpty(event) ? next_monday : event["end_date"],
        capacity: 1,
        price: 100,
        active: false,
        delivery_address: !isEmpty(event) ? event.location_address : "",
        delivery_city: "",
        status: "private",
        end_time: isEmpty(event)
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              18,
            )
          : setHours(
              setMinutes(new Date(), getMinutes(new Date(event.end_date))),
              getHours(new Date(event.end_date)),
            ),
        start_time: isEmpty(event)
          ? new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              9,
            )
          : setHours(
              setMinutes(new Date(), getMinutes(new Date(event.start_date))),
              getHours(new Date(event.start_date)),
            ),
      },
      errors: [],
      touched: {},
      isSubmitting: false,
      isVerifying: false,
      isNameValid: false,
      isEventTimeValid: true,
      isEventDateValid: true,
      isDescriptionValid: false,
    };
    this.fileapi = core("puf").get();
    this.api = props.api;

    this.validate = {
      required: [
        "event_location",
        "start_date",
        "start_time",
        "end_date",
        "end_time",
        "description",
        "image_url",
        "price",
      ],
    };
    this.onVerifyEventName = this.onVerifyEventName.bind(this);
    this.handlePhotoUpload = this.handlePhotoUpload.bind(this);
    this.populateValues = this.populateValues.bind(this);
    this.onCreateEvent = this.onCreateEvent.bind(this);
    this.onUpdateEvent = this.onUpdateEvent.bind(this);
    this.handleEventError = this.handleEventError.bind(this);
    this.formatEventObject = this.formatEventObject.bind(this);
  }

  componentDidMount() {
    if (this.props.isEdit) {
      this.populateValues();
    }
  }

  populateValues = () => {
    const { event, values } = this.state;
    if (!isEmpty(event)) {
      const {
        name,
        location_address,
        location,
        start_date,
        end_date,
        image_url,
        status,
        description,
        item: { price },
        capacity,
      } = event;

      values.event_name = name;
      values.event_location = location_address;
      values.start_date = start_date;
      values.end_date = end_date;
      values.description = description;
      values.image_url = image_url;
      values.price = price;
      values.capacity = capacity;
      values.status = status;
      values.delivery_address = location_address;
      values.mapPosition = {
        lat: "",
        lng: "",
      };
      values.markerPosition = {
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      };
      values.currentPosition = {
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      };
      values.zoom_value = 18;
      values.delivery_city = "";

      this.setState({ values, isNameValid: true, isDescriptionValid: true });
    }
  };

  formatEventObject = () => {
    const { values } = this.state;
    const {
      event_name,
      start_date,
      end_date,
      delivery_address,
      currentPosition,
      capacity,
      price,
      image_url,
      description,
      status,
      start_time,
      end_time,
    } = values;

    return {
      name: event_name,
      image_url,
      description,
      capacity,
      price,
      start_date: setHours(
        setMinutes(new Date(start_date), getMinutes(start_time)),
        getHours(start_time),
      ),
      end_date: setHours(
        setMinutes(new Date(end_date), getMinutes(end_time)),
        getHours(end_time),
      ),
      location: {
        type: "Point",
        coordinates: [currentPosition.lng, currentPosition.lat],
      },
      location_address: delivery_address,
      status,
    };
  };

  handleEventError = (err) => {
    if (err.hasOwnProperty("response")) {
      const { data = {} } = err.response,
        { error_message = {} } = data;
      if (!isEmpty(error_message)) {
        MySwal.fire({
          icon: "error",
          title: "Ooops!",
          text: error_message,
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        this.handleError();
      }
    } else {
      this.handleError();
    }
  };

  onUpdateEvent = async () => {
    const { store, event } = this.state;
    try {
      this.api
        .put(
          {
            url: `/store/${store._id}/event/${event._id}`,
            data: { event: this.formatEventObject() },
          },
          {
            headers: {
              Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
            },
            baseURL: this.api.getBaseURL(),
          },
        )
        .then(({ data }) => {
          if (data) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "Successfully updated event!",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result && result.isConfirmed) {
                this.props.history.push(`/stores/${store._id}/events`);
              }
            });
          }
        });
    } catch (err) {
      this.handleEventError(err);
    }
  };

  onCreateEvent = async () => {
    const { store } = this.state;
    try {
      this.api
        .post(
          {
            url: `/store/${store._id}/event`,
            data: { event: this.formatEventObject() },
          },
          {
            headers: {
              Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
            },
            baseURL: this.api.getBaseURL(),
          },
        )
        .then(({ data }) => {
          if (data) {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "Successfully created new event!",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result && result.isConfirmed) {
                this.props.history.push(`/stores/${store._id}/events`);
              }
            });
          }
        });
    } catch (err) {
      this.handleEventError(err);
    }
  };

  onSubmit = async (values, setSubmitting) => {
    const {
      file,
      isEdit,
      isVerifying,
      isNameValid,
      isEventDateValid,
      isEventTimeValid,
      values: { delivery_address },
    } = this.state;

    if (isVerifying || !isNameValid || !isEventDateValid || !isEventTimeValid) {
      setSubmitting(false);
      return;
    }
    try {
      if (isEmpty(delivery_address)) {
        MySwal.fire({
          icon: "info",
          title: "Ooops",
          text: "Location pin cannot be empty",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
        setSubmitting(false);
        return;
      }
      setSubmitting(true);
      if (file) {
        values.image_url = await this.handlePhotoUpload(this.state.file);
      }
      if (isEdit) {
        const { event } = this.state;
        const eventForm = this.formatEventObject();
        const {
          available: prevAvailable,
          capacity: prevCapacity,
          start_date: prevStartDate,
          end_date: prevEndDate,
        } = event;
        const eventDays = eachDayOfInterval({
          start: new Date(prevStartDate),
          end: new Date(prevEndDate),
        });

        const {
          capacity: newCapacity,
          start_date: newStartDate,
          end_date: newEndDate,
        } = eventForm;

        // If yes, check if the new capacity is less than the available seats, if yes notify user
        const availableDrift = prevCapacity * eventDays.length - prevAvailable;
        const newEventDays = eachDayOfInterval({
          start: newStartDate,
          end: newEndDate,
        });
        const newAvailable = newCapacity * newEventDays.length - availableDrift;

        if (newAvailable < 1) {
          let message = `This update will result in a total available seat (${
            newCapacity * newEventDays.length
          }) that is less than the sold seats (${availableDrift}).`;

          if (newAvailable === 0) {
            message = `This update will set the available seat to ZERO (0).`;
          }

          const result = await MySwal.fire({
            icon: "warning",
            title: `Event have sold seats`,
            text: message,
            confirmButtonText: "Confirm",
            showCancelButton: true,
            cancelButtonText: "Abort",
            allowOutsideClick: false,
          });

          if (result.isConfirmed) {
            return this.onUpdateEvent();
          } else {
            setSubmitting(false);
            return Promise.resolve();
          }
        }

        this.onUpdateEvent();
      } else {
        this.onCreateEvent();
      }

      setSubmitting(false);
    } catch (err) {
      this.handleEventError(err);
    }
  };

  handlePhotoUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const { data } = await this.fileapi.post({
        url: "/util/upload-to-aws/events",
        data: formData,
      });
      return data.filePath;
    } catch (error) {
      throw error;
    }
  };

  onVerifyEventName = async (value = "") => {
    if (isEmpty(value)) {
      this.setState({ isVerifying: false, isNameValid: false });
      return;
    }
    const { store, event } = this.state;
    this.setState({ isVerifying: true });

    const result = await fetchData(
      "get",
      `/store/${store._id}/verify-event-name?search=${value}`,
    );

    const isNameExists = result.data.existing && event?._id !== result.data?.id;
    this.setState({ isVerifying: false, isNameValid: !isNameExists });

    return isNameExists;
  };

  debounceVerifyEventName = debounce((value) => {
    this.onVerifyEventName(value);
  }, 500);

  onChange = async (e) => {
    const { isEdit, event } = this.state;
    const { name = "", checked } = e.target;
    if (name === "event_name") {
      await this.debounceVerifyEventName(e.target.value);
    }
    if (name === "description") {
      this.setState({ isDescriptionValid: !isEmpty(e.target.value) });
    }
    if (name === "status") {
      if (e.target.checked) {
        MySwal.fire({
          icon: "info",
          title: "Event will be public",
          text: "This will allow your customers to search for and buy tickets for this event.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "OK",
          showCancelButton: false,
        });
      } else {
        if (isEdit) {
          const { available, capacity } = event;
          if (available < capacity) {
            const result = await MySwal.fire({
              icon: "warning",
              title: `Event will be hidden and not be available for purchase`,
              text: `We noticed there are already purchases for this event. Do you want to continue?`,
              confirmButtonText: "Confirm",
              showCancelButton: true,
              cancelButtonText: "Abort",
              allowOutsideClick: false,
            });

            if (result.isDismissed) return;
          } else {
            MySwal.fire({
              icon: "info",
              title: "Event will be private",
              text: "Event will be hidden and not be available for purchase.",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
          }
        }
      }

      this.setState((state) => ({
        ...state,
        values: {
          ...state.values,
          status: checked ? "public" : "private",
        },
      }));
    }
  };

  render() {
    const {
      values,
      isSubmitting,
      isEdit,
      isNameValid,
      isVerifying,
      isEventDateValid,
      isEventTimeValid,
      isDescriptionValid,
    } = this.state;

    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };

    return (
      <Form onSubmit={this.handleOnSubmit}>
        <div className="d-flex align-items-center justify-content-space-between mb-4">
          <div className="display-4 font-weight-bolder mv-1-rem w-100 align-self-stretch d-flex align-items-center">
            {isEdit ? "Edit" : "Create"} Event &nbsp;
          </div>
          <div className="d-flex flex-row">
            <Button
              type="submit"
              disabled={
                isSubmitting ||
                isVerifying ||
                !isNameValid ||
                !isEventDateValid ||
                !isEventTimeValid ||
                !isDescriptionValid ||
                isEmpty(values.image_url)
              }
              className={GetSubmitClassList(
                isSubmitting,
                `btn btn-primary font-weight-bold px-9 py-4 my-3 text-nowrap`,
              )}
            >
              {`${!isEdit ? "Create" : "Save Changes"}`}
            </Button>
          </div>
        </div>
        <Row>
          <Col sm={6}>
            <Card className={`card-custom mb-4`}>
              <Card.Title className="pb-0 mb-0">
                <div className="flex flex-column flex-grow-1">
                  <h3 className="ml-8 mt-8 font-weight-bolder">
                    Event Name <RequiredAsterisk />
                  </h3>
                </div>
              </Card.Title>
              <Card.Body className="p-0 px-8">
                <div className="d-flex flex-column flex-grow-1">
                  <FormItem
                    customFormControl
                    name={"event_name"}
                    inputProps={{
                      name: "event_name",
                      placeholder: "Enter event name",
                      value: values["event_name"],
                    }}
                    actions={actions}
                    inputActions={inputActions}
                    type={"text"}
                    noLabel
                    normal
                    custom={
                      <Form.Control
                        name="event_name"
                        maxLength={50}
                        value={this.state.values["event_name"]}
                        {...(!isNull(isNameValid) &&
                          actions.isTouched("event_name") && {
                            isValid: isNameValid,
                            isInvalid: !isNameValid,
                          })}
                        {...inputActions}
                      />
                    }
                    helperText={
                      <>
                        {isVerifying && (
                          <div className="text-primary">Validating...</div>
                        )}
                        {!isVerifying &&
                          actions.isTouched("event_name") &&
                          !isNull(isNameValid) &&
                          !isNameValid && (
                            <div className="text-danger">
                              {isEmpty(this.state.values["event_name"])
                                ? "Event name cannot be empty"
                                : "Event name already exists"}
                            </div>
                          )}
                      </>
                    }
                  />
                </div>
              </Card.Body>
            </Card>
            <Card className="card-custom mb-4">
              <Card.Title className="pb-0 mb-0">
                <div className="d-flex flex-column flex-grow-1">
                  <h3 className="ml-8 mt-8 font-weight-bolder">
                    Event Description <RequiredAsterisk />
                  </h3>
                </div>
              </Card.Title>
              <div className="d-flex flex-column px-8 w-100">
                <FormItem
                  className="mt-0"
                  name={"description"}
                  inputProps={{
                    name: "description",
                    value: this.state.values["description"],
                  }}
                  inputActions={inputActions}
                  actions={actions}
                  type={"textarea"}
                  showRequired={true}
                  normal
                  noLabel
                />
              </div>
            </Card>
            <Card className="card-custom mb-4">
              <Card.Title className="pb-0 mb-0">
                <div className="d-flex flex-column flex-grow-1">
                  <h3 className="ml-8 mt-8 font-weight-bolder">
                    Location Pin
                    <RequiredAsterisk />
                  </h3>
                </div>
              </Card.Title>
              <Card.Body className="p-0 px-8 mb-6">
                <MapComponent
                  state={this.state}
                  height="400px"
                  zoom={this.state.values.zoom_value}
                  api={this.api}
                  tooltipDisplay={false}
                  placeholder="Enter event location"
                />
              </Card.Body>
            </Card>
            <Card className={`card-custom mb-4`}>
              <Card.Title className="pb-0 mb-0">
                <div className="d-flex flex-column flex-grow-1">
                  <h3 className="ml-8 mt-8 font-weight-bolder">
                    Event Details
                  </h3>
                </div>
              </Card.Title>
              <Card.Body className="p-0">
                <div className="d-flex flex-row flex-wrap font-weight-bold">
                  <div className="d-flex flex-row pl-4 pr-8 pb-4 align-items-center flex-grow-1">
                    <div className="px-4">
                      From
                      <RequiredAsterisk />
                    </div>
                    <ReactDatePicker
                      wrapperClassName="pr-4 w-100"
                      selected={new Date(values["start_date"])}
                      onChange={(date) => {
                        const { values } = this.state;
                        values["start_date"] = date;

                        const isEventDateValid = moment(
                          new Date(date),
                        ).isSameOrBefore(moment(new Date(values["end_date"])));

                        this.setState({ values, isEventDateValid });
                      }}
                      className={`form-control ${
                        !this.state.isEventDateValid ? "is-invalid" : ""
                      }`}
                      dateFormat={"iiii, MMMM dd, yyyy"}
                      maxDate={values["end_date"]}
                    />
                  </div>
                  <div className="d-flex flex-row pl-4 pr-8 pb-4 align-items-center flex-grow-1">
                    <div className="px-4 pr-6">
                      To
                      <RequiredAsterisk />
                    </div>
                    <ReactDatePicker
                      wrapperClassName="w-100 pr-4"
                      selected={new Date(values["end_date"])}
                      onChange={(date) => {
                        const { values } = this.state;
                        values["end_date"] = date;

                        const isEventDateValid = moment(
                          new Date(date),
                        ).isSameOrAfter(
                          moment(new Date(values["start_date"])),
                          "day",
                        );

                        this.setState({ values, isEventDateValid });
                      }}
                      className={`form-control ${
                        !this.state.isEventDateValid ? "is-invalid" : ""
                      }`}
                      dateFormat={"iiii, MMMM dd, yyyy"}
                      minDate={values["start_date"]}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row flex-wrap font-weight-bold">
                  <div className="d-flex flex-row pl-4 pr-8 pb-4 align-items-center flex-grow-1">
                    <div className="px-4 pr-6">
                      Start
                      <RequiredAsterisk />
                    </div>
                    <DatePicker
                      inputClass={`form-control ${
                        !this.state.isEventTimeValid ? "is-invalid" : ""
                      }`}
                      disableDayPicker
                      value={values["start_time"]}
                      format="hh:mm A"
                      plugins={[<TimePicker hideSeconds />]}
                      onChange={(time) => {
                        const { values } = this.state;
                        values["start_time"] = new Date(time);

                        const isEventTimeValid = moment(
                          new Date(time),
                        ).isSameOrBefore(moment(values["end_time"]), "day");
                        this.setState({ values, isEventTimeValid });
                      }}
                    />
                  </div>
                  <div className="d-flex flex-row pl-4 pr-8 pb-4 align-items-center flex-grow-1">
                    <div className="px-4 pr-6">
                      End
                      <RequiredAsterisk />
                    </div>
                    <DatePicker
                      inputClass={`form-control ${
                        !this.state.isEventTimeValid ? "is-invalid" : ""
                      }`}
                      disableDayPicker
                      value={values["end_time"]}
                      format="hh:mm A"
                      plugins={[<TimePicker hideSeconds />]}
                      onChange={(time) => {
                        const { values } = this.state;
                        values["end_time"] = new Date(time);
                        const isEventTimeValid = moment(new Date(time)).isAfter(
                          moment(values["start_time"]),
                        );
                        this.setState({ values, isEventTimeValid });
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-row px-8 w-100 ">
                  <FormItem
                    className={"form-control-solid"}
                    style={{ flexBasis: "50%", paddingRight: 20 }}
                    name={"price"}
                    label={"Regular Price"}
                    inputProps={{
                      name: "price",
                      placeholder: "Enter ticket price",
                      value: values["price"],
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    normal
                    showRequired
                    type={"number"}
                  />
                  <FormItem
                    className={"form-control-solid"}
                    style={{ flexBasis: "50%" }}
                    name={"capacity"}
                    label={"Capacity per day"}
                    inputProps={{
                      name: "capacity",
                      placeholder: "Enter capacity value",
                      value: values["capacity"],
                      min: 0,
                    }}
                    inputActions={inputActions}
                    actions={actions}
                    normal
                    showRequired
                    type={"number"}
                  />
                  <div className="flex justify-content-center p-2">
                    <IconTooltip
                      infoText="Set to 0 if capacity is unlimited. "
                      iconFAClass="fas fa-info-circle"
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>

          <Col sm={6}>
            <Card className="card-custom mb-4">
              <Card.Body>
                <div className="d-flex flex-row justify-content-space-between align-items-center">
                  <h3 className="font-weight-bolder mb-0">
                    Status <RequiredAsterisk />
                  </h3>
                  <span className="switch switch-outline switch-icon switch-primary switch-md">
                    <div className="d-flex flex-row justify-content-center">
                      <label className="mb-0">
                        <input
                          type="checkbox"
                          name="status"
                          checked={
                            this.state.values.status === "public"
                              ? "checked"
                              : null
                          }
                          {...inputActions}
                          onChange={this.onChange}
                        />
                        <label className="ml-2 mt-2">
                          {this.state.values.status === "public"
                            ? "Public"
                            : "Private"}
                        </label>
                        <span></span>
                      </label>
                    </div>
                  </span>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-custom">
              <Card.Title className="pb-0 mb-0">
                <div className="flex flex-colum flex-grow-1 w-full justify-center align-items-center mx-8 mt-8 mb-1">
                  <h3 className="font-weight-bolder">
                    Header Image <RequiredAsterisk />
                  </h3>
                </div>
              </Card.Title>
              <Card.Body>
                {!values.image_url && (
                  <ImageUpload
                    custom_size={{ width: "100%", height: 300 }}
                    onError={actions.handleError}
                    onChange={(file, preview) => {
                      let { values = {} } = this.state;
                      values.image_url = preview;
                      this.setState({ file, values });
                    }}
                    label={
                      <div style={{ margin: "1rem 0" }}>
                        <div
                          className="text-muted"
                          style={{ fontSize: "0.8rem" }}
                        >
                          Maximum file size is 25MB. <br />
                        </div>
                        <div
                          className="text-danger"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {this.handleFeedbackError("image_url", "error-text")}
                        </div>
                      </div>
                    }
                    maxFileSize={25000}
                    maxFileSizeText="25MB"
                  />
                )}
                {values.image_url && (
                  <PreviewPhoto
                    className="w-100"
                    style={{ height: 300, objectFit: "cover" }}
                    src={values.image_url}
                    onClickRemove={() => {
                      this.setState({
                        values: { ...values, image_url: "" },
                      });
                    }}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}
