import React from "react";
import APIComponent from "../components/API";
import core from "../vendors/core-api";
import TitleHelmet from "./TitleHelmet";
import { Layout, LayoutSplashScreen } from "../../_metronic/layout";
import SelectionModal from "../components/widgets/SelectionModal";
import OrderDetailsForm from "./branded-website/OrderDetailsModal";
import OrderDetailsModalNew from "./branded-website/OrderDetailsModalNew";
import { Redirect } from "react-router-dom";
import {
  getStorage,
  setStorage,
  // removeStorage,
} from "../../_metronic/_helpers";
import {
  GetDataFromEvent,
  checkIfStoreOpenForOrders,
  checkIfCanCheckout,
} from "../helpers";
import CartModal from "./branded-website/CartModal";

import Iframe from "react-iframe";
import qs from "qs";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  PRIMARY_COLOR,
  ASAP_ORDER_TIME_FORMAT,
  DANGER_SWAL_COLOR,
  DATERANGE_LIST,
} from "../constants";
import moment from "moment";
import axios from "axios";
import { Modal } from "react-bootstrap";
import LimitsModal from "./branded-website/LimitsModal";
import { setTheme } from "../theme";
import { deviceDetect } from "react-device-detect";
// import Group from "./branded-website/Group/index";

import ReactGA from "react-ga4";
import {
  browserStorage,
  generateMealPlanDates,
  getMaxItemsInMealPlan,
} from "../helpers/branded-website";

import PacmanLoading from "../components/widgets/PacmanLoading/PacmanLoading";
import {
  StoreHasDelivery,
  storeHasPickupActive,
  storeHasCurbside,
  storeHasMealPlan,
} from "../components/Base";
import { isEmpty, isNil } from "lodash";
const instance = axios.create({
  withCredentials: true,
});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

const MySwal = withReactContent(Swal);

export default class BrandWebsite extends APIComponent {
  constructor(props) {
    super(props);

    let { hostname = "", store_url = "", menu_version = 1 } = this.props,
      website_type = store_url !== "" ? "store" : "brand";

    if (hostname === "localhost") hostname = process.env.REACT_APP_HOSTNAME;
    let brand_url = hostname.split(".")[1] ? hostname.split(".")[0] : "",
      data_url = `/branded-website/brands/${brand_url}`;

    if (website_type === "store") data_url += `?store=${store_url}`;

    const { preview_token = "", token = "" } = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true },
    );

    let order_date = "",
      order_time = "",
      order_type = "",
      order_subtype = "",
      referrer = "",
      meal_plan_type = "";

    try {
      const token_data =
        token !== ""
          ? JSON.parse(window.atob(token))
          : {
              order_date: "",
              order_time: "",
              order_type: "",
              order_subtype: "",
              meal_plan_type: "",
            };
      order_date = token_data.order_date;
      order_time = token_data.order_time;
      order_type = token_data.order_type;
      order_subtype = token_data.order_subtype;
      meal_plan_type = token_data.meal_plan_type;

      // console.log("brandwebsite constructor token_data: ", token_data)
    } catch (err) {
      /** do nothing */
    }

    this.state = {
      brand_url,
      showSplashScreen: true,
      redirectTo: "",
      redirectPath: "",
      title: "",
      store_id: "",
      selected_store: {},
      cart: {
        order_type: order_subtype !== "" ? order_subtype : order_type,
        order_date: order_date === "" ? DATERANGE_LIST[0].date : order_date,
        order_time: order_time === "" ? ASAP_ORDER_TIME_FORMAT : order_time,
        delivery_address: "",
        items: [],
        items_count: 0,
        meal_plan_type: meal_plan_type,
      },
      showCartModal: false,
      storage_key: "",
      layout_props: {
        type: "branded-website",
        logo: "",
      },
      showSelectionModal: false,
      renderModals: true,
      preview_token,
      website_type,
      isHomepage: false,
      order_subtype,
      referrer,
      selected_item_category: {},
      firstStoreUrl: "",
      isPageUnavailable: false,
    };

    this.api = core("pu").get();
    this.MENU_VERSION = localStorage.getItem("FF_MENU_VERSION") || menu_version;
    this.data_url = data_url;

    this.renderContent = this.renderContent.bind(this);
    this.handleOnClickCart = this.handleOnClickCart.bind(this);
    this.handleOnSubmitOrderDetailsForm =
      this.handleOnSubmitOrderDetailsForm.bind(this);
    this.handleOnDataFetched2 = this.handleOnDataFetched2.bind(this);
    this.updateCart = this.updateCart.bind(this);
    // this.getDataFromCartStorage = this.getDataFromCartStorage.bind(this);
    this.getItemById = this.getItemById.bind(this);
    // this.handleOnClickAddToCart = this.handleOnClickAddToCart.bind(this);
    this.handleOnClickCtr = this.handleOnClickCtr.bind(this);
    this.handleOnClickRemoveItem = this.handleOnClickRemoveItem.bind(this);
    this.handleOnClickCheckout = this.handleOnClickCheckout.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.handleOnPlaceOrder = this.handleOnPlaceOrder.bind(this);
    this.afterCheckout = this.afterCheckout.bind(this);
    this.onClickUseDifferentPaymentMethod =
      this.onClickUseDifferentPaymentMethod.bind(this);
    this.verifyPreviewToken = this.verifyPreviewToken.bind(this);
    this.checkStocks = this.checkStocks.bind(this);
    this.initCurrentAvailableCounters =
      this.initCurrentAvailableCounters.bind(this);
    this.onClickUpdateCartAfterCheckout =
      this.onClickUpdateCartAfterCheckout.bind(this);
    this.removeItemFromCartAfterCheckout =
      this.removeItemFromCartAfterCheckout.bind(this);
    this.handleOnSubmitOrderDetailsFormCheckout =
      this.handleOnSubmitOrderDetailsFormCheckout.bind(this);
    this.handleOnClickEditTimeFromError =
      this.handleOnClickEditTimeFromError.bind(this);
    this.onCopyToClipboardSuccess = this.onCopyToClipboardSuccess.bind(this);
    this.onClickRenewReservationButton =
      this.onClickRenewReservationButton.bind(this);
    this.getSelectedStoreData = this.getSelectedStoreData.bind(this);
    this.updateSelectedStore = this.updateSelectedStore.bind(this);

    this.handleOnSubmitChangeDateForm =
      this.handleOnSubmitChangeDateForm.bind(this);
    this.mountTimer = this.mountTimer.bind(this);
    this.onClickCancelOrder = this.onClickCancelOrder.bind(this);
    this.onClickOrderAgain = this.onClickOrderAgain.bind(this);

    this.setSelectedItem = this.setSelectedItem.bind(this);
    this.getItemFromItemsDirectory = this.getItemFromItemsDirectory.bind(this);
    this.getCategoryFromCategoriesDirectory =
      this.getCategoryFromCategoriesDirectory.bind(this);
    this.handleOnClickAddToCartFromMenu =
      this.handleOnClickAddToCartFromMenu.bind(this);
    this.limitChecker = this.limitChecker.bind(this);
    this.updateCurrentTakenCounter = this.updateCurrentTakenCounter.bind(this);
    this.setCheckoutTakenCounters = this.setCheckoutTakenCounters.bind(this);
    this.markAsExpired = this.markAsExpired.bind(this);
    this.sendErrorData = this.sendErrorData.bind(this);
    this.checkoutFormRef = React.createRef();
    this.toggleUnavailablePage = this.toggleUnavailablePage.bind(this);
  }

  componentDidUpdate() {
    // console.log("component did update state:", this.state)
    // console.log("component did update props:", this.props)
  }

  async getData() {
    // console.time("get data");
    if (this.data_url !== "") {
      // add preview query to api call
      if (this.state.preview_token !== "") {
        this.data_url += "/?preview=true";
      }
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url)
          .then(({ data }) => {
            const {
              website_status = "",
              result = "ok",
              error = "",
              website_theme = "",
              group_type = "",
            } = data;
            const url = window.location.href;
            // localStorage.setItem('website_type', website_type)

            const state = {
              website_theme: setTheme(
                website_theme !== "" && website_theme !== null
                  ? JSON.parse(website_theme)
                  : {},
              ),
              group_type,
            };

            if (result === "error") {
              if (error === "brand_not_found") {
                this._isMounted && this.setState({ redirectTo: "/not-found" });
                this._isMounted = false;
              }
            } else {
              if (
                website_status === "private" &&
                url.indexOf("/coming-soon") === -1
              ) {
                if (url.indexOf("?preview_token=") !== -1) {
                  this.verifyPreviewToken(data, state);
                } else {
                  this._isMounted &&
                    this.setState({ redirectTo: "/coming-soon", ...state });
                  this._isMounted = false;
                }
              } else {
                this._isMounted &&
                  this.setState({ data, data_status: "fetched", ...state });
                this._isMounted && this.handleOnDataFetched();
              }

              if (this.use_display_data) {
                this.setDisplayData();
              }
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
    // console.timeEnd("get data");
  }

  async verifyPreviewToken(data = {}, state) {
    try {
      const { brand_url = "", preview_token = "" } = this.state;
      // const parsedUrl = new URL(window.location.href);
      // const token = parsedUrl.search.replace("?preview_token=", "").trim();
      const preview_token_data = await this.api.post({
        url: `/branded-website/brands/${brand_url}/verify-preview-token`,
        data: { token: preview_token },
      });
      if (preview_token_data.hasOwnProperty("data")) {
        const { _id = "" } = preview_token_data.data;

        if (_id === data._id) {
          this._isMounted &&
            this.setState({
              data,
              data_status: "fetched",
              preview_token,
              ...state,
            });
          this._isMounted && this.handleOnDataFetched();
        } else {
          this._isMounted && this.setState({ redirectTo: "/coming-soon" });
          this._isMounted = false;
        }
      }
    } catch (err) {
      this._isMounted && this.setState({ redirectTo: "/coming-soon" });
      this._isMounted = false;
    }
  }

  async handleOnPlaceOrder() {
    /** do nothing */
  }

  async sendErrorData(reqbody) {
    const baseURL = this.api.getBaseURL();
    await instance.post("/branded-website/payments/errors", reqbody, {
      baseURL,
    });
  }

  setReferrer = () => {
    const params = new URLSearchParams(document?.location?.search);
    const utm_campaign = params.get("utm_campaign");
    const utm_source = params.get("utm_source");

    const referrer = utm_campaign || utm_source || document.referrer;
    if (this.state.referrer !== referrer) {
      let actualReferrer = referrer;
      const campaign = browserStorage().getItem("pickup_utm_campaign");

      if (!isNil(campaign) && !isEmpty(campaign)) {
        actualReferrer = campaign;
      }

      this.setState({ referrer: actualReferrer || "" });
    }
  };

  getSelectedStoreData = () => {
    let baseURL = this.api.getBaseURL(),
      { store_id = "", brand_url = "", cart = {} } = this.state,
      { order_date = "", order_time = "" } = cart;
    console.log("getSelectedStore BrandWebsite", store_id);
    instance
      .get(
        `/branded-website/stores/${store_id}?order_date=${order_date}&order_time=${order_time}`,
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then(({ data }) => {
        const {
          store = {},
          is_using_asap = false,
          invalid_params = false,
        } = data;
        const { items_directory = {}, categories_directory = {} } = store;

        if (order_date === "") {
          cart.order_type = "pickup";
          cart.order_date = data.order_date;
        }

        if (order_time === "") {
          cart.order_time = is_using_asap
            ? ASAP_ORDER_TIME_FORMAT
            : data.order_time;
        }

        if (invalid_params) {
          cart.order_date = data.order_date;
          cart.order_time = is_using_asap
            ? ASAP_ORDER_TIME_FORMAT
            : data.order_time;
        }
        this.setState(
          {
            mountOrderDetailsForm: false,
            mountOrderDetailsFormNew: false,
            items_directory,
            categories_directory,
            cart,
            selected_store: store,
            showSplashScreen: false,
          },
          () => {
            this.setState({ mountStore: true, mountOrderDetailsForm: true });
            this.initCurrentAvailableCounters();
            this.onGetSelectedStoreData && this.onGetSelectedStoreData();
          },
        );
      })
      .catch((err) => {
        this.handleError(err);
      });
  };

  afterCheckout = () => {
    /** do nothing */
  };

  onClickUseDifferentPaymentMethod = () => {
    /** do nothing */
  };

  onClickUpdateCartAfterCheckout = () => {
    /** do nothing */
  };

  removeItemFromCartAfterCheckout = () => {
    /** do nothing */
  };

  handleOnSubmitOrderDetailsFormCheckout = () => {
    /** do nothing */
  };

  onCopyToClipboardSuccess = () => {};

  onClickRenewReservationButton = () => {};

  onClickOrderAgain = () => {};

  mountTimer = () => {};

  updateSelectedStore = () => {};

  markAsExpired = () => {};

  onClickCancelOrder = () => {
    this.setState({
      redirectTo: "/cancelled-order",
    });
  };

  handleOnError = () => {
    this.showErrorSwal();
  };

  handleOnDataFetched = () => {
    let {
      data: { _id = "", brand_logo = "", navbar_logo = "", stores = [] },
      layout_props = {},
    } = this.state;
    const storeUrlArr = stores.map((store) => store.store_url);
    this.setState({ firstStoreUrl: storeUrlArr[0] });

    layout_props.logo =
      navbar_logo !== "" && navbar_logo !== null ? navbar_logo : brand_logo;
    // layout_props.logo = toAbsoluteUrl("/media/logos/pickup-logo-1.png");

    this.setState({ storage_key: `${_id}_CART`, layout_props });

    this.setTitle();
    this.handleOnDataFetched2();
  };

  /** call this if you don't want to overwrite handleOnDataFetched */
  handleOnDataFetched2 = () => {
    // console.log("main cart", this.state.cart);
    if (this.state.cart !== undefined) {
      setStorage(
        this.state.storage_key,
        JSON.stringify({ cart: this.state.cart }),
        86400,
      );
    }
    // this.getDataFromCartStorage();
  };

  getItemFromItemsDirectory = (item_id = "") => {
    const { items_directory = {} } = this.state;
    return items_directory.hasOwnProperty(item_id)
      ? items_directory[item_id]
      : {};
  };

  getCategoryFromCategoriesDirectory = (category_name = "") => {
    const { categories_directory = [] } = this.state;

    return (
      categories_directory.find((category) => {
        return category.category_name === category_name;
      }) || {}
    );
  };

  setTitle = () => {
    // can be overwritten
    const {
      data: { name = "" },
      preview_token = "",
    } = this.state;
    this.setState({
      title:
        preview_token !== ""
          ? `[Preview Mode] Home | ${name}`
          : `Home | ${name}`,
    });
  };

  // JD: Removed getting from storage since it's buggy
  // getDataFromCartStorage = () => {
  //   let cartStr = getStorage(this.state.storage_key),
  //     { state = {} } = this.state;
  //   if (cartStr !== null) {
  //     let cart_storage = JSON.parse(cartStr);
  //     state.cart = cart_storage.cart;
  //   } else {
  //     console.log("No cart storage found");
  //   }
  //   this.setState(state);
  // };

  setSelectedItem = (selected_item, available, activeTags) => {
    if (this.checkStocks(selected_item._id, 1)) {
      selected_item.available = available;
      const selected_item_category = this.getCategoryFromCategoriesDirectory(
        selected_item.category,
      );
      this.setState(
        {
          mountShowSelectedItemModal: false,
          selected_item,
          selected_item_category,
        },
        () => {
          this.setState({
            showSelectedItemModal: true,
            mountShowSelectedItemModal: true,
            selectedItemTags: activeTags,
          });
        },
      );
    }
  };

  handleOnClickCart = (e) => {
    // 'Cart' in Topbar
    if (e) e.preventDefault();

    const { stores = [] } = this.state.data;

    const selected_store = stores.length > 0 ? stores[0] : {};

    if (!checkIfStoreOpenForOrders(selected_store)) {
      return MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `OH NO! THIS STORE IS CURRENTLY CLOSED AND WILL START ACCEPTING ORDERS UPON REOPENING`,
        confirmButtonColor: PRIMARY_COLOR,
      });
    }

    let { cart = {} } = this.state;

    if (cart.order_type === "") {
      // const { isPickupActive, isDeliveryActive, isMealPlanActive, isCurbsideActive } = selected_store;
      const isPickupActive = storeHasPickupActive(selected_store);
      const isDeliveryActive = StoreHasDelivery(selected_store);
      const isCurbsideActive = storeHasCurbside(selected_store);
      const isMealPlanActive = storeHasMealPlan(selected_store);

      if (isPickupActive) {
        // Pickup will be the default if it's active
        cart.order_type = "pickup";
        this.setState({ showPickupDetailsModal: true, cart });
      } else if (isDeliveryActive) {
        cart.order_type = "delivery";
        this.setState({ showDeliveryDetailsModal: true, cart });
      } else if (isCurbsideActive) {
        cart.order_type = "pickup";
        cart.order_subtype = "curbside_pickup";
        this.setState({
          order_subtype: "curbside_pickup",
          showPickupDetailsModal: true,
          cart,
        });
      } else if (isMealPlanActive) {
        this.setState({
          order_subtype: "meal_plan",
          showDeliveryDetailsModal: true,
          cart,
        });
      }
    } else {
      this.setState({ showCartModal: true });
    }

    //comment out above code, will revert back once go signal is given
    // this.setState({showPickupDetailsModal: true})
  };

  updateCart = (items, items_count) => {
    let { cart = {} } = this.state;
    let hasBulk;

    cart.items = items.map((item) => {
      item.included = true;
      return item;
    });
    cart.items_count = items_count;
    hasBulk = items.find((item) => item.isBulk === true);
    if (hasBulk) {
      cart.hasBulk = true;
    } else {
      cart.hasBulk = false;
    }
    // console.log("update cart cart.hasBulk: ", cart.hasBulk)

    this.setState({ cart });
    setStorage(
      this.state.storage_key,
      JSON.stringify({ cart: this.state.cart }),
      86400,
    );
  };

  handleOnSubmitOrderDetailsForm = (values, setSubmitting) => {
    // "Pickup" & "Delivery" details form
    setSubmitting(true);

    let {
        preview_token = "",
        group_type = "",
        isHomepage = false,
      } = this.state,
      {
        order_date = "",
        order_time = "",
        order_type = "",
        order_subtype = "",
        selectedUrl,
        store: store_id,
        meal_plan_type,
      } = values;
    let { cart = {}, firstStoreUrl } = this.state,
      showCheckoutFailedMessage = false,
      showCartModalOrderTimeError = false,
      showItemsUnavailableForTimeSlotError = false;

    if (this.MENU_VERSION === "2") {
      const params = {
        id: store_id,
        order_type: order_subtype || order_type,
        session_ts: Date.now(),
        referrer: this.state.referrer,
      };

      let menuUrl = process.env.REACT_APP_MENU_REVAMP_URL;

      if (order_subtype === "meal_plan") {
        let meal_plan_type_revamp = "3_day";

        if (meal_plan_type === "5DayMealPlan") meal_plan_type_revamp = "5_day";

        params.meal_plan_type = meal_plan_type_revamp;
      } else {
        params.order_date = order_date;
        params.order_time = order_time;
      }

      return window.location.assign(`${menuUrl}?${qs.stringify(params)}`);
    }

    const maxItemsInMealPlan = cart.isMealPlan
      ? getMaxItemsInMealPlan(meal_plan_type)
      : 0;

    if (
      (cart.isMealPlan && cart.items.length <= maxItemsInMealPlan) ||
      !cart.isMealPlan
    ) {
      cart.order_date = order_date;
      cart.order_time = order_time;
      cart.meal_plan_type = meal_plan_type;
      cart.order_subtype = order_subtype;
      cart.order_type = order_subtype || order_type;

      // Check if updates affected availability
      showItemsUnavailableForTimeSlotError = cart.items.some((cartItem) => {
        const { item = {} } = cartItem;
        if (!showItemsUnavailableForTimeSlotError) {
          return item.off_dates.includes(order_date);
        }
      });

      const mealPlanDates = generateMealPlanDates(meal_plan_type);

      cart.items = cart.items.map((cartItems, itemIndex) => {
        // Check if meal plan items need delivery dates update
        if (cart.isMealPlan) {
          const order_date =
            mealPlanDates[
              cartItems.isExclusive ? mealPlanDates.length - 1 : itemIndex
            ];

          cartItems.order_date = order_date;
        }

        return cartItems;
      });

      this.setState(
        {
          showCartModalOrderTimeError,
          showCheckoutFailedMessage,
          showItemsUnavailableForTimeSlotError,
          showPickupDetailsModal: false,
          showDeliveryDetailsModal: false,
          cart,
          store_id,
        },
        () => {
          // const order_token = window.btoa(JSON.stringify({
          //     order_date,
          //     order_time,
          //     order_type: order_subtype === "meal_plan"
          //         ? "delivery"
          //         : cart.order_type,
          //     order_subtype,
          //     meal_plan_type
          // })),
          const store_prefix = group_type === "multi" ? `/${selectedUrl}` : "";
          const tokenParams = {
            order_date,
            order_time,
            order_type:
              order_subtype === "meal_plan" ? "delivery" : cart.order_type,
            order_subtype,
            meal_plan_type,
          };
          const order_token = this.generateOrderToken(tokenParams);
          this.updateCart(cart.items, cart.items_count);
          const tokenUrl =
            preview_token !== ""
              ? `${store_prefix}/menu?preview_token=${preview_token}&token=${order_token}`
              : `${store_prefix}/menu?token=${order_token}`;
          const isFirstStore = selectedUrl === firstStoreUrl;
          if (window.location.pathname === "/menu" && isFirstStore) {
            this.setState({ redirectTo: tokenUrl });
          } else if (window.location.pathname === "/menu" && !isFirstStore) {
            MySwal.fire({
              icon: "warning",
              title: "Ooops!",
              text: `This will redirect you to another store and basket items will be cleared. Do you wish to proceed?`,
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Yes",
              cancelButtonText: "No",
              showCancelButton: true,
            }).then((response) => {
              if (response.isConfirmed) window.location.href = tokenUrl;
            });
          } else {
            this.setState({ redirectTo: tokenUrl });
          }

          if (!isHomepage) {
            this.getSelectedStoreData();
          }
        },
      );
    } else {
      this.displayErrorSwal(
        `Kindly remove some items on your basket before changing your meal plan type.`,
      );
    }
  };

  generateOrderToken = (tokenParams) => {
    // console.log("generateOrderToken tokenParams : ", tokenParams)
    const order_token = window.btoa(JSON.stringify(tokenParams));
    return order_token;
  };

  getItemById = (id) => {
    const {
      selected_store: { menu = [] },
    } = this.state;
    const item = menu.filter(({ item_id = {} }) => {
      return item_id._id === id;
    });

    return item.length > 0 ? item[0].item_id : {};
  };

  initCurrentAvailableCounters = () => {
    console.time("init current available counters");
    const { cart = {} } = this.state;

    cart.items.forEach((item) => {
      this.adjustCurrentAvailableCounters(item.item._id, item.qty);
    });
    console.timeEnd("init current available counters");
  };

  updateCurrentTakenCounter = (data = {}, qty = 0) => {
    const { current_taken_counter = 0 } = data;
    return current_taken_counter + qty;
  };

  setCheckoutTakenCounters = () => {
    /** To be used in Checkout page */
  };

  adjustCurrentAvailableCounters = (item_id = "", qty = 0) => {
    const item = this.getItemFromItemsDirectory(item_id);
    const category = this.getCategoryFromCategoriesDirectory(item.category);

    this.setState((prevState) => {
      const { categories_directory, items_directory } = prevState;

      const updatedItemsDirectory = {
        ...items_directory,
        [item_id]: {
          ...items_directory[item_id],
          current_taken_counter:
            (items_directory[item_id].current_taken_counter || 0) + qty,
        },
      };

      const updatedCategories = categories_directory.map((current) => {
        if (category.category_name === current.category_name) {
          current.current_taken_counter =
            (current.current_taken_counter || 0) + qty;
        }

        return current;
      });

      return {
        items_directory: updatedItemsDirectory,
        categories_directory: updatedCategories,
      };
    });
  };

  limitChecker = (data = {}, qty = 0) => {
    const {
      current_taken_counter = 0,
      available_count_slot = -1,
      available_count = -1,
    } = data;
    // let current_taken_counter = 0;
    // const localCart = JSON.parse(getStorage(this.state.storage_key));
    // if (localCart !== undefined || localCart !== null) {
    //   current_taken_counter = localCart.cart.items_count;
    // }

    let selected_limit = -1,
      selected_limit_label = "",
      proceed = true;

    if (available_count !== -1 && available_count_slot !== -1) {
      // if both have limits
      selected_limit =
        available_count < available_count_slot
          ? available_count
          : available_count_slot;
      selected_limit_label =
        available_count < available_count_slot ? "day" : "time slot";
    } else if (available_count_slot !== -1 && available_count === -1) {
      // time slot limit only
      selected_limit = available_count_slot;
      selected_limit_label = "time slot";
    } else if (available_count_slot === -1 && available_count !== -1) {
      // daily limits only
      selected_limit = available_count;
      selected_limit_label = "day";
    }

    if (selected_limit !== -1) {
      const new_taken_counter = qty + current_taken_counter;
      if (new_taken_counter > selected_limit) {
        proceed = false;
      }
    }

    return { proceed, selected_limit, selected_limit_label };
  };

  // JD - checkStocks() is used for checking if user can still add an item to the cart based on limits
  // Return a Boolean
  checkStocks = (item_id = "", qty = 0) => {
    const { cart = {} } = this.state;

    let proceed = true;
    const item = this.getItemFromItemsDirectory(item_id);
    const category = this.getCategoryFromCategoriesDirectory(item.category);
    const addedExclusiveItemsCount = cart.items.filter(
      (cart_item) => cart_item.isExclusive,
    ).length;

    if (cart.isMealPlan) {
      if (cart.meal_plan_type === "") {
        proceed = false;
        this.handleOnClickCart();
      } else {
        const maxItemsInMealPlan = getMaxItemsInMealPlan(cart.meal_plan_type);

        if (cart.items_count + qty > maxItemsInMealPlan) {
          proceed = false;
          this.displayErrorSwal(
            `This is a meal plan order. You can't add any more to the cart.`,
          );
        } else if (
          category.isExclusive &&
          (qty > 1 || addedExclusiveItemsCount === 1)
        ) {
          proceed = false;
          this.displayErrorSwal(`You can only add one exclusive item.`);
        } else if (
          addedExclusiveItemsCount === 0 &&
          !category.isExclusive &&
          cart.items_count + qty === maxItemsInMealPlan
        ) {
          proceed = false;
          this.displayErrorSwal(`You have to add at least one exclusive item.`);
        }
      }
    } else {
      const item_limit_checker = this.limitChecker(item, qty),
        category_limit_checker = this.limitChecker(category, qty);
      // console.log("checkstocks cart: ", cart);
      if (!item_limit_checker.proceed) {
        proceed = false;
        this.setState({ mountLimitsModal: false }, () => {
          this.setState({
            mountLimitsModal: true,
            showLimitsModal: true,
            limitsModalText: `You can only add ${
              item_limit_checker.selected_limit
            } pcs of this item for this ${
              item_limit_checker.selected_limit_label
            }. ${
              !cart.isInStoreOrder
                ? `Do you want to change your order schedule?`
                : ``
            }`,
          });
        });
      } else if (!category_limit_checker.proceed) {
        proceed = false;
        this.setState({ mountLimitsModal: false }, () => {
          this.setState({
            mountLimitsModal: true,
            showLimitsModal: true,
            limitsModalText: `You can only add ${
              category_limit_checker.selected_limit
            } pcs of this category for this ${
              category_limit_checker.selected_limit_label
            }. ${
              !cart.isInStoreOrder
                ? `Do you want to change your order schedule?`
                : ``
            }`,
          });
        });
      }
    }

    console.timeEnd("check stocks");
    return proceed;
  };

  displayErrorSwal = (message = "") => {
    MySwal.fire({
      icon: "error",
      title: "Ooops!",
      text: message,
      confirmButtonColor: DANGER_SWAL_COLOR,
    });
  };

  handleOnSubmitChangeDateForm = (values, setSubmitting) => {
    let { cart = {} } = this.state;
    const { order_date = "", order_time = "" } = values;

    cart.order_date = order_date;
    cart.order_time = order_time;
    this.setState({ cart, showLimitsModal: false });
  };

  handleOnClickAddToCartFromMenu = () => {
    /** JD: See updated version in Menu.js */
  };

  handleOnClickEditTimeFromError = () => {
    this.setState({ showPickupDetailsModal: true });
  };

  // JD - `handleOnClickCtr` is the event on the increment and decrement counters in the Basket modal
  handleOnClickCtr = (e) => {
    const id = GetDataFromEvent(e, "data-id"),
      action = GetDataFromEvent(e, "data-action"),
      index = GetDataFromEvent(e, "data-index");
    let { cart = {} } = this.state,
      items = [],
      items_count = 0;

    for (var i = 0; i < cart.items.length; i++) {
      let cart_item = cart.items[i];
      let { qty = 0 } = cart_item;

      if (i === parseInt(index)) {
        if (action === "plus" && this.checkStocks(id, 1)) {
          qty++;
          this.adjustCurrentAvailableCounters(id, 1);
        } else if (action === "minus") {
          qty--;
          this.adjustCurrentAvailableCounters(id, -1);
        }
      }

      if (qty > 0) {
        cart_item.qty = qty;
        cart_item.included = true; // to cancel out the not included class on the items
        items.push(cart_item);
      }

      items_count += qty;
    }

    this.setState({
      cart: { items, items_count },
      showCheckoutFailedMessage: false,
      showCartModalOrderTimeError: false,
      showItemsUnavailableForTimeSlotError: false,
    });
    this.updateCart(items, items_count);
  };

  handleOnClickRemoveItem = (index) => {
    // Used by meal plan feature
    let { cart = {} } = this.state,
      items = [],
      items_count = 0;

    // Loop for removing the item in the cart
    for (let i = 0; i < cart.items.length; i++) {
      if (index !== i) {
        let cart_item = cart.items[i];
        items.push(cart_item);
        items_count += cart_item.qty;
      }
    }

    // Re-aligning the dates once an item has been removed to retain the sequential adding of the items in the plan
    for (let i = 0; i < items.length; i++) {
      if (cart.isMealPlan) {
        items[i].order_date = items[i].isExclusive
          ? items[i].order_date
          : cart.order_dates[i];
      }
    }

    this.setState({ cart: { items, items_count } });
    this.updateCart(items, items_count);
  };

  handleOnClickCheckout = () => {
    // "Proceed to checkout button"
    const { cart = {}, referrer = "", selected_store = {} } = this.state,
      { order_date = "" } = cart,
      hasMealPlanDiscount =
        cart.isMealPlan &&
        selected_store.hasOwnProperty("mealPlanDiscount") &&
        selected_store.mealPlanDiscount > 0;

    let showItemsUnavailableForTimeSlotError = false;

    // console.log("brandwebsite handleOnClickCheckout cart: ", cart);

    if (!cart.isMealPlan)
      cart.items = cart.items.map((cartItem) => {
        const { item = {} } = cartItem;
        if (!showItemsUnavailableForTimeSlotError) {
          showItemsUnavailableForTimeSlotError =
            item.off_dates.includes(order_date);
        }

        return cartItem;
      });

    if (hasMealPlanDiscount) {
      cart.mealPlanDiscount = selected_store.mealPlanDiscount;
    }

    if (!showItemsUnavailableForTimeSlotError) {
      const {
          store_id = "",
          brand_url = "",
          storage_key = "",
          data = {},
        } = this.state,
        baseURL = this.api.getBaseURL();

      const { order_time = "" } = cart;

      // if order_time is ASAP, canCheckout is automatically true
      // if preorder, check if current datetime is before the order datetime minus 20 minutes (qualified)
      const canCheckout =
        order_time === ASAP_ORDER_TIME_FORMAT
          ? true
          : checkIfCanCheckout({ order_date, order_time });

      // console.log("handleOnClickCheckout state: ", this.state)
      // console.log("handleOnClickCheckout props: ", this.props)
      // console.log("handleOnClickCheckout canCheckout: ", canCheckout)

      if (canCheckout) {
        cart.device = deviceDetect();
        if (this.state.order_subtype === "meal_plan") {
          cart.isMealPlan = true;
          if (this.state.meal_plan_type) {
            cart.meal_plan_type = this.state.meal_plan_type;
          }
        }
        this.setState({ checkoutLoading: true });

        // ReactGA.event({
        //     category: "proceedToCheckout",
        //     action: "submitProceedToCheckout",
        //     label: "Submit Proceed To Checkout", // optional
        //     // value: 99, // optional, must be a number
        //     // nonInteraction: true, // optional, true/false
        //     // transport: "xhr", // optional, beacon/xhr/image
        //     store_id: store_id,
        // });

        // console.log("brandwebsite on proceed to checkout state: ", this.state)
        // console.log("brandwebsite on proceed to checkout props: ", this.props)

        ReactGA.event("submitProceedToCheckout", {
          category: "proceedToCheckout",
          action: "submitProceedToCheckout",
          label: "Submit Proceed To Checkout",
          // store_id: store_id,
          store_name: this.state.selected_store.name,
          brand_url: brand_url,
          brand_name: data.name,
        });
        // console.log("proceed to checkout document referer: ", this.state.referrer)
        // console.log("proceed to checkout state: ", this.state)

        let cartApiUrl = "";

        // if (data.cart.isInStoreOrder) {
        //     cartApiUrl = "/branded-website/carts/in-store-order"
        // } else {
        cartApiUrl = "/branded-website/carts/";
        // }

        // instance.post("/branded-website/carts/", { data: { data: window.btoa(JSON.stringify({ store_id, "user": null })) }, cart, referrer }, { "headers": { "Authorization": `Basic ${window.btoa(brand_url)}` }, baseURL })
        instance
          .post(
            cartApiUrl,
            {
              data: {
                data: window.btoa(JSON.stringify({ store_id, user: null })),
              },
              cart,
              referrer,
            },
            {
              headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
              baseURL,
            },
          )
          .then(({ data: { success = false, token = "", items = [] } }) => {
            if (success) {
              browserStorage().removeItem("pickup_utm_campaign");

              ReactGA.event("successProceedToCheckout", {
                category: "proceedToCheckout",
                action: "successProceedToCheckout",
                label: "Success Proceed To Checkout",
                // store_id: store_id,
                store_name: this.state.selected_store.name,
                brand_url: brand_url,
                brand_name: data.name,
              });

              // removeStorage(storage_key);
              this.setState(
                { showCartModal: false, showSplashScreen: true },
                () => {
                  this.setState({ redirectTo: "/checkout?token=" + token });
                },
              );
            } else {
              ReactGA.event("failProceedToCheckout", {
                category: "proceedToCheckout",
                action: "failProceedToCheckout",
                label: "Fail Proceed To Checkout",
                // store_id: store_id,
                store_name: this.state.selected_store.name,
                brand_url: brand_url,
                brand_name: data.name,
              });

              cart.items = items;
              this.setState({
                cart,
                showCheckoutFailedMessage: true,
                checkoutLoading: false,
              });
            }
          })
          .catch(() => {
            ReactGA.event("errorProceedToCheckout", {
              category: "proceedToCheckout",
              action: "errorProceedToCheckout",
              label: "Error Proceed To Checkout",
              // store_id: store_id,
              store_name: this.state.selected_store.name,
              brand_url: brand_url,
              brand_name: data.name,
            });
            this.handleOnError();
          });
      } else {
        ReactGA.event("failProceedToCheckout", {
          category: "proceedToCheckout",
          action: "failProceedToCheckout",
          label: "Fail Proceed To Checkout",
          // store_id: store_id,
          store_name: this.state.selected_store.name,
          brand_url: brand_url,
          brand_name: data.name,
        });
        this.setState({ mountOrderDetailsForm: false }, () => {
          // unmount to refresh the list of order time
          this.setState({
            mountOrderDetailsForm: true,
            showCartModalOrderTimeError: true,
            checkoutLoading: false,
          });
        });
      }
    } else {
      this.setState({ showItemsUnavailableForTimeSlotError, cart });
    }
  };

  renderContent = () => {
    return <></>;
  };

  toggleUnavailablePage = (value) => {
    this.setState({ isPageUnavailable: value });
  };

  render() {
    const {
        data = {},
        data_status = "",
        layout_props = {},
        cart = {},
        showSelectionModal = false,
        showPickupDetailsModal = false,
        showDeliveryDetailsModal = false,
        checkoutLoading = false,
        showCartModal = false,
        selected_store = {},
        renderIframe = "",
        preview_token = "",
        mountOrderDetailsForm = true,
        mountOrderDetailsFormNew = true,
        showCartModalOrderTimeError = false,
        showCheckoutFailedMessage = false,
        showItemsUnavailableForTimeSlotError = false,
        brand_url = "",
        showLimitsModal = false,
        limitsModalText = "",
        mountLimitsModal = false,
        website_theme = {},
        order_subtype = "",
        selected_item = {},
      } = this.state,
      isLoading = data_status !== "fetched";

    const actions = {
      // JD: Commented because it's not being used
      // handleOnClickAddToCart: this.handleOnClickAddToCart,
      handleOnClickCtr: this.handleOnClickCtr,
      handleOnClickRemoveItem: this.handleOnClickRemoveItem,
      handleOnClickCheckout: this.handleOnClickCheckout,
      handleOnClickChangeOrderType: () => {
        if (cart.order_type === "delivery") {
          this.setState({
            showCartModal: false,
            showDeliveryDetailsModal: true,
            showCartModalOrderTimeError: false,
          });
        } else {
          this.setState({
            showCartModal: false,
            showPickupDetailsModal: true,
            showCartModalOrderTimeError: false,
          });
        }
      },
      handleOnClickChangeMealPlanType: () => {
        this.setState({
          showCartModal: false,
          showDeliveryDetailsModal: true,
          showCartModalOrderTimeError: false,
          order_subtype: "meal_plan",
        });
      },
    };

    const { store_url = "" } = this.props;
    this.setReferrer();
    return (
      <>
        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {/* <MetaHelmet subdomain={this.state.brand_url} /> */}

        {/* { this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>} */}
        {this.state.showSplashScreen && isLoading ? (
          <div className="d-flex h-100 flex-column align-items-center justify-content-center">
            <PacmanLoading />
          </div>
        ) : (
          <></>
        )}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} exact={true} />
        ) : (
          <></>
        )}

        {renderIframe !== "" ? (
          <Modal
            show={true}
            onHide={() => {
              /** do nothing */
            }}
            size="lg"
          >
            <Modal.Body style={{ height: "100vh", padding: "20px 5px" }}>
              <Iframe
                url={renderIframe}
                width="100%"
                height="100%"
                display="initial"
                position="relative"
                frameBorder="0"
                onLoad={this.handle3dsIframeLoad}
              />
            </Modal.Body>
          </Modal>
        ) : (
          <>
            {isLoading ? (
              <></>
            ) : (
              <Layout
                {...layout_props}
                onClickCart={this.handleOnClickCart}
                cart_counter={cart.items_count}
                preview_token={preview_token}
                website_theme={website_theme}
                store_url={store_url}
                isPageUnavailable={this.state.isPageUnavailable}
              >
                {this.renderContent && <this.renderContent />}

                {this.state.renderModals ? (
                  <>
                    <SelectionModal
                      show={showSelectionModal}
                      onHide={() => {
                        this.setState({ showSelectionModal: false });
                      }}
                      title={<>Who will Pickup the order</>}
                      options={[
                        {
                          title: "Self Pickup",
                          icon: "Customs/take-away",
                          onClick: () => {
                            cart.order_type = "pickup";
                            this.setState({
                              showPickupDetailsModal: true,
                              showSelectionModal: false,
                              cart: cart,
                            });
                          },
                        },
                        {
                          title: "3rd Party Pickup",
                          icon: "Customs/delivery",
                          desc: "",
                          onClick: () => {
                            cart.order_type = "third_party_pickup";
                            this.setState({
                              show3rdPickupDetailsModal: true,
                              showSelectionModal: false,
                              cart: cart,
                            });
                          },
                        },
                      ]}
                    />

                    {mountOrderDetailsForm ? (
                      <>
                        <OrderDetailsForm
                          order_type="pickup"
                          title="Store and Pickup Time Details"
                          brand={data}
                          show={showPickupDetailsModal}
                          onHide={() => {
                            this.setState({ showPickupDetailsModal: false });
                          }}
                          onSubmit={this.handleOnSubmitOrderDetailsForm}
                          brand_url={brand_url}
                          website_theme={website_theme}
                          order_subtype={this.state.order_subtype}
                        />

                        <OrderDetailsForm
                          order_type="delivery"
                          // title="Store and Delivery Time Details"
                          title={
                            this.state.order_subtype === "meal_plan"
                              ? "Store and Meal Plan Details"
                              : "Store and Delivery Time Details"
                          }
                          brand={data}
                          show={showDeliveryDetailsModal}
                          onHide={() => {
                            this.setState({ showDeliveryDetailsModal: false });
                          }}
                          onSubmit={this.handleOnSubmitOrderDetailsForm}
                          brand_url={brand_url}
                          website_theme={website_theme}
                          order_subtype={this.state.order_subtype}
                        />
                      </>
                    ) : (
                      <></>
                    )}

                    {mountOrderDetailsFormNew ? (
                      <OrderDetailsModalNew
                        order_type="pickup"
                        title="Order Details"
                        brand={data}
                        show={
                          showPickupDetailsModal || showDeliveryDetailsModal
                        }
                        onHide={() => {
                          if (!showPickupDetailsModal) {
                            this.setState({ showDeliveryDetailsModal: false });
                          } else if (!showDeliveryDetailsModal) {
                            this.setState({
                              showPickupDetailsModal: false,
                            });
                          }
                        }}
                        onSubmit={this.handleOnSubmitOrderDetailsForm}
                        brand_url={brand_url}
                        website_theme={website_theme}
                        order_subtype={this.state.order_subtype}
                      />
                    ) : (
                      <></>
                    )}

                    <CartModal
                      show={showCartModal}
                      onHide={() => {
                        this.setState({ showCartModal: false });
                      }}
                      cart={cart}
                      actions={actions}
                      store={selected_store}
                      checkoutLoading={checkoutLoading}
                      preview_token={preview_token}
                      showCartModalOrderTimeError={showCartModalOrderTimeError}
                      handleOnClickEditTimeFromError={
                        this.handleOnClickEditTimeFromError
                      }
                      showCheckoutFailedMessage={showCheckoutFailedMessage}
                      showItemsUnavailableForTimeSlotError={
                        showItemsUnavailableForTimeSlotError
                      }
                      website_theme={website_theme}
                    />

                    {mountLimitsModal ? (
                      <LimitsModal
                        show={showLimitsModal}
                        onHide={() => {
                          this.setState({ showLimitsModal: false });
                        }}
                        text={limitsModalText}
                        brand_url={brand_url}
                        selected_store={selected_store}
                        cart={cart}
                        selected_item={selected_item}
                        onSubmit={this.handleOnSubmitChangeDateForm}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </Layout>
            )}
          </>
        )}
      </>
    );
  }
}
