/** Collection of Store Setup Partials */

import React, { Component } from "react";
import moment from "moment";
import { Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { DB_HOURS_FORMAT } from "../constants";

function getTimeOptions(store_hours = [], index = 0, daily = {}) {
  let current = moment(new Date()).startOf("day"),
    end = moment(new Date()).endOf("day"),
    options = [];

  let end_of_list = end;
  const filter_hours = store_hours.filter(({ day }) => day === daily.day);
  if (filter_hours && filter_hours.length > 1) {
    if (filter_hours[index + 1]) {
      const endTime = moment(filter_hours[index + 1].opening, "HH:mm");

      end_of_list =
        filter_hours.length > 1 && index + 1 !== filter_hours.length
          ? moment(new Date(endTime.toDate())).subtract("30", "minutes")
          : end;
    }
  }
  while (current.isSameOrBefore(end_of_list)) {
    options.push({
      text: current.format("hh:mm A"),
      value: current.format("HH:mm"),
    });
    current = current.add("30", "minutes");
  }

  return options;
}

function RenderTimeOptions({
  time_options = [],
  start = "",
  end = "",
  isClosing = false,
  isStoreOpen24Hrs = false,
}) {
  let add = start === "";

  const half_start = moment(start, DB_HOURS_FORMAT)
    .add("30", "minutes")
    .format(DB_HOURS_FORMAT);

  return time_options.map(({ text = "", value = "" }, index) => {
    if (add && end !== "") add = value !== end;

    if (!add && start !== "") add = value === start;

    if (
      !isStoreOpen24Hrs &&
      isClosing &&
      (value === start || value === half_start)
    ) {
      return <></>;
    }

    return add ? (
      <option value={value} key={index}>
        {text}
      </option>
    ) : (
      <></>
    );
  });
}

function OnClickAddStoreHrs(handleOnAddStoreHrs, dayInt) {
  handleOnAddStoreHrs(dayInt);
}

function OnClickDeleteStoreHrs(handleOnDeleteStoreHrs, idString, day) {
  handleOnDeleteStoreHrs(idString, day);
}

class DailyCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      new_store_hours: [],
    };
  }

  render() {
    const {
      dayInt,
      store_hours,
      handleOnDeleteStoreHrs,
      handleOnAddStoreHrs,
      isStoreOpen24Hrs,
    } = this.props;
    const currentDayItems = store_hours.filter((item) => item.day === dayInt);

    return (
      <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center mb-5">
          <span className="font-weight-bolder d-flex flex-column align-items-center">
            {moment(currentDayItems[0].day, "d").format("dddd")}
          </span>
          {!isStoreOpen24Hrs && (
            <span
              onClick={() =>
                OnClickAddStoreHrs(handleOnAddStoreHrs, currentDayItems[0].day)
              }
              className="d-flex flex-column align-items-center"
            >
              <i
                style={{
                  color: "#14C49B",
                  transform: "scale(1.4)",
                  cursor: "pointer",
                }}
                className="fas fa-plus-circle ml-5"
              ></i>
            </span>
          )}
        </div>

        <div className="d-flex flex-column">
          {currentDayItems.map((daily, index) => (
            <TimesOpenCard
              isStoreOpen24Hrs={isStoreOpen24Hrs}
              key={index}
              prev={currentDayItems[index - 1]}
              idString={daily._id}
              daily={daily}
              index={index}
              store_hours={this.props.store_hours}
              actions={this.props.actions}
              handleOnDeleteStoreHrs={handleOnDeleteStoreHrs}
            />
          ))}
        </div>
      </div>
    );
  }
}

class TimesOpenCard extends Component {
  render() {
    const {
        daily,
        index,
        actions,
        idString,
        handleOnDeleteStoreHrs,
        prev,
        store_hours,
        isStoreOpen24Hrs,
      } = this.props,
      { isOpen = false } = daily;
    const time_options = getTimeOptions(store_hours, index, daily);

    return (
      <div>
        <div className="d-flex flex-row align-items-center pl-sm-10 mb-5">
          <div
            className="mr-10 mr-xs"
            style={
              isStoreOpen24Hrs ? { pointerEvents: "none", opacity: "0.4" } : {}
            }
          >
            <div className="form-group mb-0 text-muted">
              From
              <select
                className="form-control"
                disabled={isOpen && !isStoreOpen24Hrs ? "" : "disabled"}
                value={daily.opening}
                name={`opening_${index}`}
                data-name={"opening"}
                data-index={idString}
                {...actions}
              >
                <RenderTimeOptions
                  time_options={time_options}
                  end={daily.closing}
                  start={
                    prev
                      ? moment(prev.closing, DB_HOURS_FORMAT)
                          .add("30", "minutes")
                          .format(DB_HOURS_FORMAT)
                      : ""
                  }
                />
              </select>
            </div>
          </div>
          <div
            className="mr-10 mr-xs"
            style={
              isStoreOpen24Hrs ? { pointerEvents: "none", opacity: "0.4" } : {}
            }
          >
            <div className="form-group mb-0 text-muted">
              To
              <select
                className="form-control"
                disabled={isOpen && !isStoreOpen24Hrs ? "" : "disabled"}
                value={daily.closing}
                name={`closing_${index}`}
                data-name={"closing"}
                data-index={idString}
                {...actions}
              >
                <RenderTimeOptions
                  time_options={time_options}
                  start={daily.opening}
                  isClosing={true}
                  isStoreOpen24Hrs={isStoreOpen24Hrs}
                />
              </select>
            </div>
          </div>
          <div className="text-center mr-sm-10 mr-5">
            <div className="form-group mb-0 d-flex flex-column align-items-center justify-content-center text-muted">
              <label className="checkbox checkbox-primary align-self-center">
                <input
                  className="align-self-center"
                  type="checkbox"
                  name={`isOpen_${index}`}
                  checked={isOpen ? "checked" : null}
                  data-name={"isOpen"}
                  data-index={idString}
                  {...actions}
                />{" "}
                <span></span>
              </label>
            </div>
          </div>
          {!isStoreOpen24Hrs && (
            <div className="text-center mr-sm-10">
              <div className="form-group mb-0 d-flex flex-row align-items-center justify-content-center">
                <span
                  className="d-flex flex-row align-items-center"
                  style={{ fontSize: "20px" }}
                  onClick={() =>
                    OnClickDeleteStoreHrs(
                      handleOnDeleteStoreHrs,
                      idString,
                      daily.day,
                    )
                  }
                >
                  <i
                    style={{ color: "#14C49B", cursor: "pointer" }}
                    className="mt-3 fas fa-trash"
                  ></i>
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export class EditStoreHoursTable extends Component {
  constructor(props) {
    super(props);

    const { store_hours = [] } = this.props;
    this.state = {
      store_hours: store_hours.map((daily) => {
        daily.editMode = true;
        return daily;
      }),
    };
  }

  render() {
    const uniqueDaysOpen = [
      ...new Set(this.props.store_hours.map((item) => item.day)),
    ];
    const { handleOnDeleteStoreHrs, handleOnAddStoreHrs, isStoreOpen24Hrs } =
      this.props;

    return (
      <div className="d-flex flex-column align-items-center">
        {uniqueDaysOpen.map((dayInt, index) => (
          <DailyCard
            isStoreOpen24Hrs={isStoreOpen24Hrs}
            key={index}
            index={index}
            dayInt={dayInt}
            store_hours={this.props.store_hours}
            actions={this.props.actions}
            handleOnDeleteStoreHrs={handleOnDeleteStoreHrs}
            handleOnAddStoreHrs={handleOnAddStoreHrs}
          />
        ))}
      </div>
    );
  }
}

export class EditOffDatesTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddForm: false,
      startDate: new Date(),
    };

    this.handleOnChangeAddDate = this.handleOnChangeAddDate.bind(this);

    this.setToTrue = this.setToTrue.bind(this);
    this.setToFalse = this.setToFalse.bind(this);
  }

  setToTrue = (key) => {
    let state = {};
    state[key] = true;
    this.setState(state);
  };

  setToFalse = (key) => {
    let state = {};
    state[key] = false;
    this.setState(state);
  };

  handleOnChangeAddDate = (date) => {
    this.setState({ startDate: date });
  };

  render() {
    return (
      <>
        {this.state.showAddForm ? (
          <>
            <div>
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleOnChangeAddDate}
                minDate={new Date()}
                className="form-control"
                dateFormat={"MMM d, yyyy"}
                excludeDates={this.props.off_dates.map(
                  (off_day) => new Date(off_day),
                )}
              />
            </div>
            <div className="mt-5">
              <button
                type="button"
                className="btn btn-sm btn-primary mr-5"
                onClick={() => {
                  this.props.actions.onSelectAddDate(
                    this.state.startDate,
                    (showAddForm) => {
                      this.setState({ showAddForm });
                    },
                  );
                }}
              >
                Add Off Date
              </button>
              <button
                type="button"
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  this.setToFalse("showAddForm");
                }}
              >
                Cancel
              </button>
            </div>
          </>
        ) : (
          <>
            {this.props.off_dates.length === 0 ? (
              <div>
                <p>
                  You don't have any off dates set.{" "}
                  <button
                    type="button"
                    className="btn btn-link px-0"
                    onClick={() => {
                      this.setToTrue("showAddForm");
                    }}
                  >
                    Add one?
                  </button>
                </p>
              </div>
            ) : (
              <Table responsive>
                <tbody>
                  {this.props.off_dates.map((off_date, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          className="cursor-pointer text-hover-danger"
                          onClick={() => {
                            this.props.actions.onSelectDeleteDate(off_date);
                          }}
                        >
                          {" "}
                          <i className="flaticon-cancel"></i>
                        </span>
                        <span className="ml-5">
                          {" "}
                          {moment(off_date, "YYYY-MM-DD").format(
                            "MMM D, YYYY",
                          )}{" "}
                        </span>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <button
                        type="button"
                        className="btn btn-link px-0"
                        onClick={() => {
                          this.setToTrue("showAddForm");
                        }}
                      >
                        Add another?
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </>
        )}
      </>
    );
  }
}
