/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { SVGIcon } from "../../../../../app/components/Base";
// import SVG from "react-inlinesvg";
import { checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";
import { getFirstItem } from "../../../../../app/helpers";
import { Rotate90DegreesCcw } from "@material-ui/icons";

// const HIDE_SAMPLE = true;

// export const ENABLE_EXCLUSIVE_CART_KEY = "ENABLE_EXCLUSIVE_CART_KEY"
export const ENABLE_EXCLUSIVE_CART = true;
function getAdminMenuItemActive(activeMenu, selectedMenu) {
  return activeMenu === selectedMenu ? " menu-item-active menu-item-open " : "";
}

export function AsideMenuList(props) {
  const {
    layoutProps,
    type = "admin",
    preview_token = "",
    finishedSetup = false,
    privileges = [],
    activeMenu = "",
    store_url = "",
  } = props; // onClickCart, cart_counter
  const location = useLocation();
  const { user = {} } = useSelector((state) => state.auth),
    { iam_role = "" } = user,
    isSuperAdmin = iam_role === "super_admin",
    isSystemAdmin = iam_role === "system_admin",
    brand_url = getFirstItem(user.brand_urls);

  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [openExperiences, setOpenExperiences] = useState(false);

  const handleAnalyticsClick = () => {
    setOpenAnalytics(!openAnalytics);
  };

  const handleExperiencesClick = () => {
    setOpenExperiences(!openExperiences);
  };

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  let brandParentUrl = location.pathname.split("/").slice(0, 3).join("/");
  brandParentUrl =
    brand_url !== ""
      ? brandParentUrl.indexOf("/brands/") === -1
        ? `/brands/${brand_url}.pickup.ph`
        : brandParentUrl
      : "";

  const store_prefix = store_url !== "" ? `/${store_url}` : "";

  let homeUrl =
    preview_token !== ""
      ? `${store_prefix}/?preview_token=${preview_token}`
      : `${store_prefix}/`;
  const fromUrl = localStorage.getItem("from");
  if (fromUrl) {
    homeUrl = fromUrl;
  }

  const IS_EXCLUSIVE_CART_ENABLED = ENABLE_EXCLUSIVE_CART;

  return (
    <>
      {type === "branded-website" ? (
        <>
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}

            <li className={`menu-item ${getMenuItemActive("")}`}>
              <NavLink className="menu-link" to={homeUrl}>
                <span className="menu-text">Home</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/about")}`}>
              <NavLink
                className="menu-link"
                to={
                  preview_token !== ""
                    ? `${store_prefix}/about?preview_token=${preview_token}`
                    : `${store_prefix}/about`
                }
              >
                <span className="menu-text">About</span>
              </NavLink>
            </li>

            <li className={`menu-item ${getMenuItemActive("/menu")}`}>
              <NavLink
                className="menu-link"
                to={
                  preview_token !== ""
                    ? `${store_prefix}/menu?preview_token=${preview_token}`
                    : `${store_prefix}/menu`
                }
              >
                <span className="menu-text">Menu</span>
              </NavLink>
            </li>
          </ul>
        </>
      ) : (
        <> </>
      )}

      {type === "admin" ? (
        isSuperAdmin ? (
          <>
            <SuperAdminList
              activeMenu={activeMenu}
              layoutProps={layoutProps}
              handleAnalyticsClick={handleAnalyticsClick}
              openAnalytics={openAnalytics}
            />
          </>
        ) : isSystemAdmin ? (
          <>
            <SystemAdminList
              activeMenu={activeMenu}
              layoutProps={layoutProps}
              handleAnalyticsClick={handleAnalyticsClick}
              openAnalytics={openAnalytics}
            />
          </>
        ) : (
          <>
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
              {location.pathname.indexOf("getting-started") !== -1 ? (
                <li
                  className={`menu-item ${getAdminMenuItemActive(
                    activeMenu,
                    "my-account",
                  )}`}
                >
                  <NavLink className="menu-link" to={`/`}>
                    <span className="svg-icon menu-icon">
                      <SVGIcon icon="General/Star" title="Getting Started" />
                    </span>
                    <span className="menu-text">Getting Started</span>
                  </NavLink>
                </li>
              ) : (
                <>
                  {finishedSetup ||
                  brandParentUrl.indexOf("/brands/") !== -1 ? (
                    <>
                      {privileges.includes("home") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "home",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon icon="Home/Home" title="Home" />
                              </span>
                              <span className="menu-text">Home</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("stores") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "stores",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/stores`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon icon="Shopping/Cart1" title="Stores" />
                              </span>
                              <span className="menu-text">Stores</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("menu") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "menu",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/menus`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Layout/Layout-4-blocks"
                                  title="Menu"
                                />
                              </span>
                              <span className="menu-text">Menu</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("promos") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "promos",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/promos`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon icon="Shopping/Price1" title="Menu" />
                              </span>
                              <span className="menu-text">Promo Codes</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("orders") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "orders",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/orders`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Design/Layers"
                                  title="My Store"
                                />
                              </span>
                              <span className="menu-text">Orders</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("concierge-carts") &&
                        IS_EXCLUSIVE_CART_ENABLED && (
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "concierge-carts",
                            )}`}
                          >
                            <NavLink
                              className="menu-link"
                              to={`/concierge-carts`}
                            >
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Shopping/Ticket"
                                  title="Concierge Carts"
                                />
                              </span>
                              <span className="menu-text">Concierge Carts</span>
                            </NavLink>
                          </li>
                        )}

                      {process.env.REACT_APP_EXPERIENCES_ENABLED === "true" &&
                      (privileges.includes("reservations") ||
                        privileges.includes("events")) ? (
                        <>
                          <li
                            className={`menu-item menu-item-parent ${getAdminMenuItemActive(
                              activeMenu,
                              "experiences",
                            )}`}
                          >
                            <div
                              className="menu-link menu-toggle"
                              onClick={handleExperiencesClick}
                            >
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Shopping/Chart-line1"
                                  title="Experiences"
                                />
                              </span>
                              <span className="menu-text">Experiences</span>
                              <span className="menu-text d-flex flex-row justify-content-end">
                                {openExperiences ? (
                                  <i
                                    style={{
                                      transform: openExperiences
                                        ? "rotate(90deg)"
                                        : "",
                                      transition: "transform 150ms ease",
                                    }}
                                    className="fas fa-angle-right"
                                  ></i>
                                ) : (
                                  <i
                                    style={{
                                      transform: openExperiences
                                        ? "rotate(-90deg)"
                                        : "",
                                      transition: "transform 150ms ease",
                                    }}
                                    className="fas fa-angle-right"
                                  ></i>
                                )}
                              </span>
                            </div>
                          </li>
                          {openExperiences ? (
                            <>
                              {privileges.includes("reservations") ? (
                                <>
                                  <li
                                    className={`menu-item ${getAdminMenuItemActive(
                                      activeMenu,
                                      "reservations",
                                    )}`}
                                  >
                                    <NavLink
                                      className="ml-5 menu-link"
                                      to={`/reservations`}
                                    >
                                      <span className="svg-icon menu-icon">
                                        <SVGIcon
                                          icon="Communication/Mail-locked"
                                          title="Menu"
                                        />
                                      </span>
                                      <span className="menu-text">
                                        Reservations
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {privileges.includes("events") ? (
                                <>
                                  <li
                                    className={`menu-item ${getAdminMenuItemActive(
                                      activeMenu,
                                      "events",
                                    )}`}
                                  >
                                    <NavLink
                                      className="ml-5 menu-link"
                                      to={`/events`}
                                    >
                                      <span className="svg-icon menu-icon">
                                        <SVGIcon
                                          icon="Shopping/Chart-bar1"
                                          title="Sales Analytics"
                                        />
                                      </span>
                                      <span className="menu-text">Events</span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("place-orders") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "place-orders",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/placeorders`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Shopping/Bag1"
                                  title="Place Orders"
                                />
                              </span>
                              <span className="menu-text">Place Orders</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("analytics") ||
                      privileges.includes("google-analytics") ? (
                        <>
                          {/* <li className={`menu-item ${getAdminMenuItemActive(activeMenu, "analytics")}`} > */}

                          {/* <NavLink className="menu-link" to={`${brandParentUrl}/analytics`}> */}

                          {/* <NavLink className="menu-link" to={`/analytics`}>
                                                    <span className="svg-icon menu-icon">
                                                        <SVGIcon icon="Shopping/Chart-line1" title="Analytics"/>
                                                    </span>
                                                    <span className="menu-text">Analytics</span>
                                                </NavLink>
                                            </li> */}

                          <li
                            className={`menu-item menu-item-parent ${getAdminMenuItemActive(
                              activeMenu,
                              "analyticsgroup",
                            )}`}
                          >
                            <div
                              className="menu-link menu-toggle"
                              onClick={handleAnalyticsClick}
                            >
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Shopping/Chart-line1"
                                  title="Analytics"
                                />
                              </span>
                              <span className="menu-text">Analytics</span>
                              <span className="menu-text d-flex flex-row justify-content-end">
                                {openAnalytics ? (
                                  <i
                                    style={{
                                      transform: openAnalytics
                                        ? "rotate(90deg)"
                                        : "",
                                      transition: "transform 150ms ease",
                                    }}
                                    className="fas fa-angle-right"
                                  ></i>
                                ) : (
                                  <i
                                    style={{
                                      transform: openAnalytics
                                        ? "rotate(-90deg)"
                                        : "",
                                      transition: "transform 150ms ease",
                                    }}
                                    className="fas fa-angle-right"
                                  ></i>
                                )}
                              </span>
                            </div>
                          </li>
                          {openAnalytics ? (
                            <>
                              {privileges.includes("analytics") ? (
                                <>
                                  <li
                                    className={`menu-item ${getAdminMenuItemActive(
                                      activeMenu,
                                      "analytics",
                                    )}`}
                                  >
                                    <NavLink
                                      className="ml-5 menu-link"
                                      to={`/analytics`}
                                    >
                                      {/* <span className="menu-text">
                                                                        <i class="fas fa-minus"></i>
                                                                    </span> */}
                                      <span className="svg-icon menu-icon">
                                        <SVGIcon
                                          icon="Shopping/Chart-bar1"
                                          title="Sales Analytics"
                                        />
                                      </span>
                                      <span className="menu-text">
                                        Sales Analytics
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                              {privileges.includes("google-analytics") ? (
                                <>
                                  <li
                                    className={`menu-item ${getAdminMenuItemActive(
                                      activeMenu,
                                      "googleanalytics",
                                    )}`}
                                  >
                                    <NavLink
                                      className="ml-5 menu-link"
                                      to={`/googleanalytics`}
                                    >
                                      {/* <span className="menu-text">
                                                                        <i class="fas fa-minus"></i>
                                                                    </span> */}
                                      <span className="svg-icon menu-icon">
                                        <SVGIcon
                                          icon="Customs/google"
                                          title="Google Analytics"
                                        />
                                      </span>
                                      <span className="menu-text">
                                        Google Analytics
                                      </span>
                                    </NavLink>
                                  </li>
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("settings") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "settings",
                            )}`}
                          >
                            <NavLink className="menu-link" to={`/settings`}>
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Code/Settings4"
                                  title="Settings"
                                />
                              </span>
                              <span className="menu-text">Settings</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("my-account") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "my-account",
                            )}`}
                          >
                            <NavLink className="menu-link" to="/my-account">
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="General/Star"
                                  title="My Account"
                                />
                              </span>
                              <span className="menu-text">My Account</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("feedback") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "feedback",
                            )}`}
                          >
                            <NavLink className="menu-link" to="/feedback">
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Communication/Active-call"
                                  title="Feedback"
                                />
                              </span>
                              <span className="menu-text">Feedback</span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("announcements") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "announcements",
                            )}`}
                          >
                            <NavLink className="menu-link" to="/announcements">
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Media/Volume-full"
                                  title="Announcements"
                                />
                              </span>
                              <span className="menu-text">Announcements </span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}

                      {privileges.includes("help") ? (
                        <>
                          <li
                            className={`menu-item ${getAdminMenuItemActive(
                              activeMenu,
                              "help",
                            )}`}
                          >
                            <NavLink className="menu-link" to="/help">
                              <span className="svg-icon menu-icon">
                                <SVGIcon
                                  icon="Code/Question-circle"
                                  title="Help"
                                />
                              </span>
                              <span className="menu-text">Help </span>
                            </NavLink>
                          </li>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ul>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );

  //   return (
  //       <>
  //         {/* begin::Menu Nav */}
  //         <ul className={`menu-nav ${layoutProps.ulClasses}`}>
  //           {/*begin::1 Level*/}
  //           <li className={`menu-item ${getMenuItemActive("/getting-started")}`} >
  //             <NavLink className="menu-link" to="/getting-started">
  //             <span className="svg-icon menu-icon">
  //               <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
  //             </span>
  //               <span className="menu-text">Stores</span>
  //             </NavLink>
  //           </li>

  //           <li className={`menu-item ${getMenuItemActive("/getting-started")}`} >
  //             <NavLink className="menu-link" to="/getting-started">
  //             <span className="svg-icon menu-icon">
  //               <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
  //             </span>
  //               <span className="menu-text">Items</span>
  //             </NavLink>
  //           </li>
  //           {/*end::1 Level*/}

  //           {
  //               HIDE_SAMPLE ? <></> : <>
  //                 {/* Components */}
  //                 {/* begin::section */}
  //                 <li className="menu-section ">
  //                     <h4 className="menu-text">Sample Menu</h4>
  //                     <i className="menu-icon flaticon-more-v2"></i>
  //                 </li>
  //                 {/* end:: section */}

  //                 {/* Material-UI */}
  //                 {/*begin::1 Level*/}
  //                 <li
  //                     className={`menu-item menu-item-submenu ${getMenuItemActive(
  //                         "/google-material"
  //                     )}`}
  //
  //                     data-menu-toggle="hover"
  //                 >
  //                     <NavLink className="menu-link menu-toggle" to="/google-material">
  //                     <span className="svg-icon menu-icon">
  //                     <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Cap-2.svg")}/>
  //                     </span>
  //                     <span className="menu-text">Nested Menu</span>
  //                     <i className="menu-arrow"/>
  //                     </NavLink>
  //                     <div className="menu-submenu ">
  //                     <i className="menu-arrow"/>
  //                     <ul className="menu-subnav">
  //                         <li className="menu-item  menu-item-parent" >
  //                         <span className="menu-link">
  //                         <span className="menu-text">Nested Menu</span>
  //                         </span>
  //                         </li>

  //                         {/* Inputs */}
  //                         {/*begin::2 Level*/}
  //                         <li
  //                             className={`menu-item menu-item-submenu ${getMenuItemActive(
  //                                 "/my-page"
  //                             )}`}
  //
  //                             data-menu-toggle="hover"
  //                         >
  //                         <NavLink className="menu-link menu-toggle" to="/my-page">
  //                             <i className="menu-bullet menu-bullet-dot">
  //                             <span/>
  //                             </i>
  //                             <span className="menu-text">My Page</span>
  //                             <i className="menu-arrow"/>
  //                         </NavLink>
  //                         <div className="menu-submenu ">
  //                             <i className="menu-arrow"/>
  //                             <ul className="menu-subnav">
  //                             {/*begin::3 Level*/}
  //                             <li
  //                                 className={`menu-item  ${getMenuItemActive(
  //                                     "/my-page"
  //                                 )}`}
  //
  //                             >
  //                                 <NavLink className="menu-link"
  //                                     to="/my-page">
  //                                 <i className="menu-bullet menu-bullet-dot">
  //                                     <span/>
  //                                 </i>
  //                                 <span className="menu-text">My Page</span>
  //                                 </NavLink>
  //                             </li>
  //                             {/*end::3 Level*/}
  //                             </ul>
  //                         </div>
  //                         </li>
  //                     </ul>
  //                     </div>
  //                     </li>
  //                 {/*end::1 Level*/}
  //             </>
  //           }
  //         </ul>
  //         {/* end::Menu Nav */}
  //       </>
  //   );
}

const SuperAdminList = (props) => {
  const { layoutProps, activeMenu, handleAnalyticsClick, openAnalytics } =
    props;
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getAdminMenuItemActive(activeMenu, "home")}`}
        >
          <NavLink className="menu-link" to={`/`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Home/Home" title="Home" />
            </span>
            <span className="menu-text">Home</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "groups",
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/groups`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Communication/Group" title="Home" />
            </span>
            <span className="menu-text">Groups</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "brands",
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/brands`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Shopping/Box2" title="Brands" />
            </span>
            <span className="menu-text">Brands</span>
          </NavLink>
        </li>
        {/* <li className={`menu-item ${getAdminMenuItemActive(activeMenu, "analytics")}`} >
                            <NavLink className="menu-link" to={`/admin/analytics`}>
                                <span className="svg-icon menu-icon">
                                    <SVGIcon icon="Shopping/Chart-line1" title="Analytics"/>
                                </span>
                                <span className="menu-text">Analytics</span>
                            </NavLink>
                        </li> */}
        {/* <li className={`menu-item ${getAdminMenuItemActive(activeMenu, "analytics")}`} >
                            <NavLink className="menu-link" to={`/admin/analytics`}>
                                <span className="svg-icon menu-icon">
                                    <SVGIcon icon="Shopping/Chart-line1" title="Analytics"/>
                                </span>
                                <span className="menu-text">Store Analytics</span>
                            </NavLink>
                        </li> */}
        {/* <li className={`menu-item ${getAdminMenuItemActive(activeMenu, "googleanalytics")}`} >
                            <NavLink className="menu-link" to={`/admin/googleanalytics`}>
                                <span className="svg-icon menu-icon">
                                    <SVGIcon icon="Customs/google" title="Google Analytics"/>
                                </span>
                                <span className="menu-text">Google Analytics</span>
                            </NavLink>
                        </li> */}

        <li
          className={`menu-item menu-item-parent ${getAdminMenuItemActive(
            activeMenu,
            "analyticsgroup",
          )}`}
        >
          <div className="menu-link menu-toggle" onClick={handleAnalyticsClick}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Shopping/Chart-line1" title="Analytics" />
            </span>
            <span className="menu-text">Analytics</span>
            <span className="menu-text d-flex flex-row justify-content-end">
              {openAnalytics ? (
                <i
                  style={{
                    transform: openAnalytics ? "rotate(90deg)" : "",
                    transition: "transform 150ms ease",
                  }}
                  className="fas fa-angle-right"
                ></i>
              ) : (
                <i
                  style={{
                    transform: openAnalytics ? "rotate(-90deg)" : "",
                    transition: "transform 150ms ease",
                  }}
                  className="fas fa-angle-right"
                ></i>
              )}
            </span>
          </div>
        </li>
        {openAnalytics ? (
          <>
            {
              <li
                className={`menu-item ${getAdminMenuItemActive(
                  activeMenu,
                  "analytics",
                )}`}
              >
                <NavLink className="ml-5 menu-link" to={`/admin/analytics`}>
                  <span className="svg-icon menu-icon">
                    <SVGIcon icon="Shopping/Chart-line1" title="Analytics" />
                  </span>
                  <span className="menu-text">Store Analytics</span>
                </NavLink>
              </li>
            }
            {
              <li
                className={`menu-item ${getAdminMenuItemActive(
                  activeMenu,
                  "googleanalytics",
                )}`}
              >
                <NavLink
                  className="ml-5 menu-link"
                  to={`/admin/googleanalytics`}
                >
                  {/* <span className="menu-text">
                      <i class="fas fa-minus"></i>
                  </span> */}
                  <span className="svg-icon menu-icon">
                    <SVGIcon icon="Customs/google" title="Google Analytics" />
                  </span>
                  <span className="menu-text">Google Analytics</span>
                </NavLink>
              </li>
            }
          </>
        ) : (
          <></>
        )}

        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "onboarding",
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/onboarding`}>
            <span className="svg-icon menu-icon">
              <SVGIcon
                icon="Communication/Clipboard-check"
                title="Onboarding"
              />
            </span>
            <span className="menu-text">Onboarding</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "announcements",
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/announcements`}>
            <span
              className="svg-icon menu-icon"
              style={{ transform: "rotate(-45deg)" }}
            >
              <SVGIcon icon="Media/Volume-full" title="Announcements" />
            </span>
            <span className="menu-text">Announcements</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "admintools",
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/tools`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Tools/Tools" title="Admin Tools" />
            </span>
            <span className="menu-text">Admin Tools</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
};

const SystemAdminList = (props) => {
  const { layoutProps, activeMenu } = props;
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getAdminMenuItemActive(activeMenu, "home")}`}
        >
          <NavLink className="menu-link" to={`/`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Tools/Tools" title="Home" />
            </span>
            <span className="menu-text">Admin Tools</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "groups"
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/groups`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Communication/Group" title="Home" />
            </span>
            <span className="menu-text">Groups</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getAdminMenuItemActive(
            activeMenu,
            "brands"
          )}`}
        >
          <NavLink className="menu-link" to={`/admin/brands`}>
            <span className="svg-icon menu-icon">
              <SVGIcon icon="Shopping/Box2" title="Brands" />
            </span>
            <span className="menu-text">Brands</span>
          </NavLink>
        </li> */}
      </ul>
    </>
  );
};
