import React, { useState } from "react";
import ProSourceForm from "../../components/ProSourceForm";
import { FormItem, InfoTooltip } from "../../components/Base";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import { GetSubmitClassList } from "../../helpers";
import { isNil, isEmpty } from "lodash";
import core from "../../vendors/core-api/";
import withReactContent from "sweetalert2-react-content";
import { PRIMARY_COLOR } from "../../constants";
import Swal from "sweetalert2";
import { formatOrderType } from "../../helpers";
const SERVICE_CHARGE_LIMIT = 20;
const SERVICE_CHARGE_AMOUNT_LIMIT = 1000;
const DEFAULT_FACTOR = 10;
const DEFAULT_TYPE = "amount_charge";
const MySwal = withReactContent(Swal);
export default class ServiceChargeForm extends ProSourceForm {
  constructor(props) {
    super(props);
    this.api = core("pu").get();
    const { order_type, store } = this.props;
    this.charge_name = order_type + "_service_charge_fee";
    this.chargeData = {
      active: false,
      factor: DEFAULT_FACTOR,
      type: DEFAULT_TYPE,
    };

    if (!isNil(store.additional_charges)) {
      const foundCharge = store.additional_charges.find(
        (item) => item.name === this.charge_name,
      );
      if (foundCharge) {
        this.chargeData = foundCharge;
      }
    }

    this.state = {
      errors: {},
      values: {
        active: this.chargeData.active,
        factor: this.chargeData.factor,
        type: this.chargeData.type,
      },
      store: this.props.store,
      touched: {},
      isSubmitting: false,
    };
    this.validate = {};
  }

  onChange = (e) => {};

  handleToggleServiceCharge = (isChecked) => {
    let values = {};
    const {
      store,
      store: { additional_charges = [] },
    } = this.state;
    const { order_type } = this.props;
    isChecked = !isChecked;
    const hasCharges =
      !isNil(additional_charges) && !isEmpty(additional_charges);
    if (hasCharges) {
      const index = additional_charges.findIndex(
        (item) => item.name === this.charge_name,
      );
      if (index !== -1) {
        additional_charges[index].active = isChecked;
      } else {
        additional_charges.push({
          name: this.charge_name,
          active: isChecked,
        });
      }
    } else {
      additional_charges.push({
        name: this.charge_name,
        active: isChecked,
      });
    }
    store.additional_charges = additional_charges;
    const name = `${order_type}_service_charge_fee`;

    values["name"] = name;
    values["active"] = isChecked;
    values["type"] = DEFAULT_TYPE;
    values["factor"] = DEFAULT_FACTOR;
    this.setState({ store, values });
    if (!isChecked) {
      this.api
        .put({
          url: "/store/" + store._id + "/additional_charge",
          data: values,
        })
        .then(({ data }) => {
          if (data.result === "ok") {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "Service charge successfully disabled",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onClickSubmit = () => {
    const { store, values } = this.state;
    const { order_type } = this.props;
    const name = `${order_type}_service_charge_fee`;

    values["name"] = name;
    // console.log(this.api);
    this.api
      .put({
        url: "/store/" + store._id + "/additional_charge",
        data: values,
      })
      .then(({ data }) => {
        if (data.result === "ok") {
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully saved your changes.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            // timer: 1000
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  validServiceCharge(values, errors) {
    const value = values["factor"];
    const valid = this.isValidLimit(parseInt(value), values.type);
    if (!valid) {
      errors.service_charge_fee = "Invalid service charge value";
    } else {
      delete errors.service_charge_fee;
    }
    return { valid, error_result: errors };
  }
  onClickTab = (tab) => {
    let { values, errors } = this.state;
    values["type"] = tab;
    const { valid, error_result } = this.validServiceCharge(values, errors);
    this.setState({
      values,
      errors: error_result,
      isServiceChargeSaveDisabled: !valid,
    });
  };

  isValidLimit(input = 0, charge_type) {
    const charge_limit =
      charge_type === "percent_charge"
        ? SERVICE_CHARGE_LIMIT
        : SERVICE_CHARGE_AMOUNT_LIMIT;
    return input > 0 && input <= charge_limit;
  }
  onChangeServiceCharge = (e) => {
    let { store, errors, values } = this.state;
    e.preventDefault();
    values["factor"] = parseInt(e.target.value);
    const { valid, error_result } = this.validServiceCharge(values, errors);

    this.setState({
      store: { ...store, service_charge_fee: e.target.value },
      values,
      errors: error_result,
      isServiceChargeSaveDisabled: !valid,
    });
  };
  render() {
    const actions = {
        handleFeedbackError: this.handleFeedbackError,
        isTouched: this.isTouched,
      },
      inputActions = {
        onChange: this.handleOnChange,
        onBlur: this.handleOnBlur,
      };
    return (
      <Card className="d-flex justify-content-between w-100 mb-4">
        <Card.Body>
          <div
            style={{ transform: "scale(1.2)" }}
            className="row d-flex align-items-center"
          >
            <span className="switch switch-outline switch-icon switch-primary py-1 m-0 ml-10 pl-10">
              <label>
                <input
                  type="checkbox"
                  name="isServiceChargeActive"
                  defaultChecked={this.state.values.active}
                  onChange={() =>
                    this.handleToggleServiceCharge(this.state.values.active)
                  }
                />
                <span></span>
              </label>
            </span>
            <label className="text-dark font-weight-boldest text-uppercase py-4 px-3">
              Add {formatOrderType(this.props.order_type)} Service Charge
            </label>
            {InfoTooltip(
              `Turning this on will add a non-refundable service charge in ${formatOrderType(
                this.props.order_type,
              )} option for your customers.`,
            )}
          </div>
          <Row>
            {this.state.values.active && (
              <>
                <Col sm={12} xs={12}>
                  <div className="mv-1rem text-left">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Large button group"
                    >
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.values.type === "amount_charge"
                            ? "primary"
                            : "outline-primary"
                        } font-weight-bold `}
                        onClick={() => this.onClickTab("amount_charge")}
                      >
                        Charge by Amount
                      </button>
                      <button
                        type="button"
                        className={`btn btn-${
                          this.state.values.type === "percent_charge"
                            ? "primary"
                            : "outline-primary"
                        } font-weight-bold`}
                        onClick={() => this.onClickTab("percent_charge")}
                      >
                        Charge by Percent
                      </button>
                    </div>
                  </div>
                  <label className="text-muted" style={{ fontSize: "0.9rem" }}>
                    {this.state.values.type === "amount_charge"
                      ? "Specify the amount you require the customer to pay. (max value: 1000)"
                      : "Service charge fee should range from 1% to 20%."}
                  </label>
                  <FormItem
                    style={{ width: 200, marginTop: -20 }}
                    name={"service_charge_fee"}
                    inputProps={{
                      name: "service_charge_fee",
                      placeholder: "1",
                      value: this.state.values.factor,
                    }}
                    inputActions={{
                      ...inputActions,
                      onChange: (e) => this.onChangeServiceCharge(e),
                    }}
                    actions={actions}
                    type={"number"}
                    normal
                  />
                  <Button
                    type="submit"
                    className={GetSubmitClassList(
                      this.state.isSubmitting,
                      "btn btn-primary font-weight-bold px-9 py-4 align-right",
                    )}
                    disabled={this.state.isServiceChargeSaveDisabled}
                    onClick={() => this.onClickSubmit(this.state.store)}
                  >
                    Save Changes
                  </Button>
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
