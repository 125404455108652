import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { AnimateDiv, OrderTimeRender, SVGIcon } from "../../components/Base";
import { EXTERNAL_CHECKOUT_KEY } from "../../constants";
import { formatDispatchString } from "../../helpers";
import { getMessage } from "../../helpers/branded-website";
import TrackOrderDetailsPage from "./TrackOrderDetailsPage";
import TrackMealPlanSchedulePage from "./TrackMealPlanSchedulePage";
import { isEmpty } from "lodash";
import moment from "moment";
/**
 * Track Page - Home
 * @param {Object} props.data
 * @param {Object} props.website_theme
 * @returns
 */
export default function TrackHomepage(props) {
  const {
    data = {},
    website_theme = {},
    showOrderDetailsPage = false,
    showMealPlanSchedulePage = false,
    actions = {},
  } = props;

  const { icon_color = "" } = website_theme;
  const is_order_cancelled =
    data.order.status.indexOf("cancelled") !== -1 ||
    data.order.status.includes("refund");
  const is_digital = data.order.order_type === "digital";
  const buttonStyle = {
    // backgroundColor: button_color,
    // borderColor: button_color,
    // color: button_text_color,
    backgroundColor: "#15C49A",
    borderColor: "#15C49A",
    width: "75%",
    fontSize: "1.125rem",
  };

  const ColoredIcon = styled.span`
    svg g [fill] {
      fill: ${icon_color};
    }
  `;

  return (
    <>
      <AnimateDiv>
        <div
          style={{
            display:
              showMealPlanSchedulePage || showOrderDetailsPage
                ? "none"
                : "block",
          }}
        >
          <div className="text-center">
            <div style={{ margin: "0.5rem 0" }}>
              <div className="text-uppercase font-weight-bold font-pickup-sm-1">
                Order #{data.order.transaction_number}{" "}
              </div>
              {data.order.table_number && (
                <>
                  <div className="text-uppercase font-pickup-sm-1">
                    Table Number
                  </div>
                  <div
                    className="text-uppercase font-weight-bold display-3"
                    style={{ fontSize: "1.125rem" }}
                  >
                    {data.order.table_number}
                  </div>
                </>
              )}
              <div>
                {getMessage(data.order.status, data.order.order_type, data)}
              </div>
            </div>
            <div style={{ padding: "0.25rem 0" }}>
              {is_digital ? (
                <>
                  <div className="text-uppercase font-weight-bold font-pickup-sm-1">
                    <>{`Event Time`}</>
                  </div>
                  <span style={{ fontSize: "14px", fontWeight: 500 }}>
                    {moment(data.event.start_date).format(
                      "MMMM D, YYYY h:mm A",
                    )}
                  </span>
                </>
              ) : (
                <Row>
                  <Col>
                    <div className="text-uppercase font-weight-bold font-pickup-sm-1">
                      <>{`estimated ${formatDispatchString(
                        data.order.order_type,
                      )} time`}</>
                    </div>
                    <OrderTimeRender
                      trackPage={true}
                      noRange={!(data.order.status === "new")}
                      store={data.order.store}
                      {...data.order}
                    />
                  </Col>
                </Row>
              )}
            </div>
            <div style={{ padding: "0.25rem 0" }}>
              <Row>
                <Col>
                  <div className="text-uppercase font-weight-bold font-pickup-sm">
                    <>contact our store</>
                  </div>
                  <div style={{ fontSize: "1rem" }}>
                    <i
                      className="fas fa-phone text-success"
                      style={{ transform: "rotateY(180deg)" }}
                    />
                    &nbsp;&nbsp;
                    <span className="font-weight-bold mv-1rem">
                      <a
                        style={{
                          color: website_theme.link_text_color,
                          textDecoration: "underline",
                        }}
                        href={
                          data.order.store.contact_number
                            ? `tel:${data.order.store.contact_number}`
                            : ""
                        }
                      >
                        {data.order.store.contact_number}
                      </a>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="font-pickup-sm-1 font-weight-normal">
              Share order details{" "}
              <Button
                variant="custom"
                className="btn-icon"
                style={{ marginLeft: "-5px" }}
                onClick={props.actions.onClickShare}
              >
                <ColoredIcon>
                  <SVGIcon icon={"Files/Share"} title="Share" />
                </ColoredIcon>{" "}
              </Button>{" "}
            </div>
            {!isEmpty(data) &&
              data.order.order_type === "delivery" &&
              ["ready", "completed"].includes(data.status) > -1 &&
              !isEmpty(data.delivery_data) &&
              data.delivery_data.status !== "COMPLETED" && (
                <div style={{ fontSize: "1.125rem" }}>
                  <ColoredIcon>
                    <SVGIcon icon={"Map/Position"} title="Position" />
                  </ColoredIcon>{" "}
                  &nbsp;&nbsp;
                  <span className="font-weight-bold">
                    <a
                      style={{
                        color: "#464E5F",
                        textDecoration: "underline",
                        marginLeft: "-10px",
                      }}
                      href={
                        data.order.delivery.delivery_partner === "lalamove"
                          ? `${data.delivery_data.shareLink}`
                          : data.order.delivery.delivery_partner === "mrspeedy"
                          ? `${
                              data.order.delivery &&
                              (data.order.delivery.lastStatus === "planned" ||
                                data.order.delivery.lastStatus ===
                                  "MULTI_ASSIGNING" ||
                                data.order.delivery.lastStatus ===
                                  "courier_assigned" ||
                                data.order.delivery.lastStatus ===
                                  "courier_departed")
                                ? `${data.delivery_data.orders[0].points[0].tracking_url}`
                                : `${data.delivery_data.orders[0].points[1].tracking_url}`
                            }`
                          : data.order.delivery.delivery_partner === "grab"
                          ? `${data.delivery_data.trackingURL}`
                          : ""
                      }
                      target="_blank"
                    >
                      Track your order here
                    </a>
                  </span>
                </div>
              )}
            <div className="d-flex flex-column justify-content-center align-items-center">
              {data.order.order_type === "curbside_pickup" &&
              !is_order_cancelled ? (
                <>
                  {!data.order.curbside_pickup_details.hasArrived ? (
                    <Button
                      style={buttonStyle}
                      className="btn-block btn-md font-weight-bold mt-4"
                      onClick={() =>
                        props.handleCustomerArrived(data.order._id)
                      }
                    >
                      I have arrived
                    </Button>
                  ) : (
                    <Button
                      style={buttonStyle}
                      className="btn-block font-weight-bold mt-4 disabled"
                    >
                      {data.order.status === "accepted"
                        ? "We are preparing your order please hang tight"
                        : "Coming up"}
                    </Button>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {showOrderDetailsPage && (
          <TrackOrderDetailsPage
            data={data}
            actions={actions}
            website_theme={website_theme}
          />
        )}
        {showMealPlanSchedulePage && (
          <TrackMealPlanSchedulePage
            data={data}
            actions={actions}
            website_theme={website_theme}
          />
        )}
      </AnimateDiv>
    </>
  );
}
