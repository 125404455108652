import PropTypes from "prop-types";
import React, { useState, useRef } from "react";

import { Button, Modal } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import useFetch from "../../../hooks/useFetch";
import { CopyToClipboard } from "react-copy-to-clipboard";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const Announcement = ({
  data_url = "",
  onHide = () => {},
  buttonStyle = {},
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const nextRef = useRef(null);
  const [close, setClose] = useState(false);
  const [index, setIndex] = useState(0);

  let { data, isLoading } = useFetch({
    url: data_url,
  });
  // console.log("modal", data);

  const onClose = () => {
    onHide && onHide();
    setIsOpen(!isOpen);
  };

  if (isLoading || data === null) return <></>;

  return (
    data !== null &&
    data.length > 0 && (
      <div>
        <Modal
          centered
          show={isOpen}
          onHide={onHide}
          className={`${data ? "d-block opacity-100" : "d-none opacity-0"}`}
          style={{
            transition: "opacity 9s",
          }}
        >
          <Modal.Body className="p-0">
            <Swiper
              modules={[Pagination, Navigation]}
              navigation={{
                nextEl: ".got-it",
              }}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              scrollbar={false}
              loop={false}
              onSlideChange={(swiper) => {
                setIndex(swiper.activeIndex);
                if (swiper.activeIndex === data.length - 1) {
                  setIndex(swiper.activeIndex);
                  let nextButton = swiper.navigation.nextEl;
                  nextButton.className.replace(".swiper-button-disabled", "");
                  nextButton.disabled = false;
                  if (index === 0 && data.length > 2) {
                    setClose(true);
                  }
                } else if (swiper.activeIndex === data.length - 2) {
                  setIndex(swiper.activeIndex);
                  if (close === true) {
                    setClose(false);
                  }
                }
              }}
            >
              {Array.isArray(data) ? (
                data.map((item, i) => (
                  <SwiperSlide key={item._id}>
                    <SlideContent
                      item={item}
                      itemIndex={i}
                      dataLength={data.length}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide>
                  <SlideContent item={data} itemIndex={1} dataLength={1} />
                </SwiperSlide>
              )}
            </Swiper>

            {/* <button
              style={{ top: "2rem", right: "1.5rem", zIndex: 10 }}
              type="button"
              className="close announcement-close position-absolute"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <i
                aria-hidden="true"
                className="ki ki-close rounded-circle bg-dark text-light px-5 py-4"
              ></i>
            </button> */}
          </Modal.Body>

          <Modal.Footer className="p-0">
            {data.length > 1 ? (
              <Button
                variant="primary"
                className="btn-inline-block m-4 mx-auto got-it"
                ref={nextRef}
                onClick={() => {
                  if (index === data.length - 1) {
                    setClose(true);
                  } else {
                    setClose(false);
                  }
                  if (close && index === data.length - 1) {
                    onClose();
                  }
                }}
                style={buttonStyle}
              >
                {index !== data.length - 1 ? "Got it!" : "Close"}
              </Button>
            ) : (
              <Button
                variant="primary"
                className="btn-inline-block m-4 mx-auto"
                onClick={() => {
                  onClose();
                }}
                style={buttonStyle}
              >
                Close
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

Announcement.propTypes = {
  data_url: PropTypes.string,
  onHide: PropTypes.func,
};

const replaceWithBr = (text) => {
  return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
};

const SlideContent = ({ item = {}, itemIndex = 0, dataLength = 0 }) => {
  const [copy, setCopy] = useState(false);
  // console.log(item);
  const onCopyToClipboardSuccess = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 2000);
  };

  const headerStyle = {
    position: "absolute",
    top: "5%",
    color: "black",
    backgroundColor: "rgba(250,250,250,0.5)",
    fontSize: "2em",
    fontWeight: "bolder",
  };
  const textStyle = {
    position: "absolute",
    top: "25%",
    color: "black",
    backgroundColor: "rgba(250,250,250,0.5)",
    fontSize: "1.25em",
  };
  const promoCodeStyle = {
    position: "absolute",
    top: "50%",
    color: "red",
    backgroundColor: "rgba(250,250,250,0.5)",
    fontSize: "1.8em",
    fontWeight: "bolder",
  };
  if (item.text_orientation === "left") {
    headerStyle.left = "0%";
    textStyle.left = "0%";
    promoCodeStyle.left = "0%";
  } else if (item.text_orientation === "center") {
    headerStyle.left = "50%";
    textStyle.left = "50%";
    promoCodeStyle.left = "50%";
    headerStyle.transform = "translate(-50%, -50%)";
    textStyle.transform = "translate(-50%, -50%)";
    promoCodeStyle.transform = "translate(-50%, -50%)";
  } else if (item.text_orientation === "right") {
    headerStyle.right = "0%";
    textStyle.right = "0%";
    promoCodeStyle.right = "0%";
  } else {
    headerStyle.left = "0%";
    textStyle.left = "0%";
    promoCodeStyle.left = "0%";
  }

  return (
    <>
      <div className="position-relative">
        <img
          className="w-100 rounded-top"
          src={item.img}
          alt={item.img}
          style={{
            maxHeight: "55vh",
            objectFit: "cover",
          }}
        />

        {/* description */}
        {/* {item.text !== "" && (
          <>
            <div className="px-5" style={headerStyle}>
              {item.header}
            </div>
            <div className="px-5" style={textStyle}>
              {item.text}
            </div>
          </>
        )} */}

        {/* promo code */}
        {item.promoCode !== undefined &&
          item.promoCode !== null &&
          item.promoCode !== "" &&
          item.isOverlaid && (
            <div className="px-5" style={promoCodeStyle}>
              {item.promoCode}
              <CopyToClipboard
                text={item.promoCode}
                onCopy={onCopyToClipboardSuccess}
              >
                <Button
                  variant="secondary"
                  size="sm"
                  className="btn-icon btn-circle btn-sm"
                  style={{ height: "30px", width: "30px", marginLeft: "5px" }}
                >
                  <i className="far fa-copy"></i>
                </Button>
              </CopyToClipboard>
              <div
                className="text-danger"
                style={{
                  fontSize: "1rem",
                  position: "absolute",
                  right: 10,
                }}
              >
                {copy ? "Copied!" : ""}
              </div>
            </div>
          )}

        <PerfectScrollbar
          options={perfectScrollbarOptions}
          className="scroll"
          style={{ maxHeight: "40vh", marginBottom: "10px" }}
        >
          <div
            className="px-4 py-2"
            style={{
              // overflowY: 'scroll',
              maxHeight: "40vh",
              marginBottom: "10px",
            }}
          >
            <h4>{item.header}</h4>
            <p
              dangerouslySetInnerHTML={{ __html: replaceWithBr(item.text) }}
            ></p>
            {!item.isOverlaid &&
              item.promoCode !== undefined &&
              item.promoCode !== null &&
              item.promoCode !== "" && (
                <div className="px-5">
                  <h5>
                    Use Promo Code: {item.promoCode}
                    <CopyToClipboard
                      text={item.promoCode}
                      onCopy={onCopyToClipboardSuccess}
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        className="btn-icon btn-circle btn-sm"
                        style={{
                          height: "30px",
                          width: "30px",
                          marginLeft: "5px",
                        }}
                      >
                        <i className="far fa-copy"></i>
                      </Button>
                    </CopyToClipboard>
                    <div
                      className="text-danger"
                      style={{
                        fontSize: "1rem",
                        position: "absolute",
                        right: "35%",
                      }}
                    >
                      {copy ? "Copied!" : ""}
                    </div>
                  </h5>
                </div>
              )}
          </div>
        </PerfectScrollbar>
      </div>
      {dataLength > 1 && (
        <span
          style={{ top: "2rem", left: "1rem" }}
          className="position-absolute text-dark-75 bg-light opacity-60 px-4 py-2 rounded"
        >
          {itemIndex + 1} / {dataLength}
        </span>
      )}
    </>
  );
};

SlideContent.propTypes = {
  dataLength: PropTypes.number,
  item: PropTypes.object,
  itemIndex: PropTypes.number,
};

export default Announcement;
