import moment from "moment";

export const SHOW_LOGIN_FOOTER_LINKS = false,
  DEFAULT_FORM_GROUP_CLASSLIST = "fv-plugins-icon-container",
  DEFAULT_INPUT_CLASSLIST = "py-7 px-6 border-0 rounded-lg",
  DEFAULT_PRICING_CARD_CLASSLIST =
    "card-custom bgi-no-repeat card-stretch gutter-b card-border-top-primary cursor-pointer",
  PRIMARY_COLOR = "#14C49B",
  DANGER_COLOR = "#F64E60",
  PROSOURCE_COLOR = "#ea178b",
  DEFAULT_STORE_HOURS_OPENING = "07:00",
  DEFAULT_STORE_HOURS_CLOSING = "16:00",
  DEFAULT_STORE_24_HOURS_OPENING = "00:00",
  DEFAULT_STORE_24_HOURS_CLOSING = "23:59",
  OPENING_HRS_FORMAT = "HH:mm",
  CLOSING_HRS_FORMAT = "HH:mm",
  WEBSITE_NAME = "Pickup.ph",
  CURRENCY = "PHP",
  MAIN_DOMAIN_NAME = "pickup.ph",
  DB_DATE_FORMAT = "YYYY-MM-DD",
  DB_HOURS_FORMAT = "HH:mm",
  ASAP_PREP_TIME_IN_MIN = 20,
  TIME_OPTIONS_INTERVAL_IN_MIN = 30,
  TIME_OPTION_DISPLAY_FORMAT = "hh:mm A",
  ASAP_ORDER_TIME_FORMAT = "asap_order_time",
  ASAP_DEFAULT_PREP_TIME_OPTION = "20", // for accepting asap orders
  PAYMENT_DELAY_TIME = 1000, // after checking out
  EXTERNAL_CHECKOUT_KEY = "PICKUP_EXTERNAL_CHECKOUT",
  GRAPH_COLORS = [
    "#008FFB",
    "#FEB019",
    "#FF4560",
    "#775DD0",
    "#43BCCD",
    "#3F51B5",
    "#03A9F4",
    "#4CAF50",
    "#F9CE1D",
    "#FF9800",
    "#33B2DF",
    "#D4526E",
    "#546E7A",
    "#662E9B",
  ],
  ITEM_DESCRIPTION_CHAR_LIMIT = 300,
  ANNOUNCEMENT_TEXT_CHAR_LIMIT = 300,
  MS_PER_MINUTE = 60000,
  RESERVATION_LIMIT_MIN = 5,
  RESERVATION_LIMIT_MS = RESERVATION_LIMIT_MIN * MS_PER_MINUTE,
  RESERVATION_PRICE_MINIMUM = 100,
  TIMEZONE_OFFSET = 8,
  TIMEZONE_OFFSET_IN_HOURS = 960,
  PRE_ORDER_TO_ORDER_QUEUE_TIMER_DEFAULT = 20,
  MINUTES_ALLOW_ORDER_BEFORE_OPENING_DEFAULT = 0,
  REFUND_NOTES_CHAR_LIMIT = 500,
  DELIVERY_KM_LIMIT = 50,
  PAGES_PRIVILEGES = [
    "home",
    "stores",
    "menu",
    "orders",
    "promos",
    "place-orders",
    "analytics",
    "google-analytics",
    "settings",
    "my-account",
    "feedback",
    "help",
    "announcements",
    "reservations",
    "events",
  ],
  BETA_TEST_AGREEMENT_LINK =
    "https://drive.google.com/file/d/1nsc-cgwsQ1GY2KG1n8gYLxIue7t2bjE2/view",
  DATERANGE_LIST = [
    { date: moment().format(DB_DATE_FORMAT), name: "Today" },
    {
      date: moment(moment().subtract(1, "days")).format(DB_DATE_FORMAT),
      name: "Yesterday",
    },
    { date: moment().format(DB_DATE_FORMAT), name: "This Week" },
    {
      date: moment(moment().subtract(1, "weeks")).format(DB_DATE_FORMAT),
      name: "Last Week",
    },
    {
      date: moment(moment().add(1, "weeks")).format(DB_DATE_FORMAT),
      name: "Next Week",
    },
    {
      date: moment().startOf("month").format(DB_DATE_FORMAT),
      name: "This Month",
    },
    {
      date: moment(moment().subtract(1, "months"))
        .startOf("month")
        .format(DB_DATE_FORMAT),
      name: "Last Month",
    },
    {
      date: moment().startOf("year").format(DB_DATE_FORMAT),
      name: "This Year",
    },
    {
      date: moment(moment().subtract(1, "year"))
        .startOf("year")
        .format(DB_DATE_FORMAT),
      name: "Last Year",
    },
    { date: moment().format(DB_DATE_FORMAT), name: "All-time" },
  ],
  SIGNUP_TYPE_LIST = ["self_signup", "inside_sales", "legacy"],
  CONSOLE_USERS = [
    "console_user",
    "console_user_dispatch",
    "console_user_counter",
  ],
  STORE_USERS = [
    "store_admin",
    "console_user",
    "console_user_dispatch",
    "console_user_counter",
  ],
  CANCELLED_DELIVERIES = [
    "CANCELED",
    "REJECTED",
    "EXPIRED",
    "canceled",
    "failed",
    "CANCELED",
    "FAILED",
    "RETURNED",
  ],
  ASSIGNING_STATUS = [
    "ASSIGNING_DRIVER",
    "ALLOCATING",
    "MULTI_ASSIGNING",
    "QUEUEING",
    "PENDING_PICKUP",
  ],
  ON_GOING_STATUSES = ["ON_GOING", "PICKING_UP", "PENDING_PICKUP"],
  ON_PROCESS_STATUSES = [
    "ON_GOING",
    "PENDING_DROP_OFF",
    "PICKING_UP",
    "PICKED_UP",
    "IN_DELIVERY",
  ],
  COMPLETED_STATUS = ["COMPLETED"],
  DISPLAY_DATE_FORMAT = "MMMM D, YYYY",
  DISPLAY_HOURS_FORMAT = "h:mm A",
  MEAL_DATE_FORMAT = "ddd, MMM DD, YYYY",
  ONBOARDING_PROGRESS = [
    "paymongo",
    "brand",
    "store",
    "menu",
    "delivery",
    "publish",
  ],
  ROLES = {
    super_admin: 0,
    group_admin: 1,
    brand_admin: 2,
    store_admin: 3,
    console_user: 4,
  },
  DANGER_SWAL_COLOR = "#f27474",
  REJECT_CANCELLED_STATUSES = [
    "cancelled_full_refund",
    "cancelled_no_refund",
    "cancelled_partial_refund",
    "cancelled_no_show",
  ],
  MEAL_PLAN_COLOR = "#2196F3",
  PROMOS_APPLIES_TO = [
    "pickup",
    "third_party_pickup",
    "curbside_pickup",
    "delivery",
    "dine_in",
    "meal_plan",
  ],
  PROMO_TYPES = ["amount_off", "percentage_off", "free_delivery", "free_item"],
  PROMO_SCOPE_LEVELS = ["group", "brand", "store"],
  RESERVATION_DATE_HEADER_FORMAT = "dddd, MMMM DD",
  BRAND_PRODUCT_TYPES = ["food", "non-food"],
  ADMINTOOLS_FETCH_LIMIT = 20,
  STORE_AND_SEARCH_TAGS = [
    "Barbecue",
    "Breakfast",
    "Burger",
    "Chicken",
    "Coffee",
    "Dessert",
    "Fast food",
    "Healthy",
    "Indian",
    "Japanese",
    "Mexican",
    "Middle Eastern",
    "Pasta",
    "Pizza",
    "Salad",
    "Sandwich",
    "Smoothie",
    "Stirfry",
    "Vegan",
  ],
  PASSWORD_LENGTH = 6,
  S3_IMAGE_URI =
    "https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/track/",
  ORDER_TYPES = [
    { label: "Delivery", value: "delivery" },
    { label: "Pick-up", value: "pickup" },
    { label: "Third Party Pick-up", value: "third_party_pickup" },
    { label: "Curbside Pick-up", value: "curbside_pickup" },
    { label: "Meal Plan", value: "meal_plan" },
  ],
  PRE_ORDER_QUEUE_WARNING = 60;
