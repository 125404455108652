import React from "react";

import core from "../../../vendors/core-api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import TitleHelmet from "../../../partials/TitleHelmet";

import { CreateCartForm } from "./CreateCartForm";
import { Button, Card, Col, Form, Image, Modal, Row } from "react-bootstrap";
import ExclusiveCartSummary from "./ExclusiveCartSummary";
import { filterObj, GetSubmitClassList } from "../../../helpers";
import {
  HeaderTextWithActions,
  RequiredAsterisk,
  SVGIcon,
} from "../../../components/Base";
import Breadcrumbs from "../../../components/Breadcrumbs";
import APIComponent from "../../../components/API";
import CreateCartItems from "./CreateCartItems";
import { cloneDeep, isBoolean, isEmpty, isEqual, isNull } from "lodash";
import { ServiceChargeContainer } from "./ServiceChargeContainer";
import {
  computeExtrasAmount,
  generateExtrasChecker,
} from "../../../helpers/branded-website";
import {
  DANGER_COLOR,
  DB_DATE_FORMAT,
  PRIMARY_COLOR,
} from "../../../constants";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { CartValidity } from "./CartValidity";
import qs from "qs";
import QRCode from "qrcode";
import { ItemModal } from "../PerStoreItems";

const MySwal = withReactContent(Swal);

export default function Page(props) {
  const { store_id = "" } = useParams();
  const { cart_id = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  const { user } = useSelector((state) => state.auth);

  return (
    <MainComponent id={store_id} cart_id={cart_id} user={user} {...props} />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      mountForm: true,
      title: "Concierge Cart",
      groupName: "",
      menu: null,
      searchText: "",
      items: [],
      values: {
        order_type: null,
        order_date: null,
        order_time: null,
      },
      store: {},
      service_charge: {},
      cart_link: "",
      isSubmitting: false,
      exp_date: null,
      isEdit: !isEmpty(props.cart_id),
      cart_alias: "",
      cart: {},
      status: "",
      showLinkModal: false,
      qr_code: "",
      showQR: false,
      showAddModal: false,
      cartAliasValid: "",
    };
    this.api = core("pu").get();
    this.fileapi = core("puf").get();
    this.handleAddToCart = this.handleAddToCart.bind(this);
    this.handleQuantityUpdate = this.handleQuantityUpdate.bind(this);
    this.handleOnCopyURL = this.handleOnCopyURL.bind(this);
    this.populateCartValues = this.populateCartValues.bind(this);
    this.onCartCreate = this.onCartCreate.bind(this);
    this.onCartUpdate = this.onCartUpdate.bind(this);
    this.formatCartObject = this.formatCartObject.bind(this);
    this.generateQRCode = this.generateQRCode.bind(this);
    this.handleOnCopyImageToClipboard =
      this.handleOnCopyImageToClipboard.bind(this);
    this.handleOnDownloadImage = this.handleOnDownloadImage.bind(this);
    this.getExtras = this.getExtras.bind(this);
    this.handleSubmitItem = this.handleSubmitItem.bind(this);
    this.handleItemPhotoUpload = this.handleItemPhotoUpload.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.getData(this.props.cart_id);
  }

  generateQRCode = (url_str = "") => {
    let retVal = "";
    if (!isEmpty(url_str)) {
      QRCode.toDataURL(
        url_str,
        {
          color: {
            light: "#0000",
          },
          width: 300,
          margin: 2,
        },
        (err, url) => {
          if (err) console.log("qrcode err: ", err);
          retVal = url;
        },
      );
    }
    return retVal;
  };

  populateCartValues = (cart = {}) => {
    const copy_state = this.state;
    const { order_details, status } = cart;
    const cart_details = JSON.parse(order_details);
    const {
      order_type,
      order_time,
      order_date,
      items,
      service_charge = {},
      exp_date,
      cart_alias,
    } = cart_details;

    copy_state.values = {
      order_type,
      order_time,
      order_date,
    };
    copy_state.status = status;
    copy_state.cart_alias = cart_alias;
    copy_state.items = items;
    copy_state.service_charge = service_charge;
    copy_state.exp_date = exp_date;

    const cart_link_str = `${process.env.REACT_APP_CHECKOUT_CART_DOMAIN}/checkout/${cart._id}`;
    this.setState({
      ...copy_state,
      ...(status === "new" && {
        cart_link: cart_link_str,
        qr_code: this.generateQRCode(cart_link_str),
      }),
    });
  };

  async getData(cart_id = "") {
    const { values } = this.state;
    const { order_date, order_time } = values;

    this.data_url = `/store/${this.props.id}/cart-builder`;

    if (!isEmpty(cart_id)) {
      this.data_url =
        this.data_url +
        `?cart_id=${cart_id}&order_time=${order_time || ""}&order_date=${
          order_date || ""
        }`;
    }
    this._isMounted && this.setState({ data_status: "fetching" });
    this.api &&
      this.api
        .get(this.data_url)
        .then(({ data }) => {
          const { store, items, cart } = data;
          if (!isEmpty(cart) && isEmpty(order_date) && isEmpty(order_time)) {
            this.populateCartValues(cart);
          }
          this._isMounted &&
            this.setState({
              store,
              data_status: "fetched",
              groupName: store.brand.client.name,
              menu: items,
              cart,
            });
          this.getExtras(store);
        })
        .catch((err) => {
          if (err.hasOwnProperty("response")) {
            const { data = {} } = err.response,
              { error_message = {}, error = "" } = data;
            if (!isEmpty(error_message)) {
              MySwal.fire({
                icon: "error",
                title: "Ooops!",
                text: error_message,
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "OK",
                showCancelButton: false,
              });
              if (!isEmpty(error)) {
                if (error === "CartNotFound") {
                  this.props.history.push(
                    `/stores/${this.props.id}/concierge-carts`,
                  );
                }
              }
            } else {
              this.handleOnDataFetchError();
            }
          }
          this._isMounted && this.setState({ error: err, showSwalError: true });
          this.handleOnDataFetchError();
        });
  }

  getExtras = async (store) => {
    if (!isEmpty(store) && store._id) {
      const extras = await this.api.get(
        "/extras/get_group_by_store?store=" + store._id,
      );
      this.setState({ store: { ...store, extras: extras.data } });
    }
  };

  formatCartObject = (status = "draft") => {
    const {
      values,
      items,
      exp_date,
      service_charge = 0,
      cart_alias = "",
    } = this.state;
    const exclusive_cart = {
      ...values,
      items,
      hasBulk: items.some((item) => item.isBulk),
      items_count: items.reduce((acc, cur) => (acc += cur.qty), 0),
      ...(!isNull(exp_date) && { exp_date }),
      service_charge,
      cart_alias,
      status,
    };
    return exclusive_cart;
  };

  onDraftClick = async () => {
    const { status, isEdit } = this.state;
    this.setState({ isSubmitting: true });
    const exclusive_cart = this.formatCartObject();
    if (isEdit && !isEmpty(status)) {
      await this.onCartUpdate(exclusive_cart, status === "draft");
    } else {
      await this.onCartCreate(exclusive_cart, true);
    }
  };

  onCartUpdate = async (exclusive_cart = {}) => {
    const { store = {}, cart_link = "" } = this.state;
    this.api
      .put(
        {
          url: `/store/${this.props.id}/concierge-cart-update/${this.props.cart_id}`,
          data: { cart: exclusive_cart },
        },
        {
          headers: {
            Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
          },
          baseURL: this.api.getBaseURL(),
        },
      )
      .then(({ data }) => {
        if (!isEmpty(data)) {
          this.setState({ isSubmitting: false, cartAliasValid: true });
          if (exclusive_cart.status === "new" && isEmpty(cart_link)) {
            const cart_link_str = `${process.env.REACT_APP_CHECKOUT_CART_DOMAIN}/checkout/${data._id}`;
            this.setState({
              showLinkModal: true,
              cart_link: cart_link_str,
              qr_code: this.generateQRCode(cart_link_str),
            });
          } else {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "Successfully updated cart!",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result && result.isConfirmed) {
                this.getData();
              }
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        if (err.hasOwnProperty("response")) {
          const { data = {} } = err.response,
            { error_message = {}, error = "" } = data;
          if (!isEmpty(error_message)) {
            MySwal.fire({
              icon: "error",
              title: "Ooops!",
              text: error_message,
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
            if (!isEmpty(error)) {
              if (error === "CartNotFound") {
                this.props.history.push(
                  `/stores/${this.props.id}/concierge-carts`,
                );
              } else if (error === "cart_alias_invalid") {
                this.setState({ cartAliasValid: false });
              }
            }
          } else {
            this.handleError();
          }
        } else {
          this.handleError();
        }
      });
  };

  onCartCreate = async (exclusive_cart = {}, isDraft = false) => {
    const { store } = this.state;
    this.api
      .post(
        {
          url: `/store/${this.props.id}/concierge-cart`,
          data: { cart: exclusive_cart },
        },
        {
          headers: {
            Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
          },
          baseURL: this.api.getBaseURL(),
        },
      )
      .then(({ data }) => {
        if (!isEmpty(data)) {
          this.setState({
            isSubmitting: false,
            cart: data,
            cartAliasValid: true,
          });
          if (!isDraft) {
            const cart_link_str = `${process.env.REACT_APP_CHECKOUT_CART_DOMAIN}/checkout/${data._id}`;
            this.setState({
              showLinkModal: true,
              cart_link: cart_link_str,
              qr_code: this.generateQRCode(cart_link_str),
              status: "new",
            });
            this.populateCartValues(data);
          } else {
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "Successfully saved draft!",
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "Ok",
            }).then((result) => {
              if (result && result.isConfirmed) {
                this.props.history.push(
                  `/stores/${this.props.id}/concierge-carts`,
                );
              }
            });
          }
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
        if (err.hasOwnProperty("response")) {
          const { data = {} } = err.response,
            { error_message = {}, error = "" } = data;
          if (!isEmpty(error_message)) {
            MySwal.fire({
              icon: "error",
              title: "Ooops!",
              text: error_message,
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
            if (!isEmpty(error) && error === "cart_alias_invalid") {
              this.setState({ cartAliasValid: false });
            }
          } else {
            this.handleError();
          }
        } else {
          this.handleError();
        }
      });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    const { isEdit } = this.state;
    this.setState({ isSubmitting: true });
    const exclusive_cart = this.formatCartObject("new");

    if (isEdit) {
      await this.onCartUpdate(exclusive_cart);
    } else {
      await this.onCartCreate(exclusive_cart);
    }
  };

  handleAddToCart = (item = {}, isAdd = false, extras = []) => {
    const { values, items, store } = this.state;
    const { menu } = store;
    const { order_date } = values;
    const { item_id } = item;
    let itemExtraExist = true;

    const sorted_extras = extras.sort((a, b) =>
      a.extra_id.localeCompare(b.extra_id),
    );

    let existing_item_idx = items.findIndex(
      (cart_item) => cart_item.item.item_id._id === item_id._id,
    );

    if (extras.length > 0) {
      const new_set_of_extras = generateExtrasChecker(sorted_extras);
      itemExtraExist =
        items.filter((item) =>
          isEqual(generateExtrasChecker(item.extras), new_set_of_extras),
        ).length > 0;
      if (itemExtraExist) {
        items.forEach((cart_item, idx) => {
          if (
            isEqual(generateExtrasChecker(cart_item.extras), new_set_of_extras)
          ) {
            existing_item_idx = idx;
          }
        });
      }
    }

    //update
    if (existing_item_idx >= 0 && itemExtraExist) {
      const update_items = cloneDeep(items);
      if (items[existing_item_idx].qty === 1 && !isAdd) {
        update_items.splice(existing_item_idx, 1);
      } else {
        update_items[existing_item_idx].qty += 1 * (isAdd ? 1 : -1);
        if (
          isAdd &&
          Number(update_items[existing_item_idx].custom_max) <
            update_items[existing_item_idx].qty
        ) {
          update_items[existing_item_idx].custom_max = String(
            update_items[existing_item_idx].qty,
          );
        }
      }

      this.setState({
        items: update_items,
      });
    } else {
      const new_items = [
        ...items,
        {
          item,
          order_date,
          price: parseInt(item_id.price) + computeExtrasAmount(extras),
          regular_price:
            parseInt(item_id.regular_price) + computeExtrasAmount(extras),
          qty: 1,
          isBulk: item_id.isBulk,
          extras: sorted_extras,
          custom_max: "1",
        },
      ];
      this.setState({
        items: new_items,
      });
    }
  };

  handleQuantityUpdate = (idx, key = "", value = 0) => {
    const { items } = this.state;
    if (idx >= 0) {
      const update_items = cloneDeep(items);
      update_items[idx][key] = value;
      if (update_items[idx].qty > update_items[idx].custom_max) {
        update_items[idx].qty = Number(value);
      }
      this.setState({
        items: update_items,
      });
    }
  };

  handleOnCopyURL = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          return MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "Copied URL to clipboard successfully.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
            timer: 1000,
          });
        })
        .then((response) => {
          if (response && (response.isDismissed || response.isConfirmed)) {
            this.props.history.push(`/stores/${this.props.id}/concierge-carts`);
          }
        });
    }
  };

  handleOnCopyImageToClipboard = async () => {
    const { qr_code } = this.state;
    const img = await fetch(qr_code, {
      cache: "no-cache",
      referrerPolicy: "no-referrer",
    });
    const imgBlob = await img.blob();
    if (imgBlob.type === "image/png") {
      try {
        await navigator.clipboard.write([
          new window.ClipboardItem({
            "image/png": imgBlob,
          }),
        ]);
        return MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Copied QR Image to clipboard successfully.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        }).then((response) => {
          if (response && (response.isDismissed || response.isConfirmed)) {
            this.props.history.push(`/stores/${this.props.id}/concierge-carts`);
          }
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      return MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Unable to Copy the QR Image.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        timer: 1000,
      });
    }
  };

  handleOnDownloadImage = async () => {
    const { qr_code } = this.state;
    let img = await fetch(qr_code, {
      cache: "no-cache",
      referrerPolicy: "no-referrer",
    });
    let imgBlob = await img.blob();
    if (imgBlob.type === "image/png") {
      try {
        const url = window.URL.createObjectURL(imgBlob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `exlusive_cart_${new Date().getTime()}.png`,
        );
        document.body.appendChild(link);
        link.click();
        return MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Download Successful.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
          timer: 1000,
        }).then((response) => {
          if (response && (response.isDismissed || response.isConfirmed)) {
            this.props.history.push(`/stores/${this.props.id}/concierge-carts`);
          }
        });
      } catch (err) {
        console.error(err);
      }
    } else {
      return MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Unable to Download the QR Image.`,
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Ok",
        // timer: 1000
      });
    }
  };

  handleItemPhotoUpload = (args, setFilePath, setSubmitting) => {
    const formData = new FormData();
    for (const key in args) {
      formData.append(key, args[key]);
    }
    this.fileapi
      .post({
        url: "/util/upload-to-aws/items",
        data: formData,
      })
      .then(({ data }) => {
        const { filePath = "" } = data;
        setFilePath(filePath);
      })
      .catch((error) => {
        const { data = {} } = error.response,
          { message = {} } = data;
        if (message.error === "TokenExpiredError") {
          window.location.href = "/logout";
        } else if (message.error === "UnauthorizedUserError") {
          window.location.href = "/error/error-v1";
        } else {
          MySwal.fire({
            icon: "error",
            title: "Oops!",
            text: message ? message : "",
          });
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  handleSubmitItem = async (values, setSubmitting) => {
    const { store } = this.state;
    values.brand = store.brand;
    values.store_id = store._id;
    values.isExclusive = true;

    this.api
      .post({
        url: "/item",
        data: filterObj(values, ["_id"]),
      })
      .then(() => {
        setSubmitting(false);
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "Item added successfully!",
          confirmButtonColor: PRIMARY_COLOR,
          timer: 3000,
        });
        this.getData();
        this.setState({
          showAddModal: false,
          extraGroupErr: [],
        });
      })
      .catch((error) => {
        setSubmitting(false);
        if (error.hasOwnProperty("response")) {
          const { data = {} } = error.response,
            { error_message = {} } = data;
          if (!isEmpty(error_message)) {
            MySwal.fire({
              icon: "error",
              title: "Ooops!",
              text: error_message,
              confirmButtonColor: PRIMARY_COLOR,
              confirmButtonText: "OK",
              showCancelButton: false,
            });
          } else {
            this.handleError();
          }
        } else {
          this.handleError();
        }
      });
  };

  render() {
    const {
      groupName,
      menu,
      data_status,
      searchText,
      store,
      values,
      items = [],
      cart_link,
      service_charge = {},
      isSubmitting,
      isEdit,
      exp_date,
      cart_alias = "",
      status,
      showLinkModal,
      qr_code,
      cart = {},
      showAddModal = false,
      cartAliasValid,
    } = this.state;

    const isCartValid = !isEmpty(items) && !isEmpty(cart_alias);
    const current_date = moment(new Date()).format(DB_DATE_FORMAT);
    const isOrderDateValid = moment(current_date).isSameOrBefore(
      values.order_date,
    );
    return (
      <>
        <TitleHelmet title={this.state.title} />
        <HeaderTextWithActions
          breadcrumbs={
            <Breadcrumbs>
              <Breadcrumbs.Item text={groupName} link={`/stores`} />
              <Breadcrumbs.Item
                text="Concierge Carts"
                link={`/stores/${store._id}/concierge-carts`}
              />
              <Breadcrumbs.Item
                text={`${
                  isEdit || cart._id ? "Edit" : "Create"
                } Concierge Cart`}
                active={"true"}
              />
            </Breadcrumbs>
          }
        />
        <Form onSubmit={(e) => this.onSubmit(e, false)}>
          <div className="d-flex align-items-center justify-content-space-between mb-4">
            <div className="display-4 font-weight-bolder mv-1-rem w-100 align-self-stretch d-flex align-items-center ">
              {isEdit || cart._id ? "Edit" : "Create"} Concierge Cart &nbsp;
            </div>
            <div className="d-flex flex-row">
              {!isEmpty(cart_link) && (
                <Button
                  type="button"
                  disabled={isSubmitting || !isOrderDateValid}
                  className={GetSubmitClassList(
                    this.state.isSubmitting,
                    `btn btn-primary font-weight-bold px-4 py-4 my-3 mx-4 text-nowrap`,
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      showLinkModal: true,
                    });
                  }}
                >
                  <i className="fas fa-link" />
                  Generate QR Code
                </Button>
              )}
              {(isEmpty(status) || status === "draft") && isEmpty(qr_code) && (
                <Button
                  type="button"
                  disabled={isSubmitting || isEmpty(cart_alias)}
                  className={GetSubmitClassList(
                    this.state.isSubmitting,
                    `btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4 text-nowrap`,
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    this.onDraftClick();
                  }}
                >
                  <SVGIcon icon="General/Save" variant="custom" />
                  {`${
                    isEdit && status === "draft" ? "Update" : "Save as"
                  } Draft`}
                </Button>
              )}
              <Button
                type="submit"
                disabled={!isCartValid || isSubmitting}
                className={GetSubmitClassList(
                  this.state.isSubmitting,
                  `btn btn-primary font-weight-bold px-9 py-4 my-3 text-nowrap`,
                )}
              >
                {`${
                  isEmpty(status) || (isEdit && status === "draft")
                    ? "Save"
                    : "Update"
                } Cart`}
              </Button>
            </div>
          </div>
          <Row>
            <Col sm={8}>
              <Card className={`card-custom mb-4`}>
                <Card.Title className="pb-0 mb-0">
                  <div className="flex flex-column flex-grow-1">
                    <h3 className="ml-8 mt-8 font-weight-bolder">
                      Cart Alias <RequiredAsterisk />
                    </h3>
                  </div>
                </Card.Title>
                <Card.Body className="p-0 px-8">
                  <div className="d-flex flex-column flex-grow-1 mb-8">
                    <input
                      type="text"
                      className={`form-control ${
                        !isBoolean(cartAliasValid)
                          ? ""
                          : cartAliasValid
                          ? "is-valid"
                          : "is-invalid"
                      }`}
                      placeholder="Add unique cart name"
                      value={cart_alias}
                      onChange={(e) => {
                        this.setState({
                          cart_alias: e.target.value,
                        });
                      }}
                      onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
                    />
                  </div>
                </Card.Body>
              </Card>
              <Card className="card-custom mt-4">
                <Card.Title className="pb-0 mb-0">
                  <div className="d-flex flex-row flex-grow-1">
                    <h3 className="d-flex ml-8 mt-8 font-weight-bolder align-items-center ">
                      Cart Details
                      <span className="mr-1 font-size-sm font-weight-lighter">
                        &nbsp;(optional)
                      </span>
                    </h3>
                  </div>
                </Card.Title>
                <CreateCartForm
                  store={store}
                  id={this.props.id || ""}
                  api={this.api}
                  values={values}
                  onDetailsUpdate={(key, value) => {
                    const { values } = this.state;
                    if (key === "order_date" && values.order_date !== value) {
                      this.setState(
                        {
                          values: {
                            ...this.state.values,
                            [key]: value,
                          },
                        },
                        () => {
                          this.getData();
                        },
                      );
                    } else {
                      this.setState({
                        values: {
                          ...this.state.values,
                          [key]: key === "order_time" ? value.value : value,
                        },
                      });
                    }
                  }}
                />
              </Card>
              <Card className="card-custom mt-4" style={{ minHeight: "41%" }}>
                <CreateCartItems
                  items={items}
                  searchText={searchText}
                  menu={menu}
                  store={store}
                  isLoading={data_status === "fetching"}
                  onCartUpdate={(item, isAdd, extras) =>
                    this.handleAddToCart(item, isAdd, extras)
                  }
                  onAddItem={() =>
                    this.setState({ showAddModal: !showAddModal })
                  }
                />
              </Card>
            </Col>
            <Col sm={4}>
              <Card className="card-custom">
                <Card.Title className="pb-0 mb-0">
                  <div className="d-flex flex-column flex-grow-1">
                    <h3 className="ml-8 mt-8 font-weight-bolder">
                      <i
                        className="fas fa-cart-plus mr-2"
                        style={{ color: "#14C49B" }}
                      />
                      Cart Summary ({items.length} item
                      {items.length > 1 ? "s" : ""})
                    </h3>
                  </div>
                </Card.Title>
                <ExclusiveCartSummary
                  store={store}
                  items={items}
                  serviceCharge={service_charge}
                  onCartUpdate={(item, isAdd, extras) =>
                    this.handleAddToCart(item, isAdd, extras)
                  }
                  onQuantityUpdate={this.handleQuantityUpdate}
                />
              </Card>
              <Card className="card-custom mt-4">
                <CartValidity
                  exp_date={exp_date}
                  onDateChange={(date) =>
                    this.setState({
                      exp_date: moment(new Date(date)).format(DB_DATE_FORMAT),
                    })
                  }
                />
              </Card>
              <Card className="card-custom mt-4">
                <ServiceChargeContainer
                  serviceCharge={service_charge}
                  onToggleSwitch={(isSelected, type, value) => {
                    const new_charge = isSelected ? { type, value } : {};
                    this.setState({
                      service_charge: new_charge,
                    });
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Form>
        {showAddModal && (
          <ItemModal
            method="add"
            title={`Add Concierge Item`}
            show={showAddModal}
            onHide={() => {
              this.setState({ showAddModal: !showAddModal });
            }}
            actions={{
              onError: (error, maxFileSizeText = "200KB") => {
                if (error === "size-too-much") {
                  MySwal.fire({
                    icon: "error",
                    title: "Ooops!",
                    text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  });
                } else if (error === "unsupported-file-extension") {
                  MySwal.fire({
                    icon: "error",
                    title: "Ooops!",
                    text: `File type unsupported. Please upload PNG, JPG and JPEG files only.`,
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  });
                } else {
                  MySwal.fire({
                    icon: "error",
                    title: "Ooops!",
                    text: `Something went wrong`,
                    confirmButtonColor: PRIMARY_COLOR,
                    confirmButtonText: "OK",
                    showCancelButton: false,
                  });
                }
              },
            }}
            categories={store.categories.map((c) => c.category_name)}
            extras={store.extras}
            onSubmit={this.handleSubmitItem}
            uploadItemPhoto={this.handleItemPhotoUpload}
            noAvailability={false}
            origin={"normal"}
            store={store}
            maxFileSize="25000"
            maxFileSizeText="25MB"
            isConciergeItem={true}
            extraGroupErr={[]}
            role={"console_user"}
          />
        )}
        {!isEmpty(cart_link) && showLinkModal && (
          <Modal
            centered
            show={showLinkModal}
            onHide={() => {
              this.setState({
                showLinkModal: false,
                redirectTo: `/stores/${store._id}/create-concierge?cart_id=${cart._id}`,
                edit: true,
                status: "new",
              });
            }}
          >
            <Modal.Body className="font-weight-bolder text-center text-md">
              <Image src={qr_code} width={250} height={250} />
            </Modal.Body>
            <Modal.Footer className="justify-content-center w-100">
              <div className="d-flex flex-row">
                <Button
                  className="btn m-2"
                  onClick={() => {
                    this.handleOnCopyURL(cart_link);
                  }}
                >
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <i className="far fa-share-square"></i>
                    <div className="d-flex">COPY URL TO CLIPBOARD</div>
                  </div>
                </Button>
                <Button
                  className="btn m-2"
                  onClick={() => {
                    this.handleOnCopyImageToClipboard();
                  }}
                >
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <i className="far fa-share-square"></i>
                    <div className="d-flex">COPY QR TO CLIPBOARD</div>
                  </div>
                </Button>
                <Button
                  className="btn m-2"
                  onClick={() => this.handleOnDownloadImage()}
                >
                  <div
                    className="d-flex flex-row align-items-center"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <i className="fas fa-download"></i>
                    <div className="d-flex">DOWNLOAD QR CODE </div>
                  </div>
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </>
    );
  }
}
