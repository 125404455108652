import React from "react";
import { AnimateDiv, ProgressSM } from "../../components/Base";
import { Card, Button, Modal, Row, Col, Image } from "react-bootstrap";
import BrandWebsite from "../../partials/BrandWebsite";
import PageCenter from "../../components/PageCenter";
import TitleHelmet from "../../partials/TitleHelmet";
import { Redirect } from "react-router-dom";
import moment from "moment";
import qs from "qs";
import styled from "styled-components";

import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  ViberShareButton,
  ViberIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { setTheme } from "../../theme";
import axios from "axios";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TrackHomepage from "../../partials/branded-website/TrackHomepage";
import { S3_IMAGE_URI } from "../../constants";
import {
  generateTimeline,
  getTrackImage,
  getTrackStatusLabel,
} from "../../helpers/branded-website";
import { isEmpty } from "lodash";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";

const DELIVERY_FAILED_STATUSES = [
  "REJECTED",
  "EXPIRED",
  "CANCELED",
  "FAILED",
  "RETURNED",
  "NON_SYSTEM_HAIL",
];

const MySwal = withReactContent(Swal);
const POLLFREQUENCY = 5000;
const instance = axios.create({});
instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => Promise.reject(err),
);

export default function TrackPage(props) {
  const { token = "" } = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });
  return <MainComponent token={token} {...props} />;
}

class MainComponent extends BrandWebsite {
  constructor(props) {
    super(props);
    this.pollingInterval = null;
    this._isMounted = false;
  }
  async componentDidMount() {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("trackingPage");
    } else if (window !== window.parent) {
      window.parent.postMessage("trackingPage", "*");
    }
    this._isMounted = true;
    await this.getData(); // Call getData initially
    this.startPolling(); // Start polling for data
  }
  componentWillUnmount() {
    this._isMounted = false; // Set mounted flag to false
    this.stopPolling(); // Cleanup polling
  }
  async getData() {
    this.initiateData();
  }
  startPolling() {
    // Polling to fetch data every 5 seconds
    this.pollingInterval = setInterval(() => {
      if (this._isMounted) {
        this.getData();
      }
    }, POLLFREQUENCY); // Change this interval as needed
  }

  stopPolling() {
    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }
  }

  initiateData() {
    const { token = "" } = this.props,
      baseURL = this.api.getBaseURL(),
      { brand_url = "" } = this.state;
    // this._isMounted && this.setState({ data_status: "fetching" });
    instance
      .post(
        `/branded-website/brands/track`,
        { data: window.btoa(token) },
        {
          headers: { Authorization: `Basic ${window.btoa(brand_url)}` },
          baseURL,
        },
      )
      .then(async (data) => {
        const { order = {} } = data.data,
          { brand = {} } = order,
          { website_theme = "" } = brand;

        const state = {
          website_theme: setTheme(
            website_theme !== "" ? JSON.parse(website_theme) : {},
          ),
        };

        this._isMounted &&
          this.setState({
            renderModals: false,
            data: data.data,
            data_status: "fetched",
            ...state,
          });
        this.setTitle();
        const isNonDeliveryCompleted =
          order.order_type !== "delivery" &&
          (order.status === "completed" || order.status.includes("cancelled"));
        const isDeliveryCompleted =
          order.order_type === "delivery" &&
          ["COMPLETED"].includes(order.delivery.lastStatus);
        if (isNonDeliveryCompleted || isDeliveryCompleted) {
          this.stopPolling();
          return;
        }
      })
      .catch((error) => {
        console.log(error);
        this._isMounted && this.setState({ error: error, showSwalError: true });
        this.handleOnDataFetchError();
      });
  }

  handleOnDataFetchError = () => {
    const { error = {} } = this.state;
    const { data = {} } = error.response,
      { message = {} } = data;

    if (data && message.error === "InvalidOrder") {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Invalid order token!",
      }).then(() => {
        this.setState({ redirectTo: "/" });
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  setTitle = () => {
    const {
      data: {
        order: { transaction_number = "" },
      },
    } = this.state;
    if (window !== window.parent) {
      const messageBody = JSON.stringify({
        status: "trackingPage",
        orderNumber: transaction_number,
      });
      window.parent.postMessage(messageBody, "*");
    }
    this.setState({ title: "Track Order #" + transaction_number });
  };

  messageHandler() {
    this.initiateData();
  }

  handleOnDataFetched2 = () => {
    navigator.serviceWorker.addEventListener("message", (message) =>
      this.messageHandler(message),
    );
    let { layout_props = {}, data = {} } = this.state;
    layout_props.showMenuItems = false;

    this.setState({ layout_props, data });
  };

  onCopyToClipboardSuccess = () => {
    this.setState({ onCopyToClipboardSuccessMessage: "Copied!" });
    setTimeout(() => {
      this.setState({ onCopyToClipboardSuccessMessage: "" });
    }, 5000);
  };

  handleCustomerArrived = async (order_id) => {
    const baseURL = this.api.getBaseURL();

    MySwal.fire({
      icon: "warning",
      title: `Confirm Arrival for Pickup`,
      text: `This will alert the our team that you have arrived for your curbside pickup. Continue?`,
      confirmButtonText: "Confirm",
      showCancelButton: true,
      cancelButtonText: "Abort",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        return await instance
          .get(
            `/branded-website/orders/${order_id}/curbside-customer-arrived`,
            { baseURL },
          )
          .then((data) => {
            if (data) console.log("success");
            else {
              MySwal.fire({
                icon: "error",
                title: "Error!",
                text: `Failed to confirm arrival`,
                showConfirmButton: true,
              });
            }
          });
      },
      allowOutsideClick: () => !MySwal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have alerted the merchant of your arrival",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    });
  };

  render = () => {
    const {
        data = {},
        data_status = "",
        showHomepage = true,
        showOrderDetailsPage = false,
        showMealPlanSchedulePage = false,
        showShareModal = false,
        onCopyToClipboardSuccessMessage = "",
        website_theme = {},
      } = this.state,
      isLoading = data_status !== "fetched";

    const actions = {
      onClickShowOrderDetailsPage: () => {
        this.setState({
          showHomepage: false,
          showOrderDetailsPage: true,
          showTimeline: false,
          showMealPlanSchedulePage: false,
        });
      },
      onClickBackToHome: () => {
        this.setState({
          showHomepage: true,
          showOrderDetailsPage: false,
          showTimeline: false,
          showMealPlanSchedulePage: false,
        });
      },
      onClickShare: () => {
        this.setState({ showShareModal: true });
      },
      onClickShowMealPlanSchedulePage: () => {
        this.setState({
          showHomepage: false,
          showOrderDetailsPage: false,
          showTimeline: false,
          showMealPlanSchedulePage: true,
        });
      },
      setRedirectTo: (redirectTo) => {
        this.setState({ redirectTo });
      },
    };

    const shareProps = { url: window.location.href },
      shareIconProps = { round: true, size: 30 };

    const { highlighted_text_color = "" } = website_theme;

    const isDeliveryOnGoing =
      !isEmpty(data.order) &&
      ["ready", "completed"].indexOf(data.order.status) > -1 &&
      !isEmpty(data.delivery_data) &&
      data.delivery_data.status === "PICKED_UP";

    const is_order_cancelled =
      !isEmpty(data.order) &&
      (data.order.status.indexOf("cancelled") !== -1 ||
        data.order.status.includes("refund"));

    const progress = !isEmpty(data) && generateTimeline(data);

    const ColoredProgress = styled.span`
      .bg-custom-o-50 {
        background-color: #ecf0f3;
      }

      .bg-custom {
        background-color: #15c49a;
      }
    `;
    const buttonStyle = {
      // backgroundColor: button_color,
      // borderColor: button_color,
      // color: button_text_color,
      backgroundColor: "#15C49A",
      borderColor: "#15C49A",
      width: "70%",
      fontSize: "1rem",
    };

    return (
      <>
        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push={true} />
        ) : (
          <></>
        )}

        <TitleHelmet title={this.state.title} showWebsiteName={false} />
        {isLoading ? (
          <LayoutSplashScreen />
        ) : (
          <PageCenter>
            <AnimateDiv>
              <Card
                style={{
                  borderRadius: 15,
                  boxShadow: "0px 3px 12px #00000029",
                  border: "none",
                  width: KTUtil.isMobileDevice() ? "28rem" : "40rem",
                  height: "auto",
                }}
              >
                <div
                  style={{
                    // backgroundColor: highlighted_text_color,
                    backgroundColor: "#15C49A",
                    height: 13,
                    borderRadius: "20px 20px 0 0",
                  }}
                />
                <Card.Body className="p-0">
                  {showHomepage && (
                    <>
                      {" "}
                      <div className="font-weight-bold font-pickup-sm-2 text-dark-75 mt-8 mb-4 text-center">
                        {data.order.brand.name} - {data.order.store.name}
                      </div>
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          height:
                            KTUtil.isInResponsiveRange(
                              "minimal-desktop-and-below",
                            ) && !KTUtil.isInResponsiveRange("tablet")
                              ? "auto"
                              : "30vh",
                        }}
                      >
                        <Image
                          src={`${S3_IMAGE_URI}${getTrackImage(
                            data,
                            isDeliveryOnGoing,
                          )}.gif`}
                          alt="talkie"
                          fluid
                          style={{ objectFit: "contain", maxHeight: 250 }}
                          width={
                            isDeliveryOnGoing
                              ? "100%"
                              : data.order.status !== "ready"
                              ? 300
                              : 250
                          }
                        />
                      </div>
                    </>
                  )}
                  <div
                    style={{
                      display:
                        showMealPlanSchedulePage || showOrderDetailsPage
                          ? "none"
                          : "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div className="text-center w-100">
                      {!is_order_cancelled && (
                        <Row className="m-0 px-2 flex-nowrap justify-content-space-evenly">
                          {progress.map(({ status = "" }, index) => {
                            return (
                              <div
                                key={index}
                                className="d-flex flex-column align-items-center px-4 px-md-2"
                                style={{
                                  width: KTUtil.isMobileDevice() ? 55 : "6rem",
                                  padding: "0 10px",
                                }}
                              >
                                <ColoredProgress>
                                  <ProgressSM
                                    status={status}
                                    variant="custom"
                                  />
                                </ColoredProgress>
                                <div className="track-status-label">
                                  {getTrackStatusLabel(index, "", data.order)}
                                </div>
                              </div>
                            );
                          })}
                        </Row>
                      )}
                    </div>
                  </div>
                </Card.Body>
                <Card.Body
                  style={{
                    padding:
                      showMealPlanSchedulePage || showOrderDetailsPage
                        ? 20
                        : "0.25rem",
                  }}
                >
                  <TrackHomepage
                    handleCustomerArrived={this.handleCustomerArrived}
                    data={data}
                    actions={actions}
                    website_theme={website_theme}
                    showOrderDetailsPage={showOrderDetailsPage}
                    showMealPlanSchedulePage={showMealPlanSchedulePage}
                  />
                </Card.Body>
                <Card.Footer
                  className="border-0 justify-content-center align-items-center flex-column"
                  style={{
                    borderRadius: 15,
                    display:
                      showMealPlanSchedulePage || showOrderDetailsPage
                        ? "none"
                        : "flex",
                    padding: "10px 0 20px 0",
                  }}
                >
                  {!showOrderDetailsPage && (
                    <Button
                      style={buttonStyle}
                      className="btn-block font-weight-bold"
                      onClick={actions.onClickShowOrderDetailsPage}
                    >
                      View order details
                    </Button>
                  )}
                  {!isEmpty(data.order) &&
                    data.order.isMealPlan &&
                    !showMealPlanSchedulePage && (
                      <Button
                        style={buttonStyle}
                        className="btn-block font-weight-bold"
                        onClick={actions.onClickShowMealPlanSchedulePage}
                      >
                        View meal plan schedule
                      </Button>
                    )}
                </Card.Footer>
              </Card>
            </AnimateDiv>
            <Modal
              show={showShareModal}
              onHide={() => {
                this.setState({ showShareModal: false });
              }}
              centered
              size="sm"
            >
              <Modal.Header>
                <Modal.Title>Share via</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-primary text-center mb-5">
                  {onCopyToClipboardSuccessMessage !== ""
                    ? onCopyToClipboardSuccessMessage
                    : ""}
                </div>
                <div className="d-flex justify-content-space-evenly">
                  <div className="text-center">
                    <WhatsappShareButton {...shareProps}>
                      <WhatsappIcon {...shareIconProps} />
                    </WhatsappShareButton>
                    <div className="track-status-label">WhatsApp</div>
                  </div>

                  <div className="text-center">
                    <TelegramShareButton {...shareProps}>
                      <TelegramIcon {...shareIconProps} />
                    </TelegramShareButton>
                    <div className="track-status-label">Telegram</div>
                  </div>

                  <div className="text-center">
                    <ViberShareButton {...shareProps}>
                      <ViberIcon {...shareIconProps} />
                    </ViberShareButton>
                    <div className="track-status-label">Viber</div>
                  </div>

                  <div className="text-center">
                    <CopyToClipboard
                      text={shareProps.url}
                      onCopy={this.onCopyToClipboardSuccess}
                    >
                      <Button
                        variant="secondary"
                        className="btn-icon btn-circle"
                        style={{ height: "30px", width: "30px" }}
                      >
                        <i className="far fa-copy"></i>
                      </Button>
                    </CopyToClipboard>
                    <div className="track-status-label">Copy Link</div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </PageCenter>
        )}
      </>
    );
  };
}
