import React from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Label,
  OrderTimeRender,
  RequiredAsterisk,
  WarningAlert,
  InfoAlert,
} from "../../components/Base";
import ProSourceForm from "../../components/ProSourceForm";
import OrderTimeDropDown from "../branded-website/OrderTimeDropdown";
import DatePicker from "react-datepicker";
import moment from "moment";
import { DB_DATE_FORMAT } from "../../constants";
import core from "../../vendors/core-api";
import { GetSubmitClassList, toPesoAmount } from "../../helpers";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export default class ChangeOrderScheduleModal extends ProSourceForm {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        order_date: "",
        order_time: "",
        quotedDeliveryFee: 0,
      },
      errors: {},
      touched: {},
      isSubmitting: false,
      date_options: [],
      time_options: [],
      store_time_options: {},
      mountDatePicker: false,
      off_dates: [],
    };

    this.validate = {
      required: ["order_date", "order_time"],
    };

    this.api = core("pu").get();

    this.onClickOnChangeDate = this.onClickOnChangeDate.bind(this);
    this.checkAvailability = this.checkAvailability.bind(this);
  }

  componentDidMount() {
    const { data = {} } = this.props,
      { store = {} } = data;

    let days = [false, false, false, false, false, false, false];
    const closed_days = [];

    for (var i = 0; i < store.store_hours.length; i++) {
      const { day = "0", isOpen = false } = store.store_hours[i];
      if (isOpen) {
        days[parseInt(day)] = true;
      }
    }

    for (var i = 0; i < days.length; i++) {
      if (!days[i]) closed_days.push(i);
    }

    this.setState({
      closed_days,
      mountDatePicker: true,
      off_dates: store.off_dates,
    });
  }

  onClickOnChangeDate(date) {
    let { values = {} } = this.state;
    values.order_date = moment(date).format(DB_DATE_FORMAT);

    this.setState({ values, mountOrderTimeDropdown: false });
    setTimeout(() => {
      this.setState({ mountOrderTimeDropdown: true });
    }, 50);
  }

  checkAvailability() {
    const { values = {} } = this.state,
      { data = {} } = this.props,
      { _id = "" } = data;

    this.setState({
      isSubmitting: true,
      buttonText: "Checking availability...",
    });

    this.api
      .post({ url: `/orders/${_id}/change-schedule-check`, data: values })
      .then(({ data: response }) => {
        const {
          inventory_availability_changed = false,
          delivery_quotation_changed = false,
          delivery = {},
          item_unavailable_in_order = false,
        } = response;

        let state = {
          isSubmitting: false,
          buttonText: "Yes, I would like to proceed",
          showWarning: false,
        };

        if (data.isMealPlan) {
          values.quotedDeliveryFee = delivery.new;
          state.showWarning = true;
          state.warningText = (
            <>
              <b>Warning:</b> The delivery quotation for the selected date is{" "}
              <b>{toPesoAmount(delivery.new)}</b>, subjected to free delivery
              computation
            </>
          );
        } else {
          if (item_unavailable_in_order) {
            state.showWarning = true;
            state.warningText = (
              <>
                <b>Warning:</b> Some items in this order are not available for
                this date.
              </>
            );
          } else if (
            delivery_quotation_changed &&
            inventory_availability_changed
          ) {
            state.showWarning = true;
            state.warningText = (
              <>
                <b>Warning:</b> Some items in this order has reached the limit
                for this new time slot and the delivery quotation for this order
                has changed. Customer has been charged with{" "}
                <b>{toPesoAmount(delivery.old)}</b> and new quotation is{" "}
                <b>{toPesoAmount(delivery.new)}</b>.
              </>
            );
          } else if (inventory_availability_changed) {
            state.showWarning = true;
            state.warningText = (
              <>
                <b>Warning:</b> Some items in this order has reached the limit
                for this new time slot.
              </>
            );
          } else if (delivery_quotation_changed) {
            state.showWarning = true;
            state.warningText = (
              <>
                <b>Warning:</b> The delivery quotation for this order has
                changed. Customer has been charged with{" "}
                <b>{toPesoAmount(delivery.old)}</b> and new quotation is{" "}
                <b>{toPesoAmount(delivery.new)}</b>
              </>
            );
          } else {
            state.buttonText = "Save Changes";
          }
        }

        this.setState(values);
        this.setState(state);
      })
      .catch((err) => {
        console.log(err);
        MySwal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  }

  onChange = () => {
    const { values = {} } = this.state,
      { order_date = "", order_time = "" } = values;

    if (order_date !== "" && order_time !== "") {
      this.checkAvailability();
    }
  };

  onSubmit = (values, setSubmitting) => {
    this.props.onSubmit && this.props.onSubmit(values, setSubmitting);
  };

  render() {
    const { onHide, show, data = {} } = this.props,
      {
        values = {},
        errors = {},
        mountOrderTimeDropdown = true,
        isSubmitting = false,
        buttonText = "Save Changes",
        showWarning = false,
        warningText = <></>,
        mountDatePicker = false,
        closed_days = [],
        off_dates = [],
      } = this.state,
      {
        store: selected_store,
        brand: { brand_url = "" },
        transaction_number = "",
        order_type = "",
        isMealPlan = false,
      } = data;

    const inputActions = {
      onChange: this.handleOnChange,
      onBlur: this.handleOnBlur,
    };

    return (
      <Modal onHide={onHide} show={show} centered>
        <Modal.Header>
          <Modal.Title>Change order schedule #{transaction_number}</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Form onSubmit={this.handleOnSubmit}>
          <Modal.Body>
            <Row>
              <Col>
                <div
                  className="alert alert-custom alert-outline-dark fade show mb-5"
                  role="alert"
                >
                  <div className="alert-icon">
                    <i className="flaticon-warning-sign"></i>
                  </div>
                  <div className="alert-text">
                    You are updating this order that is currently scheduled on{" "}
                    <b>
                      <OrderTimeRender {...data} />
                    </b>
                    .
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mv-1rem">
              <Col sm={6}>
                <Form.Group>
                  <Label
                    text={
                      <>
                        <span className="text-capitalize">
                          new{" "}
                          {order_type === "delivery" ? "dispatch" : "pickup"}{" "}
                          date
                        </span>{" "}
                        <RequiredAsterisk />{" "}
                      </>
                    }
                  />
                  <div>
                    {mountDatePicker ? (
                      <DatePicker
                        minDate={new Date()}
                        maxDate={
                          new Date(
                            moment(new Date()).add("1", "months").format(),
                          )
                        }
                        selected={
                          values["order_date"] === ""
                            ? null
                            : new Date(values["order_date"])
                        }
                        className="form-control"
                        onChange={this.onClickOnChangeDate}
                        placeholderText={`Select ${
                          order_type === "delivery" ? "dispatch" : "pickup"
                        } date`}
                        wrapperClassName={"width-100"}
                        dateFormat={"iiii, MMM dd"}
                        filterDate={(date) => {
                          const isDateOrderDate =
                            data["order_date"] &&
                            moment(new Date(data["order_date"])).isSame(
                              date,
                              "day",
                            );

                          return data.store.is_accepting_in_advanced_orders ||
                            isMealPlan
                            ? !closed_days.includes(new Date(date).getDay()) &&
                                !off_dates.includes(
                                  moment(date).format(DB_DATE_FORMAT),
                                )
                            : isDateOrderDate
                            ? true
                            : false;
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Form.Group>
              </Col>
              <Col sm={6}>
                {mountOrderTimeDropdown ? (
                  <OrderTimeDropDown
                    brand_url={brand_url}
                    store={selected_store}
                    inputActions={inputActions}
                    order_date={values["order_date"]}
                    hasError={errors.hasOwnProperty("order_time")}
                    error={errors["order_time"]}
                    order_type={order_type}
                    value={values["order_time"]}
                    prepend_label={"new "}
                    no_asap={true}
                    isMealPlan={isMealPlan}
                  />
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            {!data.store.is_accepting_in_advanced_orders && !isMealPlan && (
              <InfoAlert
                message={
                  <>
                    Your store does not accept day-advanced orders, check your
                    store pre-order settings if this is a mistake.
                  </>
                }
              />
            )}
            <ShowWarning showWarning={showWarning} warningText={warningText} />
          </Modal.Body>
          <Modal.Footer>
            {showWarning ? (
              <Button
                type="submit"
                variant="danger"
                className={GetSubmitClassList(isSubmitting, "")}
                disabled={this.state.isSubmitting}
              >
                {buttonText}
              </Button>
            ) : (
              <Button
                type="submit"
                className={GetSubmitClassList(isSubmitting, "")}
                disabled={this.state.isSubmitting}
              >
                {buttonText}
              </Button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }
}

function ShowWarning({ showWarning, warningText }) {
  return showWarning ? (
    <WarningAlert message={<>{warningText} Would you like to proceed?</>} />
  ) : (
    <></>
  );
}
