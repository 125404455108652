import React from "react";
import moment from "moment";
import { useParams, Redirect } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import core from "../../../vendors/core-api";
import APIComponent from "../../../components/API";
import Datatable from "react-data-table-component";
import { DANGER_COLOR, PRIMARY_COLOR } from "../../../constants";
import { Button, Row } from "react-bootstrap";
import PacmanLoading from "../../../components/widgets/PacmanLoading/PacmanLoading";
import { SVGIcon } from "../../../components/Base";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { isEmpty, debounce } from "lodash";
import { capitalize } from "@material-ui/core";
import { eachDayOfInterval } from "date-fns";
import { InventoryModal } from "./InventoryModal";

const MySwal = withReactContent(Swal);

export default function Page(props) {
  const { store_id = "" } = useParams();
  return <MainComponent id={store_id} {...props} />;
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: true,
      redirectTo: "",
      brand_name: "Brands",
      store: {},
      groupName: "",
      title: "Events",
      page: 1,
      limit: 10,
      data: {
        docs: [],
      },
      totalRows: 0,
      isModalOpen: false,
      selectedEvent: {},
    };

    this.api = core("pu").get();
    this.data_url = `/store/${props.id}/event`;
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
  }

  async getData(search = "") {
    if (this.data_url !== "") {
      const { page = 1, limit = 10 } = this.state;
      this._isMounted && this.setState({ data_status: "fetching" });
      this.api &&
        this.api
          .get(this.data_url + `?page=${page}&limit=${limit}&search=${search}`)
          .then(({ data }) => {
            this.setState({
              store: data.store,
              data: data.events,
              page: data.events.page,
              limit: data.events.limit,
              totalRows: data.events.totalDocs,
              data_status: "fetched",
              groupName: data.store.brand.client.name,
            });
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }

  handleSearchInputChange = debounce(async (value) => {
    await this.getData(value);
  }, 1000);

  async onEventDelete(event_id) {
    const { store } = this.state;
    if (!isEmpty(event_id)) {
      this.api &&
        this.api
          .put(
            {
              url: `/store/${this.props.id}/delete-event/${event_id}`,
            },
            {
              headers: {
                Authorization: `Basic ${window.btoa(store.brand.brand_url)}`,
              },
              baseURL: this.api.getBaseURL(),
            },
          )
          .then(({ data }) => {
            if (data) {
              MySwal.fire({
                icon: "success",
                title: "Success!",
                text: "Successfully deleted event!",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result && result.isConfirmed) {
                  this.getData();
                }
              });
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    }
  }

  render() {
    const {
      data_status,
      data,
      redirectTo,
      store,
      groupName,
      totalRows,
      page,
      limit,
      isModalOpen,
      selectedEvent,
    } = this.state;
    return (
      <>
        {redirectTo !== "" ? <Redirect to={redirectTo} /> : <></>}
        <div className="row">
          <div className="col-md-9 col-sm-12">
            <Breadcrumbs>
              <Breadcrumbs.Item text={groupName} />
              <Breadcrumbs.Item text="Events" link={`/events`} />
              <Breadcrumbs.Item text={store.name} link="#!" active={"true"} />
            </Breadcrumbs>
          </div>
        </div>
        <Row className="justify-content-space-between align-items-center px-8 py-6">
          <Row className="m-0">
            <h1>Events</h1>
          </Row>
          <Row className="justify-content-end">
            <div className="input-group mr-4" style={{ width: "50%" }}>
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="flaticon-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => {
                  this.handleSearchInputChange(e.target.value);
                }}
              />
            </div>
            <Button
              style={{ top: 0, right: 0 }}
              onClick={() =>
                this.props.history.push(`/stores/${this.props.id}/create-event`)
              }
            >
              + Create New Event
            </Button>
          </Row>
        </Row>
        <div className="d-flex flex-column">
          <div style={{ overflowX: "auto" }}>
            <div>
              <Datatable
                pagination
                persistTableHead
                paginationServer
                progressPending={data_status === "fetching"}
                data={data.docs}
                highlightOnHover
                paginationTotalRows={totalRows}
                paginationPerPage={limit}
                paginationDefaultPage={page}
                onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
                  this.setState(
                    { limit: currentRowsPerPage, page: currentPage },
                    () => this.getData(),
                  );
                }}
                onChangePage={(page, totalRows) => {
                  this.setState({ page, totalRows }, () => this.getData());
                }}
                progressComponent={
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <PacmanLoading removeBackgroundColor />
                  </div>
                }
                columns={[
                  {
                    name: "Event Name",
                    sortable: true,
                    selector: (row) => {
                      return row.name;
                    },
                  },
                  {
                    name: "Location",
                    center: true,
                    sortable: true,
                    style: "font-weight-bold",
                    selector: (row) => {
                      return row.location_address.split(", ")[0];
                    },
                  },
                  {
                    name: "Event Date & Time",
                    center: true,
                    selector: (row) => {
                      return moment(row.start_date).format(
                        "MMM D, YYYY hh:mm A",
                      );
                    },
                  },
                  {
                    name: "Status",
                    center: true,
                    selector: (row) => {
                      return capitalize(row.status);
                    },
                    format: (row) => {
                      return (
                        <span
                          style={{ width: 70 }}
                          className={`badge badge-pill text-capitalize badge-${
                            row.status === "private" ? "light" : "primary"
                          }`}
                        >
                          {capitalize(row.status)}
                        </span>
                      );
                    },
                    sortable: true,
                  },
                  {
                    name: "Inventory",
                    center: true,
                    sortable: false,
                    selector: (row) => {
                      return `${row.inventory} / ${
                        row.capacity === 0
                          ? "-"
                          : row.capacity *
                            eachDayOfInterval({
                              start: new Date(row.start_date),
                              end: new Date(row.end_date),
                            }).length
                      }`;
                    },
                    format: (row) => {
                      return (
                        <Button
                          variant="link"
                          className="btn-icon mx-4"
                          onClick={() => {
                            this.setState({
                              isModalOpen: true,
                              selectedEvent: row,
                            });
                          }}
                          disabled={row.inventory === 0}
                        >
                          {`${row.inventory} / ${
                            row.capacity === 0
                              ? "-"
                              : row.capacity *
                                eachDayOfInterval({
                                  start: new Date(row.start_date),
                                  end: new Date(row.end_date),
                                }).length
                          }`}{" "}
                        </Button>
                      );
                    },
                  },
                  {
                    name: "Date Created",
                    center: true,
                    selector: (row) => {
                      return moment(row.createdAt).format(
                        "MMM D, YYYY hh:mm A",
                      );
                    },
                    sortable: true,
                  },
                  {
                    name: "Date Updated",
                    center: true,
                    selector: (row) => {
                      return moment(row.updatedAt).format(
                        "MMM D, YYYY hh:mm A",
                      );
                    },
                    sortable: true,
                  },
                  {
                    name: "Actions",
                    center: true,

                    selector: (row) => {
                      return row.status;
                    },
                    format: (row) => {
                      return (
                        <div>
                          <Button
                            style={{ marginTop: 5, marginRight: -5 }}
                            variant="link"
                            className="btn-icon btn-sm"
                            onClick={() => {
                              this.setState({
                                redirectTo: `/stores/${store._id}/event/${row._id}`,
                              });
                            }}
                          >
                            <SVGIcon icon={"Design/Edit"} title={"Edit Cart"} />
                          </Button>

                          <Button
                            variant="link"
                            className="btn-icon btn-sm"
                            onClick={() => {
                              MySwal.fire({
                                icon: "warning",
                                title: "Warning!",
                                text: "Are you sure you want to delete this event?",
                                showCancelButton: true,
                                confirmButtonColor: DANGER_COLOR,
                                confirmButtonText: "Delete",
                              }).then((result) => {
                                if (result && result.isConfirmed) {
                                  this.onEventDelete(row._id);
                                }
                              });
                            }}
                          >
                            <SVGIcon
                              icon={"General/Trash"}
                              title={"Delete Cart"}
                            />
                          </Button>
                        </div>
                      );
                    },
                  },
                ]}
                noHeader
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <InventoryModal
            show={isModalOpen}
            event={selectedEvent}
            api={this.api}
            handleError={this.handleOnDataFetchError}
            onHide={() => this.setState({ isModalOpen: false })}
          />
        )}
      </>
    );
  }
}
