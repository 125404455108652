/**
 * React Image Uploader [JD]
 * - Currently adopts Metronic theme scss 
 * - Sample: 
    <ImageUpload
        onError={(err) => { ... }}
        onChange={(file, preview) => { ... }}
        onRemove={() => { ... }}
        removeText={"Remove"}
        name={"img_upload"}
    />
*/

import React from "react";
import { Button } from "react-bootstrap";
import Resizer from "react-image-file-resizer";
import { PRIMARY_COLOR } from "../constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { isEmpty } from "lodash";

var browserImageSize = require("browser-image-size");

const MySwal = withReactContent(Swal);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const isSizeTooMuch = (file, maxFileSize) => {
  return file.size / 1000 > maxFileSize;
};

export default class ImageUpload extends React.Component {
  constructor(props) {
    super(props);

    const { removeText = "Remove", name = "img_upload" } = this.props;

    this.state = {
      file: null,
      fileName: "",
      mountFile: true,
      preview: "",
      removeText,
      name,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClickUpload = this.handleOnClickUpload.bind(this);
    this.handleOnClickRemove = this.handleOnClickRemove.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.resizeFile = this.resizeFile.bind(this);
    this.dataURItoBlob = this.dataURItoBlob.bind(this);

    this.fileUploadRef = React.createRef();
  }

  handleOnClickUpload = (event) => {
    event.preventDefault();

    this.setState({ mountFile: false });

    setTimeout(() => {
      this.setState({ mountFile: true });
      this.fileUploadRef.current.click();
    }, 50);
  };

  resizeFile = (file, maxWidth, maxHeight, callback) => {
    Resizer.imageFileResizer(
      file,
      maxWidth, // maxWidth
      maxHeight, // maxHeight
      "JPEG",
      75, // quality
      0,
      (uri) => {
        return callback(null, uri);
      },
      "base64",
    );
  };

  dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0)
      byteString = atob(dataURI.split(",")[1]);
    else byteString = unescape(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  handleOnChange = (event) => {
    const file = event.target.files[0];

    const { type = "" } = file,
      {
        useResize = false,
        maxFileSize = 200,
        maxFileSizeText = "200KB",
      } = this.props;

    if (["image/jpeg", "image/png", "image/gif"].indexOf(type) !== -1) {
      if (useResize) {
        if (file.size / 1000 > 100) {
          // Resize
          browserImageSize(file).then((size) => {
            let { height, width } = size;
            height = Math.floor(height / 2);
            width = Math.floor(width / 2);

            this.resizeFile(file, width, height, (err, data) => {
              if (err) console.log(err);
              else {
                const newBlob = this.dataURItoBlob(data);
                const newFile = new File([newBlob], file.name, {
                  type: file.type,
                });
                toBase64(newFile)
                  .then((preview) => {
                    this.setState({
                      file: newFile,
                      preview,
                      fileName: file.name,
                    });
                    this.props.onChange &&
                      this.props.onChange(newFile, preview);
                  })
                  .catch((err) => {
                    this.handleOnError(err);
                  });
              }
            });
          });
        } else {
          toBase64(file)
            .then((preview) => {
              this.setState({ file, preview, fileName: file.name });
              this.props.onChange && this.props.onChange(file, preview);
            })
            .catch((err) => {
              this.handleOnError(err);
            });
        }
      } else {
        if (!isSizeTooMuch(file, maxFileSize)) {
          toBase64(file)
            .then((preview) => {
              this.setState({ file, preview, fileName: file.name });
              this.props.onChange && this.props.onChange(file, preview);
            })
            .catch((err) => {
              this.handleOnError(err);
            });
        } else {
          this.handleOnError("size-too-much", maxFileSizeText);
        }
      }
    } else {
      this.handleOnError("unsupported-file-extension");
    }
  };

  handleOnClickRemove = () => {
    this.setState({ preview: "", file: null });
    this.props.onRemove && this.props.onRemove();
  };

  handleOnError = (err, maxFileSizeText) => {
    this.onError && this.onError(err, maxFileSizeText);
  };

  onError = (error, maxFileSizeText) => {
    if (error === "size-too-much") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File is too big! Maximum upload size is ${maxFileSizeText}.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else if (error === "unsupported-file-extension") {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `File type unsupported. Please upload PNG, JPG, JPEG, and GIF files only.`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    } else {
      MySwal.fire({
        icon: "error",
        title: "Ooops!",
        text: `Something went wrong`,
        confirmButtonColor: PRIMARY_COLOR,
        confirmButtonText: "OK",
        showCancelButton: false,
      });
    }
  };

  render() {
    const {
      upload_icon = "flaticon-photo-camera",
      showUploadIcon = true,
      showUploadButton = false,
      custom_size = {},
    } = this.props;

    return (
      <>
        <div>
          <div className="d-none">
            {this.state.mountFile ? (
              <input
                type="file"
                name={this.state.name}
                ref={this.fileUploadRef}
                onChange={this.handleOnChange}
                accept="image/*"
              />
            ) : (
              <> </>
            )}
          </div>

          {
            this.state.preview === "" && showUploadIcon ? (
              <div className="animate__animated animate__fadeIn">
                <div
                  className={`symbol cursor-pointer ${
                    isEmpty(custom_size) ? "symbol-150" : "w-100"
                  }`}
                  onClick={this.handleOnClickUpload}
                >
                  <span
                    className="symbol-label font-size-h5"
                    style={custom_size}
                  >
                    <i className={upload_icon} />
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )
            // Todo: Add previews thru props.
            // <div className="animate__animated animate__fadeIn">
            //     <div>
            //         <img alt={this.state.fileName} src={this.state.preview} className="display-brand-logo"/>
            //     </div>
            //     <a href="#" className="kt-link" onClick={this.handleOnClickRemove}>{this.state.removeText}</a>
            // </div>
          }

          {showUploadButton ? (
            <Button
              variant="primary"
              onClick={this.handleOnClickUpload}
              className="btn-sm"
            >
              Upload
            </Button>
          ) : (
            <></>
          )}

          {this.props.label && this.props.label}
        </div>
      </>
    );
  }
}
