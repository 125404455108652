import React from "react";

// import { SVGIcon } from "../../components/Base";
import StoreListItem from "../../partials/StoreListItem";
import BrandWebsite from "../../partials/BrandWebsite";
import core from "../../vendors/core-api";
import APIComponent from "../../components/API";
// import {
//   IsDeliveryAvailable,
//   isCurbsideAvailable,
//   isMealPlanAvailable,
//   isPickupAvailable,
// } from "../../components/Base";
import { checkIfStoreOpenForOrders, getResizedImage } from "../../helpers";
import styled from "styled-components";
import { Button, Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import DemoBrand from "../../partials/branded-website/DemoBrand/index";
import Group from "../../partials/branded-website/Group/index";
import AnnouncementModal from "../../partials/branded-website/modals/Announcement";
import OrderDetailsModalNew from "../../partials/branded-website/OrderDetailsModalNew";
import { isEmpty } from "lodash";

export function getStyle(font_type = "normal", font_color = "") {
  let style = { fontSize: "30px", fontWeight: 600, color: font_color };

  if (font_type === "small") style.fontSize = "20px";
  else if (font_type === "large") style.fontSize = "40px";

  return style;
}

function checkStores(stores = []) {
  let isStoreOpenForOrders = false;

  for (var i = 0; i < stores.length; i++) {
    if (checkIfStoreOpenForOrders(stores[i])) {
      isStoreOpenForOrders = true;
      break;
    }
  }
  return isStoreOpenForOrders;
}

export default function Homepage(props) {
  const { store_url = "" } = useParams();
  const params = new URLSearchParams(window.location.search);
  const menu_version_default = "1";
  return (
    <MainComponent
      store_url={store_url}
      menu_version={params.get("menu_version") || menu_version_default}
      {...props}
      ui={params.get("ui")}
    />
  );
}

class MainComponent extends BrandWebsite {
  handleOnDataFetched = () => {
    let {
      data: { _id = "", website_bg = "", stores = [], website_content = "" },
      layout_props = {},
    } = this.state;

    const selected_store = stores.length === 1 ? stores[0] : {};
    layout_props.showFooter = false;
    layout_props.hideHeader = true;

    layout_props.kt_content_style = {
      padding: 0,
    };

    layout_props.container_style = {
      padding: 0,
      maxWidth: "100%",
    };

    // if(website_bg !== "")
    //     image_div_style = {
    //         backgroundImage: `url(${website_bg})`,
    //         backgroundPosition: 'center',
    //         backgroundSize: `cover`,
    //         boxShadow: `inset 0 0 0 100vw rgba(0,0,0,0.5)`
    //     };

    this.setState({
      layout_props,
      hasWebsiteBackground: website_bg !== "",
      storage_key: `${_id}_CART`,
      selected_store,
      website_content:
        website_content !== "" && website_content !== null
          ? JSON.parse(website_content)
          : {},
      showSplashScreen: false,
      isHomepage: true,
      isBannerClosed: false,
    });

    this.setTitle();
    this.handleOnSubmitOrderDetailsForm =
      this.handleOnSubmitOrderDetailsForm.bind(this);
  };

  renderContent = () => {
    const {
        data = {},
        cart = {},
        brand_url = "",
        store_id = "" || data.stores[0]._id,
        website_content = {},
        website_theme = {},
        website_type = "",
        // order_subtype = "",
        // order_type = "",
      } = this.state,
      { website_bg = "", stores = [], date_updated, date_created } = data,
      selected_store = stores.length > 0 ? stores[0] : {};
    let { isBannerClosed } = this.state;
    const actions = {
      // handleOnClickPickup: () => {
      //   this.setState({ showPickupDetailsModal: true });
      //   this.setState({ order_subtype: "pickup" });
      // },
      // handleOnClickCurbsidePickup: async () => {
      //   this.setState({ showPickupDetailsModal: true });
      //   this.setState({ order_subtype: "curbside_pickup" });
      //   // this.setState({ cart: { order_type: "curbside_pickup" } })
      //   // console.log('homepage state: ', this.state)
      //   // console.log('homepage props: ', this.props)
      //   // console.log('homepage showPickupDetailsModal: ', this.state.showPickupDetailsModal)
      //   // console.log('homepage order_subtype: ', order_subtype)
      //   // console.log('homepage state.order_subtype: ', this.state.order_subtype)
      // },
      // handleOnClickMealPlan: async () => {
      //   this.setState({ showDeliveryDetailsModal: true });
      //   this.setState({ order_subtype: "meal_plan" });
      // },
      // handleOnClickDelivery: () => {
      //   this.setState({ showDeliveryDetailsModal: true });
      //   this.setState({ order_subtype: "delivery" });
      // },

      setSelectedStore: (selected_store) => {
        this.setState({ selected_store });
        this.initCurrentAvailableCounters();
      },
      handleBanner: () => {
        localStorage.setItem("isConsentBanner", true);
        this.setState({ isBannerClosed: true });
      },
    };

    const isStoreWebsite = website_type === "store";

    const {
        text_color = "",
        highlighted_text_color = "",
        homepage_highlighted_text_color = "",
        background_color = "",
        link_text_color = "",
        homepage_icon_color = "",
        homepage_button_color = "",
        homepage_button_text_color = "",
        homepage_text_color = "",
      } = website_theme,
      {
        brand_logo_orientation = "",
        store_title_alignment = "hidden",
        store_title_font_size = "normal",
        store_title_text = "",
        store_title_font_colour = "",
      } = website_content;

    const HighlightedText = styled.b`
      color: ${homepage_highlighted_text_color !== ""
        ? homepage_highlighted_text_color
        : highlighted_text_color};
    `;

    const isStoreOpenForOrders = checkStores(stores);

    const large_image = getResizedImage(
      website_bg,
      "large",
      date_updated || date_created,
    );
    const buttonStyle = {
      backgroundColor: homepage_button_color,
      borderColor: homepage_button_color,
      color:
        homepage_button_text_color !== ""
          ? homepage_button_text_color
          : homepage_text_color,
    };
    // console.log(data);
    // console.log("homepage", this.state);
    const homepage_logo = data.group_logo ? data.group_logo : data.brand_logo;

    return (
      <>
        <div
          className="css-qwibvh"
          style={{
            color:
              homepage_text_color !== "" ? homepage_text_color : text_color,
          }}
        >
          {website_type === "brand" ? (
            <AnnouncementModal
              data_url={`/announcements/brand/${data._id}`}
              buttonStyle={buttonStyle}
            />
          ) : (
            <AnnouncementModal
              data_url={`/announcements/store/${
                store_id !== "" ? store_id : data.stores[0]._id
              }`}
              buttonStyle={buttonStyle}
            />
          )}

          <div className="css-1ucwpv3">
            <section
              className="css-luxby8"
              style={{ backgroundColor: background_color }}
            >
              {!isBannerClosed && !localStorage.getItem("isConsentBanner") ? (
                <div
                  className="alert alert-dismissible bg-light-secondary d-flex flex-center flex-column px-10"
                  style={{ position: "relative", fontSize: "11px" }}
                >
                  <div className="text-center">
                    <div className="text-dark">
                      By using our site you are consenting to the use of cookies
                      as described in our{" "}
                      <a
                        style={{ color: website_theme.link_text_color }}
                        href="https://www.pickup.ph/privacy-policy"
                        className="fw-bolder me-1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        privacy policy.
                      </a>
                      <br />
                      You can change your cookie settings at anytime, but this
                      may impact your experience on our site.
                    </div>
                    <div className="d-flex flex-center flex-wrap">
                      <Button
                        style={buttonStyle}
                        className="btn mr-5 mt-5"
                        onClick={actions.handleBanner}
                      >
                        Ok, I got it
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {brand_logo_orientation !== "hidden" &&
              !isEmpty(homepage_logo) ? (
                <div className={`text-${brand_logo_orientation}`}>
                  <img
                    src={homepage_logo}
                    alt={data.name}
                    className="homepage-logo mr-2"
                  />
                </div>
              ) : (
                <></>
              )}

              {store_title_text !== "" ? (
                <div
                  className={`text-${store_title_alignment} py-5`}
                  style={getStyle(
                    store_title_font_size,
                    store_title_font_colour,
                  )}
                >
                  {store_title_text}
                </div>
              ) : (
                <></>
              )}

              <div className="mv-1rem">{website_content.brand_intro}</div>

              {isStoreWebsite ? (
                <div style={{ margin: "1.5rem 0" }}>
                  <StoreListItem
                    store={selected_store}
                    store_hours_display={"branded-website-today"}
                    hideName={true}
                    custom_color={homepage_icon_color}
                    custom_link_color={link_text_color}
                  />
                </div>
              ) : (
                <></>
              )}

              <div className="css-1sh12zw">
                <div className="mv-1rem">
                  <HighlightedText>
                    {website_content.highlighted_text_1}
                  </HighlightedText>{" "}
                  {website_content.paragraph_1}
                </div>
                <div className="mv-1rem">
                  <HighlightedText>
                    {website_content.highlighted_text_2}
                  </HighlightedText>{" "}
                  {website_content.paragraph_2}
                </div>
                <div className="mv-1rem">
                  <HighlightedText>
                    {website_content.highlighted_text_3}
                  </HighlightedText>{" "}
                  {website_content.paragraph_3}
                </div>
                <div className="mv-1rem">
                  <HighlightedText>
                    {website_content.highlighted_text_4}
                  </HighlightedText>{" "}
                  {website_content.paragraph_4}
                </div>
              </div>

              {this.props.ui === "demo" ? (
                <DemoBrand data={data} />
              ) : data.brands !== undefined && data.website_type === "group" ? (
                <Group data={data} />
              ) : isStoreOpenForOrders ? (
                <>
                  <div className="font-weight-bolder">
                    {website_content.order_type_line}
                  </div>

                  <ButtonsDiv
                    data={data}
                    actions={actions}
                    button_color={homepage_button_color}
                    button_text_color={homepage_button_text_color}
                    store_id={store_id}
                    brand_url={brand_url}
                    cart={cart}
                    onSubmit={this.handleOnSubmitOrderDetailsForm}
                  />
                </>
              ) : (
                <div>
                  <Card>
                    <Card.Body style={{ padding: "0.5rem" }}>
                      <p style={{ color: "black" }}>
                        OH NO! THIS STORE IS CURRENTLY CLOSED AND WILL START
                        ACCEPTING ORDERS UPON REOPENING
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              )}

              <div className="mv-1rem font-weight-bolder">
                Powered by{" "}
                <a
                  href="https://www.pickup.ph"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="text-primary">Pickup.ph</span>
                </a>
              </div>
            </section>
          </div>
          <div className="css-1e4rxnh">
            <div
              data-testid="landingImage"
              className="css-10klw3m"
              style={{ height: "100%" }}
            >
              {website_bg !== "" ? (
                <img
                  src={large_image}
                  className="css-9cywm4"
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  alt="background"
                  onError={(ev) => {
                    ev.target.onError = null;
                    ev.target.src = website_bg;
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };
}

/**
 * is accepting preorders?
 * is marked as open?
 * is not within off dates?
 * is open 24 hours?
 * is within operating hours? ( with `minutes_allow_order_before_opening` consideration)
 */

// class LandingPage extends React.Component {
class ButtonsDiv extends APIComponent {
  constructor(props) {
    super(props);

    const { store_id = "", brand_url = "", cart = {} } = this.props,
      { order_date = "" } = cart;

    this.data_url =
      store_id !== "" && brand_url !== ""
        ? `/branded-website/brands/${brand_url}/stores/${store_id}/stocks?order_date=${order_date}`
        : "";

    this.api = core("pu").get();
  }

  handleOnDataFetched = () => {
    const { data = {} } = this.state;
    this.props.actions.setSelectedStore(data);
  };

  handleOrderDetailsModal = () => {
    this.setState({ showPickupDetailsModal: true });
  };

  render() {
    localStorage.setItem(
      "from",
      `/${window.location.href.split("://")[1].split("/")[1]}`,
    );

    const { data = {}, button_text_color = "", button_color = "" } = this.props;
    const { showPickupDetailsModal } = this.state;

    const buttonStyle = {
      backgroundColor: button_color,
      borderColor: button_color,
      color: button_text_color,
      padding: "1rem 1.2rem",
      fontSize: "1rem",
    };
    // console.log("homepage render stores: ", stores)
    return (
      <div data-testid="orderOptions" className="css-znampd mv-1rem">
        <OrderDetailsModalNew
          order_type="pickup"
          title="Order Details"
          brand={data}
          show={showPickupDetailsModal}
          onHide={() => {
            this.setState({ showPickupDetailsModal: false });
          }}
          onSubmit={this.props.onSubmit}
          brand_url={this.props.brand_url}
          website_theme={this.props.website_theme}
          buttonStyle={buttonStyle}
          order_subtype={this.state.order_subtype}
        />
        <Button
          style={buttonStyle}
          className="btn mr-5 mt-5"
          onClick={this.handleOrderDetailsModal}
        >
          <i
            style={{ color: button_text_color }}
            className="fas fa-shopping-bag"
          ></i>
          ORDER NOW
        </Button>
      </div>
    );
  }
}

// class OrderTypesForm extends ProSourceForm {
//   constructor(props) {
//     super(props);
//     const { store_id = "", brand_url = "", cart = {} } = this.props,
//       { order_date = "" } = cart;
//     this.data_url =
//       store_id !== "" && brand_url !== ""
//         ? `/branded-website/brands/${brand_url}/stores/${store_id}/stocks?order_date=${order_date}`
//         : "";
//     this.api = core("pu").get();
//   }
//   handleOnDataFetched = () => {
//     const { data = {} } = this.state;
//     this.props.actions.setSelectedStore(data);
//   };
//   render() {
//     localStorage.setItem(
//       "from",
//       `/${window.location.href.split("://")[1].split("/")[1]}`
//     );
//     const {
//         data = {},
//         button_text_color = "",
//         button_color = "",
//         actions = {},
//         onHide,
//         show = false,
//       } = this.props,
//       { stores = [] } = data;
//     const buttonStyle = {
//       backgroundColor: button_color,
//       borderColor: button_color,
//       color: button_text_color,
//     };
//     return (
//       <Modal show={show} onHide={onHide} centered>
//         <Modal.Header>
//           <Modal.Title>Order Details</Modal.Title>
//           <button
//             type="button"
//             className="close"
//             data-dismiss="modal"
//             aria-label="Close"
//             onClick={onHide}
//           >
//             <i aria-hidden="true" className="ki ki-close"></i>
//           </button>
//         </Modal.Header>
//         <Modal.Body>
//           <Form>
//             {IsDeliveryAvailable(stores) ? (
//               <Button
//                 style={buttonStyle}
//                 className="btn mr-5 mt-5"
//                 onClick={actions.handleOnClickDelivery}
//               >
//                 <i
//                   style={{ color: button_text_color }}
//                   className="fas fa-motorcycle"
//                 ></i>
//                 DELIVERY
//                 {/* 🛵 DELIVERY */}
//               </Button>
//             ) : (
//               <></>
//             )}
//             {isPickupAvailable(stores) && (
//               <>
//                 <Button
//                   style={buttonStyle}
//                   className="btn mr-5 mt-5"
//                   onClick={actions.handleOnClickPickup}
//                 >
//                   <i
//                     style={{ color: button_text_color }}
//                     className="fas fa-shopping-bag"
//                   ></i>
//                   CLICK AND COLLECT
//                   {/* 🛍️ CLICK AND COLLECT */}
//                 </Button>
//               </>
//             )}
//             {isCurbsideAvailable(stores) ? (
//               <>
//                 <Button
//                   style={buttonStyle}
//                   className="btn mr-5 mt-5"
//                   onClick={actions.handleOnClickCurbsidePickup}
//                 >
//                   <i
//                     style={{ color: button_text_color }}
//                     className="fas fa-car"
//                   ></i>
//                   CURBSIDE PICKUP
//                   {/* 🚗 CURBSIDE PICKUP */}
//                 </Button>
//               </>
//             ) : (
//               <></>
//             )}
//             {isMealPlanAvailable(stores) ? (
//               <>
//                 <Button
//                   style={buttonStyle}
//                   className="btn mr-5 mt-5"
//                   onClick={actions.handleOnClickMealPlan}
//                 >
//                   <i
//                     style={{ color: button_text_color }}
//                     className="far fa-calendar-check"
//                   ></i>
//                   MEAL PLAN
//                 </Button>
//               </>
//             ) : (
//               <></>
//             )}
//           </Form>
//         </Modal.Body>
//       </Modal>
//     );
//   }
// }
