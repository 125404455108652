import React from "react";

import APIComponent from "../../components/API";
import core from "../../vendors/core-api";
import { useParams, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen } from "../../../_metronic/layout";
import TitleHelmet from "../../partials/TitleHelmet";
import { KTUtil } from "../../../_metronic/_assets/js/components/util";

import { ReviewForms } from "../../partials/ReviewForms";
import {
  HeaderTextWithActions2,
  DFlex,
  IconAlert,
  ListItem,
  OrderTimeRender,
  OrderStatusActionButton,
  TextMuted,
  Section,
  InfoTooltip,
  DeliveryButtons,
} from "../../components/Base";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Card, Row, Col, Button, Modal } from "react-bootstrap";
import {
  PRIMARY_COLOR,
  DANGER_COLOR,
  ASAP_DEFAULT_PREP_TIME_OPTION,
  CANCELLED_DELIVERIES,
} from "../../constants";
import {
  fetchData,
  formatOrderType,
  formatAdditionalChargeName,
  formatAdditionalChargeType,
  computeOrderServiceCharge,
} from "../../helpers";
import Activities from "../../partials/Activities";
import DeliveryLogs from "../../partials/DeliveryLogs";
import {
  GetDataFromEvent,
  phoneNumberPrefix,
  getStatusDisplay,
  isOrderReadOnly,
  decryptPaymentDetails,
  toPesoAmount,
  computeTotalCartAmount,
  computeTotalCartItems,
  formatPromoType,
  isOrderRefunded,
} from "../../helpers";

import Swal from "sweetalert2";
import moment from "moment";
import withReactContent from "sweetalert2-react-content";
import CancelNotesModal from "../../partials/CancelNotesModal";
import SelectionModal from "../../components/widgets/SelectionModal";
import PacmanLoading from "../../components/widgets/PacmanLoading/PacmanLoading";

import "react-perfect-scrollbar/dist/css/styles.css";

import PerfectScrollbar from "react-perfect-scrollbar";
import AcceptOrderModal from "../../partials/AcceptOrderModal";
import DeliveryDetailsModal from "../../partials/DeliveryDetailsModal";
import RefundRequestModal from "../../partials/RefundRequestModal";
import ChangeOrderScheduleModal from "../../partials/orders/ChangeOrderScheduleModal";
import ChangeCustomerInfoModal from "../../partials/orders/ChangeCustomerInfoModal";

import MealPlanListModal from "../../partials/MealPlanListModal";
import { isEmpty, isNil, isUndefined } from "lodash";
import { Rating } from "@material-ui/lab";
import { ThumbUpAlt, ThumbDownAlt } from "@material-ui/icons";
import { UAParser } from "ua-parser-js";
const MySwal = withReactContent(Swal);

export default function Page(props) {
  const { order_id = "", brand_url = "", mode = "" } = useParams(),
    { user } = useSelector((state) => state.auth);
  return (
    <MainComponent
      {...props}
      id={order_id}
      brand_url={brand_url}
      user={user}
      webview={mode === "webview"}
    />
  );
}

class MainComponent extends APIComponent {
  constructor(props) {
    super(props);

    this.state = {
      showSplashScreen: false,
      redirectTo: "",
      title: "Orders",
      action: "",
      display_buttons: props.user.display_buttons,
      cancelDeliveryShow: true,
      showMealPlanListModal: false,
      mealPlanOrderData: [],
      inStoreOrderDetails: {},
      isSubmitting: false,
      isEditing: false,
      deliveryLogsUpdateKey: true,
      showTrackingModal: false,
      trackingUrl: "",
    };

    this.api = core("pu").get();
    this.data_url = "/orders/" + this.props.id;

    this.handleOnClickUpdateBtn = this.handleOnClickUpdateBtn.bind(this);
    // this.onSubmitCancelOrder = this.onSubmitCancelOrder.bind(this);
    this.execUpdateStatus = this.execUpdateStatus.bind(this);
    this.onClickReadyForPickupModal =
      this.onClickReadyForPickupModal.bind(this);
    this.onClickRefundButton = this.onClickRefundButton.bind(this);
    this.onSubmitOutForDelivery = this.onSubmitOutForDelivery.bind(this);
    this.execAcceptASAPOrder = this.execAcceptASAPOrder.bind(this);
    this.execHailRider = this.execHailRider.bind(this);
    this.execDeliveryRefresh = this.execDeliveryRefresh.bind(this);
    this.onSubmitUpdateSchedule = this.onSubmitUpdateSchedule.bind(this);
    this.onSubmitUpdateCustomer = this.onSubmitUpdateCustomer.bind(this);
    this.handleOpenTracking = this.handleOpenTracking.bind(this);
    this.handleCloseTrackingModal = this.handleCloseTrackingModal.bind(this);
  }

  getInStoreOrderDetails = async (orderId) => {
    try {
      const result = await fetchData(
        `get`,
        `/orders/${orderId}/in-store-order-details`,
      );
      const response = result.data;
      this.setState({ inStoreOrderDetails: response });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  getStoreRating = async (orderId) => {
    try {
      const result = await fetchData(`get`, `store-rating/order/${orderId}`);
      const response = result.data;
      this.setState({ storeRating: response });
      return response;
    } catch (err) {
      MySwal.fire({
        icon: "error",
        title: "Error!",
        text: "Error fetching store rating.",
        confirmButtonColor: DANGER_COLOR,
        confirmButtonText: "Back",
      });
    }
  };

  handleOnClickDeliveryRef = () => {
    const { data } = this.state;
    if (data.delivery) {
      this.api
        .post({
          url: `/branded-website/delivery/delivery_details`,
          data: {
            brand_id: data.brand._id,
            delivery_partner: data.delivery.delivery_partner,
            orderRef: data.delivery.orderRef,
            store_id: data.store._id,
          },
        })
        .then((deliveryData) => {
          if (data.delivery.delivery_partner === "lalamove") {
            if (deliveryData.data.tracking_link !== undefined) {
              this.handleOpenTracking(deliveryData.data.tracking_link);
            } else {
              MySwal.fire({
                icon: "info",
                title: "Tracking URL not found",
                text: "Lalamove is still searching for a rider",
              });
            }
          }
          if (data.delivery.delivery_partner === "mrspeedy") {
            this.handleOpenTracking(
              deliveryData.data.orders[0].points[1].tracking_url,
            );
          }
          if (data.delivery.delivery_partner === "grab") {
            if (deliveryData.data.trackingURL !== undefined) {
              this.handleOpenTracking(deliveryData.data.trackingURL);
            } else {
              MySwal.fire({
                icon: "info",
                title: "Tracking URL not yet ready",
                text: "Grab is still searching for a rider",
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handleOnDataFetched = () => {
    const { transaction_number = "" } = this.state.data;

    this.setState({ title: "Order #" + transaction_number });
  };

  handleError = (error) => {
    if (error.hasOwnProperty("response")) {
      const { data = {} } = error.response,
        { message = {} } = data;

      if (message.error === "TokenExpiredError") {
        window.location.href = "/logout";
      } else if (message.error === "UnauthorizedUserError") {
        window.location.href = "/error/error-v1";
      } else if (message.error === "OrderStatusNotSync") {
        MySwal.fire({
          icon: "error",
          title: "Order not synced",
          text: "Error on updating this order. Please refresh this page.",
        });
      } else {
        this.showErrorSwal();
        console.log(error);
      }
    } else {
      this.showErrorSwal();
      console.log(error);
    }
  };

  handleOnClickUpdateBtn = (e) => {
    e.preventDefault();
    const value = GetDataFromEvent(e, "data-value");
    this.setState({ updateBtnLoading: true });
    const submitting = (updateBtnLoading) => {
      this.setState({ updateBtnLoading });
    };
    if (value === "accept") {
      this.setState({ showAcceptAsapOrderModal: true });
    } else if (value === "reject") {
      this.setState({
        showNotesModal: true,
        action: "reject",
        updateBtnLoading: false,
      });
    } else if (value === "ready") {
      this.execUpdateStatus("ready", "", submitting);
    } else if (value === "ready_delivery") {
      // this.setState({ showReadyForDeliveryModal: true })
      this.execUpdateStatus("completed", "", submitting);
    } else if (
      value === "ready_pickup_no_show" ||
      value === "ready_curbside_pickup_no_show" ||
      value === "ready_third_party_pickup_no_show"
    ) {
      this.execUpdateStatus("cancelled_no_show", "", submitting);
    } else if (
      value === "ready_pickup" ||
      value === "ready_curbside_pickup" ||
      value === "ready_dine_in" ||
      value === "ready_third_party_pickup"
    ) {
      this.execUpdateStatus("completed", "", submitting);
    } else {
      console.log("value", value);
    }
  };

  handleOnClickMealPlan = async () => {
    let showMealPlanListModal,
      mealPlanOrderData = this.state;
    const order_id = this.props.id;
    showMealPlanListModal = true;
    this.setState({ showMealPlanListModal });

    let mealPlanOrders = await this.execFetchMealPlanItems(order_id);
    mealPlanOrderData = mealPlanOrders;
    this.setState({ mealPlanOrderData });
    // console.log("perorder handleOnClickMealPlan mealPlanOrders: ", mealPlanOrders)
  };

  handleOnCloseMealPlanListModal = () => {
    let showMealPlanListModal = this.state;
    showMealPlanListModal = false;
    this.setState({ showMealPlanListModal });
  };

  execFetchMealPlanItems = async (order_id) => {
    // const order_id = this.props.id
    // console.log("perorder execfetchmealplanitems order_id: ", order_id)
    const mealPlanOrders = await this.api.get(
      "/orders/" + order_id + "/meal-plan-orders",
    );
    // const mealPlanOrders = await this.api.get({
    //     url: "/orders/" + order_id + "/meal-plan-orders",
    // })
    return mealPlanOrders.data;
  };

  // onSubmitCancelOrder = ({ refund_type = "", notes = "" }) => {
  //     this.setState({ cancelBtnLoading: true });
  //     this.execUpdateStatus(refund_type, notes, (cancelBtnLoading) => { this.setState({ cancelBtnLoading, showNotesModal : false })});
  // }

  onClickReadyForPickupModal = (status = "") => {
    MySwal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: `You are about to set this order as ${
        status === "cancelled_no_show" ? "no show" : "completed"
      }.`,
      confirmButtonColor: PRIMARY_COLOR,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
      // "reverseButtons": true
    }).then((result) => {
      if (result.value) {
        this.execUpdateStatus(status);
      }
    });
  };

  onSubmitOutForDelivery = (values = {}) => {
    this.setState({ btnLoading: true });
    this.execUpdateStatus("completed", JSON.stringify(values), (btnLoading) => {
      this.setState({ btnLoading, showReadyForDeliveryModal: false });
    });
  };

  execUpdateStatus = (status = "", notes = "", setSubmitting = () => {}) => {
    const { data = {} } = this.state,
      { _id = "" } = data;

    this.api
      .put({
        url: "/orders/" + _id + "/status",
        data: { status, notes, current_status: data.status },
      })

      .then((response) => {
        if (response.data.isInsuffCredit) {
          MySwal.fire({
            icon: "warning",
            title: "Insufficient Funds",
            text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
            confirmButtonColor: PRIMARY_COLOR,
            confirmButtonText: "Ok",
          }).then(() => {
            this.setState({
              redirectTo: `/stores/${this.state.data.store._id}/orders`,
            });
          });
        } else {
          this.getData();
          MySwal.fire({
            icon: "success",
            title: "Success!",
            text: "You have successfully updated this order. Redirecting you to orders page.",
            timer: 4000,
            confirmButtonText: "OK",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonColor: PRIMARY_COLOR,
          }).then((result) => {
            if (result.dismiss) {
              const { dismiss } = result;
              if (dismiss === "cancel") {
                this.setState({ updateBtnLoading: false });
              } else {
                this.setState({
                  redirectTo: `/stores/${this.state.data.store._id}/orders`,
                });
              }
            } else {
              this.setState({
                redirectTo: `/stores/${this.state.data.store._id}/orders`,
              });
            }
          });
        }
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showCompletionSelectionModal: false });
      });
  };

  execAcceptASAPOrder = (
    minutes = ASAP_DEFAULT_PREP_TIME_OPTION,
    setSubmitting,
  ) => {
    const { data = {} } = this.state,
      { _id = "" } = data;

    this.api
      .put({
        url: "/orders/" + _id + "/status",
        data: {
          status: "accepted",
          is_asap: true,
          minutes,
          current_status: data.status,
        },
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated this order. Redirecting you to orders page.",
          timer: 4000,
          confirmButtonText: "OK",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonColor: PRIMARY_COLOR,
        }).then((result) => {
          this.getData();
          if (result.dismiss) {
            const { dismiss } = result;
            if (dismiss === "cancel") {
              this.setState({ updateBtnLoading: false });
            } else {
              this.setState({
                redirectTo: `/stores/${this.state.data.store._id}/orders`,
              });
            }
          } else {
            this.setState({
              redirectTo: `/stores/${this.state.data.store._id}/orders`,
            });
          }
        });
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showAcceptAsapOrderModal: false });
      });
  };

  onClickRefundButton = () => {
    this.setState({ showRefundRequestModal: true });
  };

  onSubmitRefundRequest = (values, setSubmitting) => {
    this.setState({ disableRefundButton: true });
    const { _id = "", status = "" } = this.state.data;

    values.current_status = status;

    if (status === "new") {
      values.order_status = "cancelled";
    }

    this.api
      .post({
        url: "/orders/" + _id + "/refunds",
        data: values,
      })
      .then(() => {
        this.getData();

        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have sent a refund request.",
          showConfirmButton: false,
          timer: 1000,
        });
      })
      .catch((err) => {
        this.handleError(err);
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({
          disableRefundButton: false,
          showRefundRequestModal: false,
          showNotesModal: false,
        });
      });
  };

  execCancelDelivery = async (order_id) => {
    let driverDetails;
    let messageText;
    let cancelErrorText;
    let cancelledPartners;
    let { cancelDeliveryShow } = this.state;
    try {
      const { delivery } = this.state.data;
      if (delivery) {
        console.log("cancel delivery data delivery: ", delivery);
        messageText = `This will cancel the ongoing delivery process for this order. `;
        if (delivery.driverDetails) {
          driverDetails = JSON.parse(delivery.driverDetails);
          if (delivery.delivery_partner === "lalamove") {
            if (driverDetails && driverDetails.name && driverDetails.phone) {
              messageText += `${`You may want to contact the assigned courier first - ${driverDetails.name} (${driverDetails.phone}). `}`;
            }
          } else if (delivery.delivery_partner === "grab") {
            if (
              driverDetails &&
              driverDetails.courier &&
              driverDetails.courier.name &&
              driverDetails.courier.phone
            ) {
              messageText += `${`You may want to contact the assigned courier first - ${driverDetails.courier.name} (${driverDetails.courier.phone}). `}`;
            }
          } else if (delivery.delivery_partner === "mrspeedy") {
            if (
              driverDetails &&
              driverDetails.courier &&
              driverDetails.courier.name &&
              driverDetails.courier.phone
            ) {
              messageText += `${`You may want to contact the assigned courier first - ${driverDetails.courier.name} ${driverDetails.courier.surname} (${driverDetails.courier.phone}). `}`;
            }
          }
        }
        messageText += `Do you wish to proceed with the cancellation? `;
        let messageTitle = `Warning!`; // ${this.computeDeliveryTimer()}`

        function successCancellation(multi_hailing, delivery, data) {
          if (data.cancelResponse) {
            if (
              (multi_hailing === false &&
                cancellationMessageCheck(delivery, data)) ||
              (multi_hailing && data.cancelResponse.length === 0)
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
        function cancellationMessageCheck(delivery, data) {
          return (
            (delivery.delivery_partner === "lalamove" &&
              data.cancelResponse.response) ||
            (delivery.delivery_partner === "mrspeedy" &&
              data.cancelResponse.is_successful === true) ||
            (delivery.delivery_partner === "grab" && data.cancelResponse === "")
          );
        }

        MySwal.fire({
          icon: "warning",
          title: `${messageTitle}`,
          text: `${messageText}`,
          confirmButtonColor: "#F1384C", //PRIMARY_COLOR,
          confirmButtonText: "Cancel Delivery",
          showCancelButton: true,
          cancelButtonText: "Abort",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            return await this.api
              .put({
                url: "/orders/" + order_id + "/delivery-cancel",
              })
              .then(({ data }) => {
                console.log("delivery-cancel data: ", data);
                console.log(
                  "delivery-cancel data: ",
                  data.cancelResponse.length,
                );
                // console.log("this.state.data: ", this.state.data)
                const multi_hailing = this.state.data.multipartner_hailing;
                if (successCancellation(multi_hailing, delivery, data)) {
                  return data;
                } else {
                  if (this.state.data.multipartner_hailing === false) {
                    cancelledPartners = delivery.delivery_partner.toUpperCase();
                  } else if (this.state.data.multipartner_hailing === true) {
                    cancelledPartners = data.cancelResponse
                      .map(function (response) {
                        return String(response.delivery_partner).toUpperCase();
                      })
                      .join(", ");
                  }
                  cancelErrorText = `${cancelledPartners} declined your cancellation request. `;
                  if (
                    this.state.data.multipartner_hailing === false &&
                    delivery.delivery_partner === "lalamove"
                  ) {
                    cancelErrorText += `You may contact Lalamove Hotline at 8888-52-52.`;
                  }
                  MySwal.fire({
                    icon: "error",
                    title: "Error!",
                    text: `${cancelErrorText}`,
                    showConfirmButton: true,
                    // timer: 1000
                  });
                }
              })
              .catch(function (error) {
                MySwal.fire({
                  icon: "error",
                  title: "Error!",
                  text: "No Delivery booking found, we cannot proceed on cancellation request.",
                  showConfirmButton: true,
                  // timer: 1000
                });
              });
          },
          allowOutsideClick: () => !MySwal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            this.getData();
            MySwal.fire({
              icon: "success",
              title: "Success!",
              text: "You have cancelled the delivery.",
              showConfirmButton: false,
              timer: 1000,
            });
          }
        });
      }
    } catch (err) {
      this.handleError(err);
    }
  };

  computeDeliveryTimer = () => {
    const {
      data: {
        delivery: { onGoingInitTime = "" },
      },
    } = this.state;
    let timer;
    // console.log("compute delivery timer ongoinginittime: ", onGoingInitTime)
    if (onGoingInitTime) {
      timer = moment
        .duration(moment(new Date()).diff(moment(onGoingInitTime)))
        .asMinutes();
      // console.log("compute delivery timer: ", timer)
      return timer;
    }
  };

  computeMaxRefund = () => {
    const { data = "" } = this.state;
    let maxRefund = 0;
    let cartTotal = 0;
    let promoDiscount = 0;

    let partialMealPlanPromo = 0;

    cartTotal = data.order_details
      ? computeTotalCartAmount(data.order_details)
      : 0;

    promoDiscount = data.promoDiscount ? data.promoDiscount : 0;
    const service_charge = computeOrderServiceCharge(data);

    if (data.meal_plan_type === "3DayMealPlan") {
      if (data.meal_plan_index !== 2) {
        partialMealPlanPromo = Math.floor(promoDiscount / 3);
      } else {
        partialMealPlanPromo =
          promoDiscount - 2 * Math.floor(promoDiscount / 3);
      }
    } else if (data.meal_plan_type === "5DayMealPlan") {
      if (data.meal_plan_index !== 4) {
        partialMealPlanPromo = Math.floor(promoDiscount / 5);
      } else {
        partialMealPlanPromo =
          promoDiscount - 4 * Math.floor(promoDiscount / 5);
      }
    }
    if (data.delivery) {
      if (data.delivery.isCOD) {
        // maxRefund = computeTotalCartAmount(data.order_details)
        maxRefund = data.payment.amount - service_charge;
      } else {
        if (data.isMealPlan) {
          if (data.payment.main_payment && data.payment.main_payment.amount) {
            // maxRefund = data.payment.main_payment.amount - this.computeTotalMealPlanRefund()
            maxRefund =
              // computeTotalCartAmount(data.order_details) +
              cartTotal + Number(data.chargedDeliveryFee);
          } else {
            // maxRefund = data.payment.amount
            maxRefund =
              // computeTotalCartAmount(data.order_details) +
              cartTotal + Number(data.chargedDeliveryFee);
          }
          if (data.mealPlanDiscount > 0 || promoDiscount > 0) {
            maxRefund =
              maxRefund -
              // computeTotalCartAmount(data.order_details) +
              // cartTotal +
              // data.mealPlanDiscount / 100;
              partialMealPlanPromo;
            // maxRefund = maxRefund - Math.ceil(((computeTotalCartAmount(data.order_details))*(data.mealPlanDiscount/100)))
          }
        } else {
          var discount = data.promoDiscount ? data.promoDiscount : 0;
          maxRefund =
            // computeTotalCartAmount(data.order_details) +
            cartTotal + Number(data.chargedDeliveryFee) - Number(discount);
        }
      }
    } else {
      // maxRefund = computeTotalCartAmount(data.order_details)
      maxRefund = data.payment.amount - service_charge;
    }
    // if (data.promoDiscount) {
    //   maxRefund = maxRefund - data.promoDiscount;
    // }
    // console.log("maxRefund: ", maxRefund)
    return Math.round(maxRefund);
  };

  computeTotalMealPlanRefund = () => {
    let totalMealPlanRefund = 0;

    totalMealPlanRefund = this.state.mealPlanOrderData.reduce((acc, cur) => {
      // console.log("refunded amount: ", cur.refunded_amount)
      let val = cur.refunded_amount ? cur.refunded_amount : 0;
      // console.log("val: ", val)
      acc += val;
      // console.log("acc: ", acc)
      return acc;
    }, 0);
    // console.log("totalMealPlanRefund: ", totalMealPlanRefund)
    return totalMealPlanRefund;
  };

  onSubmitUpdateCustomer = (values, setSubmitting) => {
    const { _id = "" } = this.state.data;
    this.api
      .put({
        url: "/orders/" + _id + "/customer",
        data: values,
      })
      .then(() => {
        this.getData();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated this order. Redirecting you to orders page.",
          timer: 4000,
          confirmButtonText: "OK",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonColor: PRIMARY_COLOR,
        }).then((result) => {
          if (result.dismiss) {
            const { dismiss } = result;
            if (dismiss === "cancel") {
              this.setState({ updateBtnLoading: false });
            } else {
              this.setState({
                redirectTo: `/stores/${this.state.data.store._id}/orders`,
              });
            }
          } else {
            this.setState({
              redirectTo: `/stores/${this.state.data.store._id}/orders`,
            });
          }
        });
      })
      .catch((err) => {
        if (
          err.hasOwnProperty("response") &&
          err.response.hasOwnProperty("data") &&
          err.response.data.hasOwnProperty("error") &&
          err.response.data.error.length > 0
        ) {
          this.handleError(err);
        }
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showChangeCustomerInfoModal: false });
      });
  };

  onSubmitUpdateSchedule = (values, setSubmitting) => {
    const { _id = "" } = this.state.data;

    this.api
      .put({
        url: "/orders/" + _id + "/schedule",
        data: values,
      })
      .then(() => {
        this.getData();
        MySwal.fire({
          icon: "success",
          title: "Success!",
          text: "You have successfully updated this order. Redirecting you to orders page.",
          timer: 4000,
          confirmButtonText: "OK",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonColor: PRIMARY_COLOR,
        }).then((result) => {
          if (result.dismiss) {
            const { dismiss } = result;
            if (dismiss === "cancel") {
              this.setState({ updateBtnLoading: false });
            } else {
              this.setState({
                redirectTo: `/stores/${this.state.data.store._id}/orders`,
              });
            }
          } else {
            this.setState({
              redirectTo: `/stores/${this.state.data.store._id}/orders`,
            });
          }
        });
      })
      .catch((err) => {
        if (
          err.hasOwnProperty("response") &&
          err.response.hasOwnProperty("data") &&
          err.response.data.hasOwnProperty("error") &&
          err.response.data.error.length > 0
        ) {
          const {
            response: {
              data: { error, delivery_partner },
            },
          } = err;

          if (error === "cancel_not_allowed") {
            let cancelErrorText = `${delivery_partner.toUpperCase()} declined your moving request. `;
            if (delivery_partner === "lalamove") {
              cancelErrorText += `You may contact Lalamove Hotline at 8888-52-52.`;
            }
            this.showErrorSwal(cancelErrorText);
          } else {
            this.handleError(err);
          }
        } else {
          this.handleError(err);
        }
      })
      .finally(() => {
        setSubmitting(false);
        this.setState({ showChangeOrderScheduleModal: false });
      });
  };

  deliveryReview = (arr) => {
    let str = "";
    if (!isNil(arr) && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        str += `"${arr[i]}", `;
      }
      str = str.substring(0, str.length - 2);
    }
    return str;
  };

  onClickEdit = () => {
    this.setState({ isEditing: true });
  };

  onClickCancelEdit = () => {
    this.setState({ isEditing: false });
  };

  async getDataNoRemount() {
    if (this.data_url !== "") {
      this.api &&
        this.api
          .get(this.data_url)
          .then((data) => {
            this._isMounted &&
              this.setState({
                data: data.data,
                data_status: "fetched",
              });
            this.handleOnDataFetched();

            if (this.use_display_data) {
              this.setDisplayData();
            }
          })
          .catch((error) => {
            this._isMounted &&
              this.setState({ error: error, showSwalError: true });
            this.handleOnDataFetchError();
          });
    } else {
      this.afterComponentDidMount();
    }
  }
  execCheckDiscrepancy = async (order_id, callbackFunc) => {
    try {
      const { data } = await this.api.post({
        url: "/orders/" + order_id + "/delivery-discrepancy-check",
      });

      const { withDiscrepancy, quotations } = data;
      if (withDiscrepancy) {
        const discrepancyWarning = await MySwal.fire({
          icon: "warning",
          title: "Quotation discrepancy detected",
          html: `Detected a discrepancy in quotation <br/><b>${toPesoAmount(
            quotations.old,
          )}</b> to <b>${toPesoAmount(
            quotations.new,
          )}</b><br/> do you want to proceed?`,
          confirmButtonColor: "#F4521E", //PRIMARY_COLOR,
          confirmButtonText: "Yes, Proceed",
          showCancelButton: true,
          cancelButtonText: "Cancel",
        });
        if (discrepancyWarning.isConfirmed) {
          callbackFunc();
          return;
        }
        return;
      }
    } catch (err) {
      this.handleError(err);
    }
  };
  execHailRider = async (order_id, lastStatus) => {
    const hailFunction = async () => {
      data.delivery.lastStatus = "REFRESHING";
      this.setState({ data });
      const { data: hailingResponse } = await this.api.put({
        url: "/orders/" + order_id + "/delivery-hail",
      });

      if (hailingResponse.isInsuffCredit === true) {
        MySwal.fire({
          icon: "warning",
          title: "Insufficient funds",
          text: "You have insufficient funds in your wallet. Please top up and rehail to proceed with delivery.",
          confirmButtonColor: PRIMARY_COLOR,
          confirmButtonText: "Ok",
        });
      }
      await this.getDataNoRemount();
    };

    let { data } = this.state;
    try {
      let messageText, messageTitle;
      if (lastStatus === undefined) {
        messageTitle = `Warning!`;
        messageText = `This order is set to Auto-Hail once the order status is Accepted or set to Ready.
                Are you sure you want to override the auto-hailing and commence hailing the rider now?`;
      } else {
        messageTitle = `Are you sure?`;
        messageText = `This delivery has been cancelled. Are you sure you want to hail a rider again?`;
      }
      const result = await MySwal.fire({
        icon: "warning",
        title: messageTitle,
        text: messageText,
        confirmButtonColor: "#F4521E", //PRIMARY_COLOR,
        confirmButtonText: "Hail Rider",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        preConfirm: async () => {
          await this.execCheckDiscrepancy(order_id, hailFunction);
        },
        showLoaderOnConfirm: true,
      });

      if (result.isConfirmed === true) {
        hailFunction();
      }
    } catch (err) {
      data.delivery.lastStatus = lastStatus;
      this.setState({ data });
      this.handleError(err);
    }
  };

  execDeliveryRefresh = async (order_id, lastStatus) => {
    let { data } = this.state;
    try {
      data.delivery.lastStatus = "REFRESHING";
      this.setState({ data });
      await this.api.put({
        url: "/orders/" + order_id + "/delivery-refresh",
      });
      await this.getDataNoRemount();
    } catch (err) {
      data.delivery.lastStatus = lastStatus;
      this.setState({ data });
      this.handleError(err);
    }
  };

  deliverySocketHandler = async () => {
    await this.getDataNoRemount();
    this.setState((prevState) => ({
      deliveryLogsUpdateKey: !prevState.deliveryLogsUpdateKey,
    }));
  };

  refreshDeliveryLogs = async () => {
    let { data } = this.state;
    const {
      _id: order_id,
      delivery: { lastStatus },
    } = data;
    this.setState((prevState) => ({
      deliveryLogsUpdateKey: !prevState.deliveryLogsUpdateKey,
    }));
    setTimeout(async () => {
      await this.execDeliveryRefresh(order_id, lastStatus);
    }, 1000); // prevents racing condition on delivery logs update
  };
  mountDeliverySocket() {
    const { order_type } = this.state.data;
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.getData();
    await this.getInStoreOrderDetails(this.props.id);
    await this.getStoreRating(this.props.id);
    this.mountDeliverySocket();
  }

  componentWillUnmount() {
    this._mounted = false;
  }
  render() {
    const {
        data = "",
        data_status = "",
        showNotesModal = false,
        action,
        cancelBtnLoading = false,
        showCompletionSelectionModal = false,
        showReadyForDeliveryModal = false,
        btnLoading = false,
        updateBtnLoading = false,
        showAcceptAsapOrderModal = false,
        disableRefundButton = false,
        showRefundRequestModal = false,
        showChangeOrderScheduleModal = false,
        showChangeCustomerInfoModal = false,
        display_buttons,
        storeRating,
      } = this.state,
      isLoading = data_status !== "fetched";
    const { pickup_details } = data;

    let thirdPartyInfo = false;
    let partialMealPlanPromo = 0;

    try {
      thirdPartyInfo =
        typeof pickup_details === "string"
          ? JSON.parse(pickup_details)
          : pickup_details;
    } catch (error) {
      console.log(error);
    }
    const cartTotal = data.order_details
      ? computeTotalCartAmount(data.order_details)
      : 0;
    const includedDeliveryFee =
      data.chargedDeliveryFee && !data.isDeliveryCOD
        ? Number(data.chargedDeliveryFee)
        : 0;
    const promoDiscount = data.promoDiscount ? data.promoDiscount : 0;
    const refundedAmount = data.refunded_amount ? data.refunded_amount : 0;

    // modifying of order status from dashboard should not be allowed unless role is permitted
    const isFromReportsPage =
      !isEmpty(this.props.location.state) &&
      this.props.location.state.from.includes("/reports");
    const ALLOWED_ROLES = ["store_admin", "console_user"];
    const computed_additional_charge = computeOrderServiceCharge(data);
    const hasOrderServiceCharge = computed_additional_charge > 0;
    let driverDetails = {};
    if (data.delivery) {
      if (data.delivery.driverDetails)
        driverDetails = JSON.parse(data.delivery.driverDetails);

      if (data.isMealPlan) {
        const { mealPlanOrderData } = this.state;
        const order_id = this.props.id;
        let mealPlanOrders = mealPlanOrderData;
        if (data.meal_plan_type === "3DayMealPlan") {
          if (data.meal_plan_index !== 2) {
            partialMealPlanPromo = Math.floor(promoDiscount / 3);
          } else {
            partialMealPlanPromo =
              promoDiscount - 2 * Math.floor(promoDiscount / 3);
          }
        } else if (data.meal_plan_type === "5DayMealPlan") {
          if (data.meal_plan_index !== 4) {
            partialMealPlanPromo = Math.floor(promoDiscount / 5);
          } else {
            partialMealPlanPromo =
              promoDiscount - 4 * Math.floor(promoDiscount / 5);
          }
        }

        if (!mealPlanOrderData.length) {
          this.execFetchMealPlanItems(order_id)
            .then((result) => {
              // console.log("result: ", result)
              mealPlanOrders = result;
              // mealPlanOrderData = mealPlanOrders
              // console.log("PerOrder mealPlanOrderData: ", mealPlanOrderData)
              // if (!mealPlanOrderData.length) {
              // if (mealPlanOrderData !== mealPlanOrders) {
              this.setState({ mealPlanOrderData: mealPlanOrders });
              // console.log("PerOrder mealPlanOrderData: ", mealPlanOrderData)
              // }
            })
            .then(() => {
              // console.log("PerOrder state: ", this.state)
            });
        }
      }
    }

    return (
      <>
        {this.renderTrackingModal()}
        <TitleHelmet title={this.state.title} />

        {this.state.showSplashScreen ? <LayoutSplashScreen /> : <></>}

        {this.state.redirectTo !== "" ? (
          <Redirect to={this.state.redirectTo} push />
        ) : (
          <></>
        )}

        {isLoading ? (
          <div className="h-100 w-100 d-flex flex-column align-items-center">
            <PacmanLoading />
          </div>
        ) : (
          <>
            <MealPlanListModal
              show={this.state.showMealPlanListModal}
              onHide={this.handleOnCloseMealPlanListModal}
              mealPlanOrderData={this.state.mealPlanOrderData}
            />

            <AcceptOrderModal
              show={showAcceptAsapOrderModal}
              onHide={() => {
                this.setState({
                  showAcceptAsapOrderModal: false,
                  updateBtnLoading: false,
                });
              }}
              onSubmit={(values, setSubmitting) => {
                this.execAcceptASAPOrder(values.minutes, setSubmitting);
              }}
              order={data}
              store={data.store}
              display_buttons={display_buttons}
            />

            <SelectionModal
              show={showCompletionSelectionModal}
              onHide={() => {
                this.setState({ showCompletionSelectionModal: false });
              }}
              title={<>What do you want to do?</>}
              options={[
                {
                  title: "completed",
                  icon: "Navigation/Check",
                  desc: "",
                  onClick: () => {
                    this.onClickReadyForPickupModal("completed");
                  },
                },
                {
                  title: "mark as no show",
                  icon: "Navigation/Close",
                  desc: "",
                  onClick: () => {
                    this.onClickReadyForPickupModal("cancelled_no_show");
                  },
                },
              ]}
            />

            <DeliveryDetailsModal
              show={showReadyForDeliveryModal}
              onHide={() => {
                this.setState({ showReadyForDeliveryModal: false });
              }}
              btnLoading={btnLoading}
              onSubmit={this.onSubmitOutForDelivery}
              display_buttons={display_buttons}
            />

            <CancelNotesModal
              show={showNotesModal}
              onHide={() => {
                this.setState({ showNotesModal: false });
              }}
              onSubmit={this.onSubmitRefundRequest}
              btnLoading={cancelBtnLoading}
              order={data}
              action={action}
              display_buttons={display_buttons}
              max={this.computeMaxRefund()}
            />

            <RefundRequestModal
              show={Boolean(showRefundRequestModal && display_buttons)}
              onHide={() => {
                this.setState({ showRefundRequestModal: false });
              }}
              // max={computeTotalCartAmount(data.order_details)}
              // max={
              //     data.delivery && !data.delivery.isCOD
              //         ? computeTotalCartAmount(data.order_details) + Number(data.chargedDeliveryFee)
              //         : computeTotalCartAmount(data.order_details)
              // }
              // max={
              //     data.delivery
              //     ?
              //         !data.delivery.isCOD
              //         ?
              //             !data.isMealPlan
              //             ?
              //                 computeTotalCartAmount(data.order_details) + Number(data.chargedDeliveryFee)
              //             :
              //                 data.payment.main_payment && data.payment.main_payment.amount
              //                 ?
              //                     toPesoAmount(data.payment.main_payment.amount)
              //                 :
              //                     toPesoAmount(data.payment.amount)
              //         :
              //             computeTotalCartAmount(data.order_details)
              //     :
              //         computeTotalCartAmount(data.order_details)
              // }
              max={this.computeMaxRefund()}
              transaction_number={data.transaction_number}
              onSubmit={this.onSubmitRefundRequest}
            />

            <ChangeOrderScheduleModal
              show={showChangeOrderScheduleModal}
              onHide={() => {
                this.setState({ showChangeOrderScheduleModal: false });
              }}
              data={data}
              onSubmit={this.onSubmitUpdateSchedule}
            />

            <ChangeCustomerInfoModal
              show={showChangeCustomerInfoModal}
              onHide={() => {
                this.setState({ showChangeCustomerInfoModal: false });
              }}
              data={data}
              onSubmit={this.onSubmitUpdateCustomer}
            />

            <HeaderTextWithActions2
              breadcrumbs={
                this.props.webview ? (
                  <Breadcrumbs>
                    <Breadcrumbs.Item
                      text={"Dashboard"}
                      link={`/webview/brands/${this.props.brand_url}`}
                    />
                    <Breadcrumbs.Item text={this.state.title} active="true" />
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs>
                    <Breadcrumbs.Item
                      text={this.state.data.brand.name}
                      link={`/`}
                    />
                    {this.props.user.iam_role !== "super_admin" && (
                      <Breadcrumbs.Item text="Orders" link="/orders" />
                    )}
                    <Breadcrumbs.Item
                      text={data.store.name}
                      link={`/stores/${data.store._id}/orders`}
                    />
                    <Breadcrumbs.Item
                      text={this.state.title}
                      active="true"
                      link="#!"
                    />
                  </Breadcrumbs>
                )
              }
              title={
                <>
                  <span className="va-middle"> {this.state.title} </span>
                  {/* <span className={`label label-${getVariantByStatus(data.status)} label-pill label-inline ml-2 va-middle text-uppercase font-weight-bolder label-lg`}>{getStatusDisplay(data.status, data.order_type)}</span> */}
                  <span className="text-purple text-uppercase font-weight-bolder font-pickup-sm-2 ml-5">
                    {getStatusDisplay(data.status, data.order_type)}
                  </span>
                </>
              }
              actions={
                <>
                  {!isOrderReadOnly(data.status) &&
                    data.order_queue &&
                    !isFromReportsPage &&
                    ALLOWED_ROLES.includes(this.props.user.iam_role) && (
                      <>
                        {display_buttons && (
                          <OrderStatusActionButton
                            onClick={this.handleOnClickUpdateBtn}
                            btnLoading={updateBtnLoading}
                            {...data}
                          />
                        )}
                      </>
                    )}
                </>
              }
            />

            {isOrderReadOnly(data.status) && (
              <Row>
                <Col>
                  <IconAlert
                    text={<> This order page is set to read-only. </>}
                    variant="danger"
                    icon="General/Lock"
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col sm={4}>
                <Card className="gutter-b card-custom card-stretch">
                  <Card.Body className="d-flex flex-column">
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Customer Information
                            <button
                              type="button"
                              className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle font-pickup-sm"
                              onClick={() => {
                                this.setState({
                                  showChangeCustomerInfoModal: true,
                                });
                              }}
                            >
                              <i className="far fa-edit text-primary" />
                            </button>
                          </div>
                        </>
                      }
                      body={
                        <>
                          <div>
                            <TextMuted>First Name:</TextMuted>{" "}
                            <>{data.guest_customer.first_name}</>
                          </div>
                          <div>
                            <TextMuted>Last Name:</TextMuted>{" "}
                            <>{data.guest_customer.last_name}</>
                          </div>
                          <div>
                            <TextMuted>Phone:</TextMuted>{" "}
                            <>
                              {phoneNumberPrefix(
                                data.guest_customer.contact_number,
                              )}
                            </>
                          </div>
                          <div>
                            <TextMuted>Email:</TextMuted>{" "}
                            <>{data.guest_customer.email}</>
                          </div>
                        </>
                      }
                    />
                    {thirdPartyInfo &&
                    Object.keys(thirdPartyInfo).length > 0 ? (
                      <Section
                        header={
                          <>
                            <div className="font-weight-bolder">
                              Third Party Information
                            </div>
                          </>
                        }
                        body={
                          <>
                            <div>
                              <TextMuted>First Name:</TextMuted>{" "}
                              <>{thirdPartyInfo.first_name}</>
                            </div>
                            <div>
                              <TextMuted>Last Name:</TextMuted>{" "}
                              <>{thirdPartyInfo.last_name}</>
                            </div>
                            <div>
                              <TextMuted>Phone:</TextMuted>{" "}
                              <>
                                {phoneNumberPrefix(
                                  thirdPartyInfo.contact_number,
                                )}
                              </>
                            </div>
                          </>
                        }
                      />
                    ) : null}
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Payment Information
                          </div>
                        </>
                      }
                      body={
                        <>
                          <div>
                            <TextMuted>Payment Method:</TextMuted>{" "}
                            <>{data.payment.payment_method}</>
                          </div>
                          <div>
                            <TextMuted>Payment ID:</TextMuted>{" "}
                            <>
                              {decryptPaymentDetails(
                                data.payment.payment_details,
                              )}
                            </>
                          </div>
                          <div>
                            <TextMuted>Amount:</TextMuted>{" "}
                            <>
                              {/* {toPesoAmount(data.payment.amount)} */}
                              {data.isMealPlan ? (
                                <>
                                  {data.payment.main_payment &&
                                  data.payment.main_payment.amount
                                    ? toPesoAmount(
                                        data.payment.main_payment.amount,
                                      )
                                    : toPesoAmount(data.payment.amount)}
                                  <span>
                                    {InfoTooltip(
                                      "This amount is for the entire meal plan.",
                                      "grey",
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>{toPesoAmount(data.payment.amount)}</>
                              )}
                            </>
                          </div>
                          {data.isMealPlan && data.mealPlanDiscount ? (
                            <>
                              <div>
                                <TextMuted>Meal Plan Discount (%):</TextMuted>{" "}
                                <>
                                  {/* {toPesoAmount(data.payment.amount)} */}
                                  {`${data.mealPlanDiscount}%`}
                                </>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {display_buttons ? (
                            <>
                              {data.status === "completed" ? (
                                <div>
                                  <Button
                                    variant="danger"
                                    onClick={this.onClickRefundButton}
                                    disabled={
                                      disableRefundButton ? "disabled" : ""
                                    }
                                  >
                                    Issue Refund
                                  </Button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : null}
                        </>
                      }
                    />
                    {data.isInStoreOrder ? (
                      <>
                        <Section
                          header={
                            <>
                              <div className="font-weight-bolder">
                                In-Store Order Information
                              </div>
                            </>
                          }
                          body={
                            <>
                              <div>
                                <TextMuted>In-Store Order User:</TextMuted>{" "}
                                <>
                                  {
                                    this.state.inStoreOrderDetails
                                      .store_user_name
                                  }
                                </>
                                {/* <>{`test`}</> */}
                              </div>
                            </>
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    {isOrderRefunded(data.status) ? (
                      <>
                        <Section
                          header={
                            <>
                              <div className="font-weight-bolder">
                                Refund Details
                              </div>
                            </>
                          }
                          body={
                            <>
                              {
                                <div>
                                  <TextMuted>Refunded Amount:</TextMuted>{" "}
                                  <>{toPesoAmount(data.refunded_amount)}</>
                                </div>
                              }
                              {
                                <div>
                                  <TextMuted>Refund Reason:</TextMuted>{" "}
                                  <>{data.refund_notes}</>
                                </div>
                              }
                              {data.isMealPlan ? (
                                <div>
                                  <TextMuted>
                                    Entire Meal Plan Total Refunded Amount:
                                  </TextMuted>{" "}
                                  <>
                                    {
                                      // toPesoAmount(this.state.mealPlanOrderData.reduce((acc, cur) => {
                                      //     console.log("refunded amount: ", cur.refunded_amount)
                                      //     let val = cur.refunded_amount?cur.refunded_amount:0
                                      //     console.log("val: ", val)
                                      //     acc += val
                                      //     console.log("acc: ", acc)
                                      //     return acc
                                      // }, 0))
                                      toPesoAmount(
                                        this.computeTotalMealPlanRefund(),
                                      )
                                    }
                                  </>
                                </div>
                              ) : (
                                <></>
                              )}
                            </>
                          }
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={8} className="d-flex flex-column">
                <Card className="card gutter-b card-custom">
                  <Card.Body>
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Ordered Items
                          </div>
                          <div className="font-size-sm font-weight-bolder text-muted mt-2">
                            Item Count:{" "}
                            {computeTotalCartItems(data.order_details)}
                          </div>
                        </>
                      }
                    />
                    <div className="order-details">
                      {data.order_details.map(
                        (
                          { item = {}, qty = 0, price = 0, extras = [] },
                          index,
                        ) => {
                          return (
                            <div key={index}>
                              <div className="item d-flex flex-wrap align-items-center mv-1rem inactive-link">
                                <div className="item-photo-wrapper symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                                  <div
                                    className="symbol-label"
                                    style={{
                                      backgroundImage: `url(${item.image_url})`,
                                    }}
                                  ></div>
                                </div>
                                <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                                  <div className="font-weight-bolder text-primary">
                                    {qty} x {item.name}
                                  </div>
                                  {extras.map(({ extra_id = {} }, index) => {
                                    return (
                                      <div key={index}>+ {extra_id.name}</div>
                                    );
                                  })}
                                </div>
                                <div className="item-actions-wrapper d-flex align-items-center">
                                  <span className="font-weight-bolder font-pickup-sm-2">
                                    {" "}
                                    {toPesoAmount(price * qty)}{" "}
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-solid"></div>
                            </div>
                          );
                        },
                      )}
                    </div>

                    <div className="separator separator-solid separator-border-2"></div>
                    {data.promo && data.promo.promo_type === "free_item" && (
                      <div className="item-text-wrapper d-flex flex-column flex-grow-1 pr-2 pt-2">
                        <div className="item-name d-flex">
                          <span>
                            <i className="fas fa-gift"></i>
                          </span>
                          <span className="font-weight-bold ml-2">
                            {data.promo.free_item_description}
                          </span>
                          <div></div>
                        </div>
                      </div>
                    )}
                    <div className="d-flex flex-wrap align-items-ce nter mv-1rem">
                      <DFlex className="flex-column flex-grow-1">
                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                          total amount
                        </span>
                      </DFlex>
                      <DFlex className="align-items-center">
                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                          {toPesoAmount(cartTotal)}
                        </span>
                      </DFlex>
                    </div>
                    {hasOrderServiceCharge && (
                      <>
                        {data.additional_charges.map((charge) => (
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {formatAdditionalChargeName(charge.name)}
                                {charge.type === "percent_charge" && (
                                  <span className="text-primary ml-1">
                                    {formatAdditionalChargeType(
                                      charge.type,
                                      charge.factor,
                                    )}
                                  </span>
                                )}
                                {InfoTooltip("Non-refundable", "grey")}
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {toPesoAmount(charge.amount)}
                              </span>
                            </DFlex>
                          </div>
                        ))}
                        {promoDiscount === 0 &&
                          data.order_type !== "delivery" && (
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  grand total
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  {toPesoAmount(
                                    Number(
                                      cartTotal + computed_additional_charge,
                                    ).toFixed(2),
                                  )}
                                </span>
                              </DFlex>
                            </div>
                          )}
                      </>
                    )}

                    {data.order_type !== "delivery" && (
                      <>
                        {isOrderRefunded(data.status) && !data.promoDiscount ? (
                          <>
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                  refunded amount
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                  - {toPesoAmount(data.refunded_amount)}
                                </span>
                              </DFlex>
                            </div>
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  grand total
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  {toPesoAmount(
                                    computeTotalCartAmount(data.order_details) +
                                      computed_additional_charge -
                                      parseInt(data.refunded_amount) -
                                      parseInt(data.promoDiscount),
                                  )}
                                </span>
                              </DFlex>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {data.order_type !== "delivery" &&
                      data.promoDiscount > 0 && (
                        <>
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                {`Promo - ${
                                  data.promo.promo_code
                                }(${formatPromoType(data.promo)}) `}
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center text-danger">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {`- ${toPesoAmount(promoDiscount)}`}
                              </span>
                            </DFlex>
                          </div>
                          {data.refunded_amount > 0 && (
                            <>
                              <div className="separator separator-solid separator-border-2"></div>
                              <div className="d-flex flex-wrap align-items-center mv-1rem">
                                <DFlex className="flex-column flex-grow-1">
                                  <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                    refunded amount
                                  </span>
                                </DFlex>
                                <DFlex className="align-items-center">
                                  <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                    - {toPesoAmount(refundedAmount)}
                                  </span>
                                </DFlex>
                              </div>
                            </>
                          )}
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                grand total
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {toPesoAmount(
                                  cartTotal +
                                    computed_additional_charge -
                                    promoDiscount -
                                    refundedAmount,
                                )}
                              </span>
                            </DFlex>
                          </div>
                        </>
                      )}

                    {data.order_type === "delivery" && data.delivery.isCOD && (
                      <>
                        {data.promoDiscount > 0 && (
                          <div className="d-flex flex-wrap align-items-center mv-1rem">
                            <DFlex className="flex-column flex-grow-1">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                {`Promo - ${
                                  data.promo.promo_code
                                }(${formatPromoType(data.promo)}) `}
                              </span>
                            </DFlex>
                            <DFlex className="align-items-center text-danger">
                              <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                {`- ${toPesoAmount(promoDiscount)}`}
                              </span>
                            </DFlex>
                          </div>
                        )}
                        {!data.refunded_amount &&
                          cartTotal !==
                            computed_additional_charge +
                              cartTotal -
                              promoDiscount && (
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  grand total
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  {toPesoAmount(
                                    computed_additional_charge +
                                      cartTotal -
                                      promoDiscount,
                                  )}
                                </span>
                              </DFlex>
                            </div>
                          )}
                      </>
                    )}

                    {data.order_type === "delivery" && data.delivery && (
                      <>
                        {data.delivery.isCOD ? (
                          <>
                            {!refundedAmount && (
                              <>
                                <div className="separator separator-solid separator-border-2"></div>
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                      {`⚠️delivery fee (${
                                        !data.delivery.delivery_partner
                                          ? ""
                                          : data.delivery.delivery_partner
                                      }` + ` Cash)`}
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center text-danger">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                      {toPesoAmount(data.chargedDeliveryFee)}
                                    </span>
                                  </DFlex>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {!data.isMealPlan ? (
                              <>
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                      {`delivery fee (${
                                        !data.delivery.delivery_partner
                                          ? ""
                                          : data.delivery.delivery_partner
                                      })`}
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                      {/* {toPesoAmount(data.chargedDeliveryFee)} */}
                                      {toPesoAmount(includedDeliveryFee)}
                                    </span>
                                  </DFlex>
                                </div>

                                {data.promoDiscount > 0 && (
                                  <>
                                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                                      <DFlex className="flex-column flex-grow-1">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                          {`Promo - ${
                                            data.promo.promo_code
                                          }(${formatPromoType(data.promo)}) `}
                                        </span>
                                      </DFlex>
                                      <DFlex className="align-items-center text-danger">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          {`- ${toPesoAmount(promoDiscount)}`}
                                        </span>
                                      </DFlex>
                                    </div>
                                  </>
                                )}

                                {data.order_type !== "delivery" &&
                                  data.promoDiscount > 0 && (
                                    <>
                                      <div className="d-flex flex-wrap align-items-center mv-1rem">
                                        <DFlex className="flex-column flex-grow-1">
                                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                            grand total
                                          </span>
                                        </DFlex>
                                        <DFlex className="align-items-center">
                                          <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                            {toPesoAmount(
                                              computed_additional_charge +
                                                cartTotal -
                                                promoDiscount,
                                            )}
                                          </span>
                                        </DFlex>
                                      </div>
                                    </>
                                  )}
                              </>
                            ) : (
                              <>
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                      Delivery Fee*
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                      {data.chargedDeliveryFee > 0
                                        ? toPesoAmount(data.chargedDeliveryFee)
                                        : `FREE`}
                                    </span>
                                  </DFlex>
                                </div>
                                {data.promoDiscount > 0 && (
                                  <>
                                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                                      <DFlex className="flex-column flex-grow-1">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                          {`Partial Discount `}
                                        </span>
                                      </DFlex>
                                      <DFlex className="align-items-center text-danger">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          {`- ${toPesoAmount(
                                            partialMealPlanPromo,
                                          )}`}
                                        </span>
                                      </DFlex>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {isOrderRefunded(data.status) ? (
                          <>
                            <div className="separator separator-solid separator-border-2"></div>
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                  refunded amount
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                  - {toPesoAmount(refundedAmount)}
                                </span>
                              </DFlex>
                            </div>
                            <div className="d-flex flex-wrap align-items-center mv-1rem">
                              <DFlex className="flex-column flex-grow-1">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  grand total
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                  {data.delivery && (
                                    <>
                                      {data.delivery.isCOD
                                        ? toPesoAmount(
                                            computed_additional_charge +
                                              cartTotal -
                                              refundedAmount -
                                              promoDiscount,
                                          )
                                        : !data.isMealPlan
                                        ? toPesoAmount(
                                            computed_additional_charge +
                                              cartTotal +
                                              includedDeliveryFee -
                                              refundedAmount -
                                              promoDiscount,
                                          )
                                        : toPesoAmount(
                                            cartTotal +
                                              includedDeliveryFee -
                                              partialMealPlanPromo -
                                              refundedAmount,
                                          )}
                                    </>
                                  )}
                                </span>
                              </DFlex>
                            </div>
                            {data.delivery.isCOD && (
                              <>
                                <div className="separator separator-solid separator-border-2"></div>
                                <div className="d-flex flex-wrap align-items-center mv-1rem">
                                  <DFlex className="flex-column flex-grow-1">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder text-danger">
                                      {`⚠️delivery fee (${
                                        !data.delivery.delivery_partner
                                          ? ""
                                          : data.delivery.delivery_partner
                                      }` + ` Cash)`}
                                    </span>
                                  </DFlex>
                                  <DFlex className="align-items-center text-danger">
                                    <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                      {toPesoAmount(data.chargedDeliveryFee)}
                                    </span>
                                  </DFlex>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {!data.delivery.isCOD && (
                              <>
                                {!data.isMealPlan ? (
                                  <>
                                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                                      <DFlex className="flex-column flex-grow-1">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          grand total
                                        </span>
                                      </DFlex>
                                      <DFlex className="align-items-center">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          {data.delivery && (
                                            <>
                                              {toPesoAmount(
                                                computed_additional_charge +
                                                  cartTotal +
                                                  includedDeliveryFee -
                                                  promoDiscount,
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </DFlex>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="d-flex flex-wrap align-items-center mv-1rem">
                                      <DFlex className="flex-column flex-grow-1">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          grand total
                                        </span>
                                      </DFlex>
                                      <DFlex className="align-items-center">
                                        <span className="font-pickup-sm-2 text-uppercase font-weight-bolder">
                                          {data.delivery && (
                                            <>
                                              {toPesoAmount(
                                                computed_additional_charge +
                                                  cartTotal +
                                                  includedDeliveryFee -
                                                  partialMealPlanPromo,
                                              )}
                                            </>
                                          )}
                                        </span>
                                      </DFlex>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {data.isMealPlan && (
                          <>
                            <div className="separator separator-solid separator-border-2"></div>
                            <div className="d-flex flex-wrap align-items-center mb-1 mt-2">
                              {data.promo && data.promo.promo_code && (
                                <DFlex className="flex-column flex-grow-1">
                                  <span>
                                    <span
                                      style={{ transform: "scale(0.8)" }}
                                      className="font-pickup-sm-1 font-italic"
                                    >
                                      {`*Promo Used - ${
                                        data.promo.promo_code
                                      }${formatPromoType(data.promo)}`}
                                    </span>
                                  </span>
                                </DFlex>
                              )}
                            </div>
                            <div className="d-flex flex-wrap align-items-center mb-1 mt-2">
                              <DFlex className="flex-column flex-grow-1">
                                <span>
                                  <span
                                    style={{ transform: "scale(0.8)" }}
                                    className="font-pickup-sm-1 font-italic"
                                  >
                                    *Delivery Quotation minus Free Delivery
                                    Discount:
                                  </span>
                                </span>
                              </DFlex>
                            </div>
                            <div className="d-flex flex-wrap align-items-center mb-1">
                              <DFlex className="flex-column flex-grow-1">
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="font-pickup-sm-1 text-uppercase font-italic"
                                >
                                  {`Delivery quotation (${
                                    !data.delivery.delivery_partner
                                      ? ""
                                      : data.delivery.delivery_partner
                                  })`}
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="font-pickup-sm-1 text-uppercase font-italic"
                                >
                                  {toPesoAmount(data.quotedDeliveryFee)}
                                </span>
                              </DFlex>
                            </div>
                            <div className="d-flex flex-wrap align-items-center mb-1">
                              <DFlex className="flex-column flex-grow-1">
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="font-pickup-sm-1 text-uppercase text-danger font-italic"
                                >
                                  Free Delivery Discount
                                </span>
                              </DFlex>
                              <DFlex className="align-items-center">
                                <span
                                  style={{ transform: "scale(0.8)" }}
                                  className="font-pickup-sm-1 text-uppercase text-danger font-italic"
                                >
                                  {`- ${toPesoAmount(
                                    data.current_free_delivery_amount,
                                  )}`}
                                </span>
                              </DFlex>
                            </div>
                            <div className="d-flex flex-wrap align-items-center mb-1 mt-2">
                              <DFlex className="flex-column flex-grow-1">
                                <span>
                                  {/* <span style={{transform: "scale(0.8)"}} className="font-pickup-sm-1">                                                                               
                                                                            *Computed by subtracting Free Delivery Discount from Delivery Quotation
                                                                        </span> */}
                                  <span
                                    style={{ transform: "scale(0.8)" }}
                                    className="font-pickup-sm-1 font-italic"
                                  >
                                    (Free if Delivery Quotation is less than
                                    Free Delivery Discount)
                                  </span>
                                </span>
                              </DFlex>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </Card.Body>
                </Card>

                <Card className="gutter-b card-custom flex-grow-1">
                  <Card.Body>
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Customer Remarks
                          </div>
                        </>
                      }
                      body={
                        <>{data.notes.trim() !== "" ? data.notes : "N/A"}</>
                      }
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <Card className="gutter-b card-custom card-stretch">
                  <Card.Body>
                    {data.isMealPlan ? (
                      <div>
                        <Section
                          header={
                            <>
                              <div className="font-weight-bolder">
                                Meal Plan Information
                              </div>
                            </>
                          }
                        />
                        <div className="d-flex align-items-center justify-content-between mb-10">
                          <div className="d-flex align-items-center mr-2">
                            <div className="symbol symbol-40 mr-3 flex-shrink-0">
                              <div className="symbol-label">
                                <span>
                                  <i className="flaticon-calendar text-primary"></i>
                                </span>
                              </div>
                            </div>
                            <div className="text-dark-75 font-weight-bolder text-uppercase">
                              Meal
                              {` ${data.meal_plan_index + 1} `}
                              of
                              <span
                                style={{
                                  color: "blue",
                                  textDecorationLine: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {
                                  <div onClick={this.handleOnClickMealPlan}>
                                    {` ${
                                      data.meal_plan_type === "3DayMealPlan"
                                        ? "3"
                                        : ` ${
                                            data.meal_plan_type ===
                                            "5DayMealPlan"
                                              ? "5"
                                              : ` ${data.meal_plan_type}`
                                          }`
                                    }`}
                                    {`-Day Meal Plan`}
                                  </div>
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}

                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            {data.order_type === "pickup"
                              ? "Pickup Information"
                              : ""}
                            {data.order_type === "delivery"
                              ? "Delivery Information"
                              : ""}
                          </div>
                        </>
                      }
                    />
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <div className="d-flex align-items-center mr-2">
                        <div className="symbol symbol-40 mr-3 flex-shrink-0">
                          <div className="symbol-label">
                            <span>
                              {data.order_type === "pickup" ? (
                                <i className="flaticon-shopping-basket text-primary"></i>
                              ) : (
                                <i className="flaticon2-delivery-truck text-primary"></i>
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div
                            href="#"
                            className="text-dark-75 font-weight-bolder"
                          >
                            {data.order_type === "delivery" && (
                              <>
                                {data.delivery && (
                                  <>
                                    {data.multipartner_hailing === false ? (
                                      <>
                                        <div>
                                          {`${data.delivery.delivery_partner.toUpperCase()} ${
                                            data.delivery.delivery_vehicle
                                              ? `${data.delivery.delivery_vehicle.toUpperCase()}`
                                              : ""
                                          } ${
                                            data.delivery.priority_fee
                                              ? data.delivery.priority_fee !==
                                                  0 && " PRIORITY"
                                              : ""
                                          }`}
                                          <div>
                                            <span className="font-size-sm text-muted font-weight-bold mt-1 text-uppercase">
                                              {data.order_type} partner
                                            </span>
                                          </div>
                                        </div>
                                        {data.delivery.lastStatus && (
                                          <>
                                            {CANCELLED_DELIVERIES.includes(
                                              data.delivery.lastStatus,
                                            ) ? (
                                              <div className="mt-2 text-danger text-uppercase">
                                                {data.delivery.lastStatus.toUpperCase()}
                                              </div>
                                            ) : (
                                              <div className="mt-2">
                                                {data.delivery.lastStatus.toUpperCase()}
                                              </div>
                                            )}
                                            {
                                              <div className="font-size-sm text-muted font-weight-bold mt-1">
                                                <span className="text-uppercase">
                                                  {data.order_type} status
                                                </span>
                                              </div>
                                            }
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <div className="mt-2 text-uppercase">
                                          Multipartner Hailing
                                        </div>
                                      </>
                                    )}

                                    {
                                      <div className="mt-2">
                                        {data.delivery_address}
                                      </div>
                                    }
                                    {
                                      <div className="font-size-sm text-muted font-weight-bold mt-1">
                                        <span className="text-uppercase">
                                          {data.order_type} address
                                        </span>
                                      </div>
                                    }
                                    {data.delivery.orderRef &&
                                      data.multipartner_hailing === false && (
                                        <>
                                          <div
                                            className="text-dark-75 font-weight-bolder mt-2"
                                            style={{
                                              color: "blue",
                                              textDecorationLine: "underline",
                                            }}
                                          >
                                            <a
                                              href="#!"
                                              style={{ color: "blue" }}
                                              onClick={
                                                this.handleOnClickDeliveryRef
                                              }
                                            >
                                              {data.delivery.orderRef}
                                            </a>
                                          </div>
                                          <div className="font-size-sm text-muted font-weight-bold mt-1">
                                            <span className="text-uppercase">
                                              {data.order_type} reference
                                            </span>
                                          </div>
                                        </>
                                      )}
                                    {driverDetails &&
                                      data.multipartner_hailing === false && (
                                        <>
                                          <div className="mt-2">
                                            {driverDetails.name ||
                                              (driverDetails.courier &&
                                                driverDetails.courier.name) ||
                                              "N/A"}
                                          </div>
                                          <div className="font-size-sm text-muted font-weight-bold mt-1">
                                            <span className="text-uppercase">
                                              {data.order_type} rider name
                                            </span>
                                          </div>
                                          <div>
                                            <div className="mt-2">
                                              {driverDetails.phone ||
                                                (driverDetails.courier &&
                                                  driverDetails.courier
                                                    .phone) ||
                                                "N/A"}
                                            </div>
                                            <div className="font-size-sm text-muted font-weight-bold mt-1">
                                              <span className="text-uppercase">
                                                {data.order_type} rider contact
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                  </>
                                )}
                              </>
                            )}

                            {data.order_type === "curbside_pickup" &&
                              data.curbside_pickup_details && (
                                <>
                                  <div>
                                    <div>
                                      {data.curbside_pickup_details.vehicle_make
                                        ? data.curbside_pickup_details
                                            .vehicle_make
                                        : "N/A"}
                                      <div>
                                        <span className="font-size-sm text-muted font-weight-bold mt-1 text-uppercase">
                                          vehicle make
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      {data.curbside_pickup_details
                                        .vehicle_color
                                        ? data.curbside_pickup_details
                                            .vehicle_color
                                        : "N/A"}
                                      <div>
                                        <span className="font-size-sm text-muted font-weight-bold mt-1 text-uppercase">
                                          vehicle color
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      {data.curbside_pickup_details
                                        .vehicle_plate
                                        ? data.curbside_pickup_details
                                            .vehicle_plate
                                        : "N/A"}
                                      <div>
                                        <span className="font-size-sm text-muted font-weight-bold mt-1 text-uppercase">
                                          vehicle plate number
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                            {data.order_type === "dine_in" && (
                              <>
                                <div>
                                  {data.table_number ? (
                                    <>
                                      <span className="font-weight-bold mt-1 text-uppercase display-4">
                                        {data.table_number}
                                      </span>
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                  <div>
                                    <span className="font-size-sm text-muted font-weight-bold mt-1 text-uppercase">
                                      table number
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          <div className="text-dark-75 font-weight-bolder mt-2">
                            {/* {moment(data.order_date, DB_DATE_FORMAT).format("MMMM D, YYYY")} {moment(data.order_time, DB_HOURS_FORMAT).format("h:mm A")} */}
                            <OrderTimeRender {...data} />
                          </div>
                          <div className="font-size-sm text-muted font-weight-bold mt-1">
                            <span className="text-uppercase">
                              {formatOrderType(data.order_type)} date and time
                              {data.status === "new" && display_buttons ? ( // !data.order_queue
                                <button
                                  type="button"
                                  className="btn btn-icon btn-sm px-0 text-uppercase font-weight-bolder va-middle font-pickup-sm"
                                  onClick={() => {
                                    this.setState({
                                      showChangeOrderScheduleModal: true,
                                    });
                                  }}
                                >
                                  <i className="far fa-edit text-primary" />
                                </button>
                              ) : (
                                <></>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-10">
                      <div className="d-flex align-items-center mr-2">
                        <div className="symbol symbol-40 mr-3 flex-shrink-0">
                          <div className="symbol-label">
                            <span>
                              <i className="flaticon-home text-primary"></i>
                            </span>
                          </div>
                        </div>
                        <div>
                          <div
                            href="#"
                            className="text-dark-75 text-hover-primary font-weight-bolder"
                          >
                            {data.store.name}
                          </div>
                          <div className="font-size-sm text-muted font-weight-bold mt-1">
                            <span className="text-uppercase">store</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {isOrderRefunded(data.status) && (
                      <div className="d-flex align-items-center justify-content-between mb-10">
                        <div className="d-flex align-items-center mr-2">
                          <div className="symbol symbol-40 mr-3 flex-shrink-0">
                            <div className="symbol-label">
                              <span>
                                <i className="flaticon-notes text-primary"></i>
                              </span>
                            </div>
                          </div>
                          <div>
                            <div
                              href="#"
                              className="text-dark-75 text-hover-primary font-weight-bolder"
                            >
                              {data.cancellation_notes}
                            </div>
                            <div className="font-size-sm text-muted font-weight-bold mt-1">
                              <span className="text-uppercase">
                                Cancellation Notes
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {data.order_type === "delivery" && (
                      <>
                        <Section
                          header={
                            <>
                              <div className="font-weight-bolder">
                                Delivery Actions
                              </div>
                            </>
                          }
                        />

                        <div className="d-flex justify-content-start">
                          <DeliveryButtons
                            order={data}
                            actions={{
                              execHailRider: this.execHailRider,
                              execDeliveryRefresh: this.execDeliveryRefresh,
                            }}
                          />
                        </div>
                        <div className="d-flex">
                          {!isFromReportsPage &&
                            data.order_type === "delivery" &&
                            data.delivery && (
                              <Button
                                disabled={
                                  [
                                    "REFRESHING",
                                    ...CANCELLED_DELIVERIES,
                                  ].includes(data.delivery.lastStatus) ||
                                  isUndefined(data.delivery.lastStatus)
                                }
                                className={`font-weight-bolder text-uppercase mt-5 ${
                                  KTUtil.isMobileDevice() ? "mb-3" : "mr-5"
                                }`}
                                variant="danger"
                                onClick={async (event) => {
                                  event.stopPropagation();
                                  await this.execCancelDelivery(
                                    data._id,
                                    data.delivery.lastStatus,
                                  );
                                }}
                              >
                                <span>
                                  <i className="flaticon2-cancel icon-nm"></i>{" "}
                                  CANCEL DELIVERY
                                </span>
                              </Button>
                            )}
                        </div>
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={8}>
                <Card className="gutter-b card-custom card-stretch">
                  <Card.Body>
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Customer Rating
                          </div>
                        </>
                      }
                    />

                    {!isNil(storeRating) && storeRating !== "" ? (
                      <Row>
                        <Col>
                          <Card>
                            <Card.Body>
                              <div className="text-dark-75 text-primary font-weight-bolder text-break">
                                <span>Customer Review</span>
                              </div>
                              <hr />
                              <Row>
                                <Col>
                                  <div>
                                    <Rating
                                      name="half-rating-read"
                                      value={storeRating.store_rating}
                                      precision={0.5}
                                      size={"large"}
                                      readOnly
                                    />
                                  </div>
                                  <div className="text-dark-50 font-weight-bolder text-break">
                                    <span>
                                      {this.deliveryReview(
                                        storeRating.store_feedbacks,
                                      )}
                                    </span>
                                  </div>
                                </Col>
                              </Row>

                              {!isNil(storeRating.customer_suggestion) &&
                                storeRating.customer_suggestion !== "" && (
                                  <Row>
                                    <Col>
                                      <div className="text-dark-75 text-primary font-weight-bolder text-break mt-5">
                                        <span>
                                          Suggestion:{" "}
                                          <span className="font-size-sm font-weight-bolder text-muted mt-2 text-break">
                                            {storeRating.customer_suggestion}
                                          </span>
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                )}
                              <hr />
                              {!isNil(storeRating.item_details) &&
                                !isNil(storeRating.item_reactions) && (
                                  <Row>
                                    <Col>
                                      <div className="text-dark-75 text-primary font-weight-bolder mt-5">
                                        Item Reactions
                                      </div>
                                      <PerfectScrollbar>
                                        {storeRating.item_details.map(
                                          (item, index) => (
                                            <div
                                              key={index}
                                              className="item d-flex flex-wrap align-items-center mv-1rem inactive-link"
                                            >
                                              <div className="item-photo-wrapper symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                                                <div
                                                  className="symbol-label"
                                                  style={{
                                                    backgroundImage: `url(${item.image_url})`,
                                                  }}
                                                ></div>
                                              </div>
                                              <div className="item-text-wrapper d-flex flex-column flex-grow-1">
                                                <div className="font-weight-bolder text-primary">
                                                  {item.name}
                                                </div>
                                                <div>
                                                  {storeRating.item_reactions[
                                                    index
                                                  ].liked ? (
                                                    <ThumbUpAlt size="small" />
                                                  ) : (
                                                    <ThumbDownAlt size="small" />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ),
                                        )}
                                      </PerfectScrollbar>
                                    </Col>
                                  </Row>
                                )}
                            </Card.Body>
                          </Card>
                        </Col>
                        <Col lg={6}>
                          <ReviewForms
                            storeRating={storeRating}
                            data={data}
                            api={this.api}
                            user={this.props.user}
                          />
                        </Col>
                      </Row>
                    ) : (
                      <div className="font-weight-bolder">N/A</div>
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card className="gutter-b card-custom card-stretch">
                  <Card.Body>
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">
                            Customer Details
                          </div>
                        </>
                      }
                    />
                    <Row>
                      <Col>
                        <ListItem
                          reverse={true}
                          text={
                            <>
                              <span className="label label-primary label-inline va-middle text-uppercase font-weight-bolder mr-2">
                                soon
                              </span>
                            </>
                          }
                          mutedText={<>Order Frequency</>}
                          hideIcon={true}
                        />
                      </Col>
                      <Col>
                        <ListItem
                          reverse={true}
                          text={
                            <>
                              <span className="label label-primary label-inline va-middle text-uppercase font-weight-bolder mr-2">
                                soon
                              </span>
                            </>
                          }
                          mutedText={<>Total Lifetime Spend</>}
                          hideIcon={true}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <ListItem
                          reverse={true}
                          text={
                            <>
                              <span className="label label-primary label-inline va-middle text-uppercase font-weight-bolder mr-2">
                                soon
                              </span>
                            </>
                          }
                          mutedText={<>Customer Favorites</>}
                          hideIcon={true}
                        />
                      </Col>
                      <Col>
                        <ListItem
                          reverse={true}
                          text={
                            <>
                              <span className="label label-primary label-inline va-middle text-uppercase font-weight-bolder mr-2">
                                soon
                              </span>
                            </>
                          }
                          mutedText={<>Instagram</>}
                          hideIcon={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <Row>
              {data.order_type === "delivery" && data.delivery && (
                <Col sm={4}>
                  <Card className="card-custom">
                    <Card.Body className="standard-card-body">
                      <Section
                        header={
                          <>
                            <div className="font-weight-bolder">
                              Delivery Log
                            </div>
                          </>
                        }
                      />
                      <DeliveryLogs
                        order={data}
                        deliveryLogsUpdateKey={this.state.deliveryLogsUpdateKey}
                        api={this.api}
                        maxHeight={"350px"}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              )}
              <Col>
                <Card className="card-custom">
                  <Card.Body className="standard-card-body">
                    <Section
                      header={
                        <>
                          <div className="font-weight-bolder">Activity Log</div>
                        </>
                      }
                    />

                    <Activities
                      collection="orders"
                      identifier={data._id}
                      api={this.api}
                      maxHeight={"350px"}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }

  handleOpenTracking = (url) => {
    const { browser, os } = UAParser();

    if (
      ["Safari", "Safari Mobile", "Mobile Safari"].includes(browser.name) ||
      ["iOS"].includes(os.name)
    ) {
      this.setState({
        showTrackingModal: true,
        trackingUrl: url,
      });
    } else {
      window.open(url, "_blank");
    }
  };

  handleCloseTrackingModal = () => {
    this.setState({
      showTrackingModal: false,
      trackingUrl: "",
    });
  };

  renderTrackingModal = () => {
    return (
      <Modal
        show={this.state.showTrackingModal}
        onHide={this.handleCloseTrackingModal}
        size="lg"
        aria-labelledby="tracking-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title id="tracking-modal">Delivery Tracking</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.handleCloseTrackingModal}
          >
            <i aria-hidden="true" className="ki ki-close"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: "80vh", width: "100%" }}>
            <iframe
              src={this.state.trackingUrl}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
              title="Delivery Tracking"
              allow="geolocation"
            />
          </div>
        </Modal.Body>
      </Modal>
    );
  };
}
