import { capitalize } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { isEmpty, debounce } from "lodash";
import PacmanLoading from "../../../components/widgets/PacmanLoading/PacmanLoading";
import moment from "moment";

export const InventoryModal = ({ event, show, api, handleError, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    const getEventOrders = async () => {
      try {
        setIsLoading(true);
        const { data } = await api.get(
          `/store/${event.store._id}/event/${event._id}/orders?page=${page}&limit=${limit}`,
        );
        if (data) {
          const { orders } = data;
          const { docs, totalDocs, limit, page } = orders;
          setData(docs);
          setLimit(limit);
          setPage(page);
          setTotalRows(totalDocs);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    getEventOrders();
    // eslint-disable-next-line
  }, []);

  const getData = async (search = "") => {
    if (!isEmpty(event)) {
      try {
        setIsLoading(true);
        api &&
          api
            .get(
              `/store/${event.store._id}/event/${event._id}/orders?page=${page}&limit=${limit}&search=${search}`,
            )
            .then(({ data }) => {
              if (data) {
                const { orders } = data;
                const { docs, totalDocs, limit, page } = orders;
                setData(docs);
                setLimit(limit);
                setPage(page);
                setTotalRows(totalDocs);
                setIsLoading(false);
              }
            });
      } catch (err) {
        setIsLoading(false);
        handleError(err);
      }
    }
  };

  const handleSearchInputChange = debounce(async (value) => {
    await getData(value);
  }, 1000);

  return (
    <Modal show={show} centered onHide={onHide} size="lg">
      {" "}
      <Modal.Header>
        <Modal.Title>Event Orders</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          columns={[
            {
              name: "Booking Number",
              sortable: true,
              selector: (row) => {
                return `#${row.transaction_number}`;
              },
            },
            {
              name: "Booked Date",
              sortable: true,
              selector: (row) => {
                return moment(row.order_date).format("MMM D, YYYY");
              },
            },
            {
              name: "Guest Info",
              sortable: true,
              selector: (row) => {
                return capitalize(row.guest_customer.last_name);
              },
            },
            {
              name: "Status",
              center: true,
              selector: (row) => {
                return capitalize(row.status);
              },
              format: (row) => {
                return (
                  <span
                    style={{ width: 70 }}
                    className={`badge badge-pill text-capitalize badge-${
                      row.status === "new" ? "light" : "primary"
                    }`}
                  >
                    {row.status === "completed" ? "Redeemed" : "Pending"}
                  </span>
                );
              },
              sortable: true,
            },
          ]}
          data={data}
          pagination
          persistTableHead
          paginationServer
          progressPending={isLoading}
          paginationTotalRows={totalRows}
          paginationPerPage={limit}
          paginationDefaultPage={page}
          onChangeRowsPerPage={(currentRowsPerPage, currentPage) => {
            setLimit(currentRowsPerPage);
            setPage(currentPage);
            getData();
          }}
          onChangePage={(page, totalRows) => {
            setPage(page);
            setTotalRows(totalRows);
            getData();
          }}
          progressComponent={
            <div className="d-flex justify-content-center align-items-center h-100">
              <PacmanLoading removeBackgroundColor />
            </div>
          }
          noHeader
          subHeader
          subHeaderComponent={
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="flaticon-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Booking Number or Guest Last Name"
                onChange={(e) => {
                  handleSearchInputChange(e.target.value);
                }}
              />
            </div>
          }
        />
      </Modal.Body>
    </Modal>
  );
};
